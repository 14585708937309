import React, { useRef } from "react";
import ThemeCard from "../../components/ThemeCard";
import { Col, Pagination, Row, Tooltip, notification } from "antd";
import ThemeCardTable from "../../components/ThemeCardTable";
import { useEffect } from "react";
import APICall from "../../APICall";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import EndPoints from "../../APICall/EndPoints";
import { useDispatch, useSelector } from "react-redux";
import UserFilterComponant from "../../components/UserFilterComponant/UserFilterComponant";
import { setUserClearFilter } from "../../store/slice/globleSlice";
import { formatNumber } from "../../components/FormateToINR/formatToINR";
import MarketScriptList from "../../components/MarketScriptList/MarketScriptList";
import ThemeDropDown from "../../components/ThemeDropDown";
import {
  options,
  tradeLogFilterColumns,
  tradeLogFilterOption,
} from "../../Helper/constant";
import GetDate from "../../components/DatePicker/GetDate";
import useGetQueryParams from "../../hooks/useGetQueryParams";
import {
  readableString,
  valanBillDate,
} from "../../components/CommanFuction/CommanFunction";
import {
  PaginationRow,
  StyledButton,
  TextLeftDiv,
} from "../../styles/commonStyle";
import { ActiveDot } from "../TradingTrade/style";
import ValanComponant from "../../components/ValanComponant/ValanComponant";

const initalValue = {
  title: null,
};

function TradeLog() {
  // state
  const [value, setValue] = useState(initalValue);
  const [totalCounts, setTotalCounts] = useState(0);
  const valueRef = useRef({});
  const dispatch = useDispatch();
  const { params, setSearchParams } = useGetQueryParams();

  // redux
  const loginUserDetails = useSelector((state) => state.authSlice?.userDetail);
  const filterUser = useSelector((state) => state?.globleSlice?.filterUsers);
  const checkList = useSelector((state) => state?.globleSlice?.tradeLogColumn);
  const loginUserId = loginUserDetails.userId;
  const isOwner = loginUserDetails?.accountType === "owner";

  // life cycle
  useEffect(() => {
    if (Object.keys(params)?.length === 0) {
      setSearchParams({ userId: loginUserId });
    }
  }, [params]);

  useEffect(() => {
    setSearchParams({
      ...params,
      userId: params?.userId ? params.userId : loginUserId,
    });
    if (params?.title) {
      setValue({ title: params?.title });
    }
  }, []);

  // api call
  const getTrade = async () => {
    let isAllow = filterUser?.includes(Number(params?.userId));
    if (!isAllow) {
      notification.error({ message: "User Id is not valid" });
      return [];
    }
    let obj = {
      query: {
        childAllLogs: {
          page: params?.page || 1,
          limit: params?.limit | 10,
        },
      },
      filters: {
        userId: Number(params?.userId),
        startDate: params?.startDate || null,
        endDate: params?.endDate || null,
        symbolName: params?.symbolName || null,
        marketName: params?.marketName || null,
        title: params?.title || null,
        logType: params?.logType || null,
      },
      searchQuery: "",
    };
    if (params?.valanId) obj.filters.valanId = params.valanId;
    let response = await APICall("post", `${EndPoints.getTradeLog}`, obj);
    setTotalCounts(response?.data?.data?.childAllLogsPagination?.totalCount);
    return response?.data?.data;
  };

  const {
    isLoading,
    isFetching,
    error: tradeLogError,
    data: tradeList,
    refetch,
  } = useQuery({
    queryKey: ["logTrades", params],
    queryFn: getTrade,
    enabled: Object.keys(params)?.length > 0,
  });

  if (tradeLogError) notification.error({ message: tradeLogError?.message });

  // function
  const handleReset = () => {
    dispatch(setUserClearFilter(true));
    valueRef.current = {};
    setSearchParams({ userId: loginUserId });
  };

  const handleFind = () => {
    let payloadValue = valueRef.current;
    let valueObj = {
      userId: payloadValue?.userId || loginUserId,
      marketName: payloadValue?.value?.marketName || null,
      symbolName: payloadValue?.value?.symbolName || null,
      title: value?.title || null,
      startDate: payloadValue?.startDate?.toISOString() || null,
      endDate: payloadValue?.endDate?.toISOString() || null,
      valanId: payloadValue?.valan || null,
      page: 1,
    };
    if (params?.limit) {
      valueObj.limit = params?.limit;
    }

    for (let key in valueObj) {
      if (!valueObj[key]) {
        delete valueObj[key];
      }
    }

    setSearchParams(valueObj);
  };

  const onChangeDropDown = (name, selectedValue, selectedOptions) => {
    setValue({ ...value, [name]: selectedValue?.[0] });
    valueRef.current = { ...valueRef.current, [name]: selectedValue?.[0] };
  };

  const onChagePage = (page) => {
    setSearchParams({ ...params, page: page });
  };

  // column
  const columns = [
    {
      title: "DEVICE",
      dataIndex: "deviceType",
      key: "deviceType",
      width: "50px",
      align: "left",
      hidden: !checkList?.includes("deviceType"),
      render: (text, record) => {
        return (
          <Tooltip title={`${record?.marketStatus} trade`}>
            <div style={{ display: "flex", gap: "5px" }}>
              <div>{record?.deviceType}</div>
              <ActiveDot status={record?.marketStatus}></ActiveDot>
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: "O.TIME",
      dataIndex: "tradeTime",
      key: "tradeTime",
      width: "6rem",
      align: "left",
      hidden: !checkList?.includes("tradeTime"),
      render: (text, record) => {
        const finalDate = valanBillDate(record?.tradeTime, true);
        return !isLoading && <div>{finalDate}</div>;
      },
    },

    {
      title: "CLIENT",
      dataIndex: "client",
      key: "client",
      align: "left",
      width: "10rem",
      hidden: !checkList?.includes("client"),
      render: (text, record) => {
        return (
          !isLoading && (
            <div>{`${record?.client?.userId}(${record?.client?.accountName})`}</div>
          )
        );
      },
    },
    {
      title: "Trade Id",
      dataIndex: "tradeNumber",
      key: "tradeNumber",
      width: "5rem",
      align: "left",
      hidden: !checkList?.includes("tradeNumber"),
      render: (text, record) => {
        return (
          !isLoading && (
            <div>{record?.tradeNumber ? record?.tradeNumber : "-"}</div>
          )
        );
      },
    },
    {
      title: "MARKET",
      dataIndex: "marketName",
      key: "marketName",
      width: "6rem",
      align: "left",
      hidden: !checkList?.includes("marketName"),
    },
    {
      title: "SCRIPT",
      dataIndex: "symbol",
      key: "symbol",
      align: "left",
      width: "10rem",
      hidden: !checkList?.includes("symbol"),
      render: (text, record) => {
        const date = valanBillDate(record?.expiryDate, false);
        const opt = record?.marketName?.endsWith("OPT");
        const name = opt
          ? `${record?.symbolName} ${record?.series ? record?.series : ""} ${
              record?.strikePrice ? record?.strikePrice : ""
            }`
          : record?.symbolName;
        return (
          !isLoading && (
            <div>
              {name} {date}
            </div>
          )
        );
      },
    },
    {
      title: "ORDER TYPE",
      dataIndex: "title",
      key: "title",
      width: "6rem",
      align: "left",
      hidden: !checkList?.includes("title"),
      render: (text, record) => {
        return <span>{readableString(record?.title)}</span>;
      },
    },
    {
      title: "B/S",
      dataIndex: "tradePattern",
      key: "tradePattern",
      width: "4rem",
      align: "left",
      hidden: !checkList?.includes("tradePattern"),
    },
    {
      title: "QTY (LOT)",
      dataIndex: "lot",
      key: "lot",
      width: "6rem",
      align: "left",
      hidden: !checkList?.includes("lot"),
      render: (text, record) => {
        const lot = formatNumber(record?.lot);
        const quantity = formatNumber(record?.quantity);
        const data =
          record?.marketName === "NSE EQU" ? quantity : `${quantity} (${lot})`;
        return !isLoading && <div>{data}</div>;
      },
    },
    {
      title: "ORDER PRICE",
      dataIndex: "tradePrice",
      key: "tradePrice",
      width: "7rem",
      align: "left",
      hidden: !checkList?.includes("tradePrice"),
      render: (text, record) => {
        return !isLoading && <div>{record?.tradePrice}</div>;
      },
    },
    {
      title: "LTP",
      dataIndex: "stoploss",
      key: "stoploss",
      width: "6rem",
      align: "left",
      hidden: !checkList?.includes("stoploss"),
      render: (text, record) => {
        return !isLoading && <div>{record?.stoploss ?? "-"}</div>;
      },
    },
    {
      title: "High",
      dataIndex: "high",
      key: "high",
      width: "6rem",
      align: "left",
      hidden: !checkList?.includes("high"),
      render: (text, record) => {
        return !isLoading && <div>{record?.high ?? "-"}</div>;
      },
    },
    {
      title: "Low",
      dataIndex: "low",
      key: "low",
      width: "6rem",
      align: "left",
      hidden: !checkList?.includes("low"),
      render: (text, record) => {
        return !isLoading && <div>{record?.low ?? "-"}</div>;
      },
    },
    {
      title: "Bid",
      dataIndex: "Bid",
      key: "Bid",
      width: "6rem",
      align: "left",
      hidden: !checkList?.includes("Bid"),
      render: (text, record) => {
        return !isLoading && <div>{record?.Bid ?? "-"}</div>;
      },
    },
    {
      title: "Ask",
      dataIndex: "Ask",
      key: "Ask",
      width: "6rem",
      align: "left",
      hidden: !checkList?.includes("Ask"),
      render: (text, record) => {
        return !isLoading && <div>{record?.Ask ?? "-"}</div>;
      },
    },
    {
      title: "USER IP",
      dataIndex: "userIp",
      key: "userIp",
      align: "left",
      width: "8rem",
      hidden: !checkList?.includes("userIp"),
      render: (text, record) => {
        return !isLoading && <div>{record?.tradeIpAddress}</div>;
      },
    },

    {
      title: "REMARK",
      dataIndex: "remark",
      key: "remark",
      align: "left",
      width: "12rem",
      hidden: !checkList?.includes("remark"),
      render: (text, record) => {
        return !isLoading && <div>{record?.message?.text}</div>;
      },
    },
    {
      title: "MODIFIED BY",
      align: "center",
      key: "modifiedBy",
      width: "12rem",
      hidden: !checkList?.includes("modifiedBy"),
      children: [
        {
          title: "DATE",
          dataIndex: "",
          align: "center",
          key: "date",
          width: "6rem",
          render: (text, record) => {
            const finalDate = valanBillDate(record?.createdAt, true);
            return !isLoading && <TextLeftDiv>{finalDate}</TextLeftDiv>;
          },
        },
        // {
        //   title: "TIME",
        //   dataIndex: "",
        //   align: "left",
        //   key: "time",
        //   width: "5rem",
        //   render: (text, record) => {
        //     // let date = new Date(record?.tradeTime);
        //     return !isLoading && <span>{"-"}</span>;
        //   },
        // },
        {
          title: "ID",
          dataIndex: "",
          align: "center",
          key: "id",
          width: "6rem",
          render: (text, record) => {
            const modifiedId =
              `${record?.modifiedBy?.userId}(${record?.modifiedBy?.accountName})` ||
              "-";
            return !isLoading && <TextLeftDiv>{modifiedId}</TextLeftDiv>;
          },
        },
      ],
    },
    {
      title: "ACTION",
      dataIndex: "action",
      key: "action",
      align: "center",
      filters: tradeLogFilterOption,
      filterMultiple: false,
      hidden: !checkList?.includes("action"),
      width: "5rem",
      render: (text, record) => {
        return <div>{record?.logType || "-"}</div>;
      },
    },
  ];

  return (
    <>
      <ThemeCard>
        <Row gutter={[20, 20]}>
          <MarketScriptList valueRef={valueRef} />
          {isOwner && <ValanComponant valueRef={valueRef} />}
          <GetDate valueRef={valueRef} />
          <Col xs={24} sm={12} md={8} lg={6} xl={4}>
            <ThemeDropDown
              title={"Order Type"}
              placeholder="Order Type"
              name="title"
              width={"98%"}
              showSearch={true}
              options={options.trading.orderType}
              $onChange={onChangeDropDown}
              $value={value?.title}
            />
          </Col>
          <UserFilterComponant valueRef={valueRef} />
        </Row>
        <Row style={{ marginTop: "3rem" }} gutter={[10, 10]}>
          <Col xs={24} sm={12} md={8} lg={3} xl={2}>
            <StyledButton type="primary" onClick={handleFind}>
              Search
            </StyledButton>
          </Col>
          <Col xs={24} sm={12} md={8} lg={3} xl={2}>
            <StyledButton type="primary" onClick={handleReset}>
              Clear Search
            </StyledButton>
          </Col>
        </Row>
      </ThemeCard>
      <ThemeCardTable
        buttonType={false}
        data={tradeList?.childAllLogs}
        column={columns}
        headingStyle={true}
        isFilterTrue={true}
        isLoading={isLoading}
        filtertype="tradeLog"
        isFilter={true}
        checkList={checkList}
        columnFilter={tradeLogFilterColumns}
      />
      <PaginationRow>
        <Pagination
          size="large"
          showSizeChanger={false}
          current={params?.page || 1}
          pageSize={params?.limit || 10}
          total={totalCounts}
          onChange={onChagePage}
        />
      </PaginationRow>
    </>
  );
}

export default TradeLog;
