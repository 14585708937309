import React, { useState, useRef, useEffect, useMemo } from "react";
import ThemeCard from "../../components/ThemeCard";
import { Layout, Tabs, Typography, Avatar } from "antd";
import { useGetChat, useGetChildUsers } from "../../APICall/apiHooks";
import { useSelector } from "react-redux";
import { UserOutlined } from "@ant-design/icons";
import ClientListSection from "./ClientListSection";
import { ChatArea, MessageBubble } from "./commanFunction";
import {
  ChatAreaDiv,
  ChatboxHeaderDiv,
  DateHeader,
  SelectUserDiv,
  StyledSlider,
  UserIconForEmptyChat,
} from "./style";
import MessageInput from "./MessageInput";
import dayjs from "dayjs";
const io = require("socket.io-client");

const { Text } = Typography;
const { Sider } = Layout;

const formatDateHeader = (dateString) => {
  const messageDate = dayjs(dateString);
  const today = dayjs().startOf("day");
  const yesterday = today.subtract(1, "day");

  if (messageDate.isSame(today, "day")) {
    return "Today";
  } else if (messageDate.isSame(yesterday, "day")) {
    return "Yesterday";
  } else {
    return messageDate.format("MMMM D, YYYY");
  }
};

function NotificationPage() {
  // state
  const [activeTab, setActiveTab] = useState("Client");
  const [activeUser, setActiveUser] = useState(null);
  const [messages, setMessages] = useState({});

  const messageEndRef = useRef(null);
  const chatSocketref = useRef();
  const chatAreaRef = useRef();

  //redux
  const loginId = useSelector((state) => state.authSlice?.userDetail?.userId);
  const login_id = useSelector((state) => state.authSlice?.userDetail?._id);

  // custom hook for get child users
  const { childUsers, isLoading: childUserLoading } = useGetChildUsers({
    id: loginId,
    type: "onlychildwithparent",
  });

  // custom hook for get user chat
  const { chatData, chatLoading, chatMutate } = useGetChat();

  // chat socket connection function
  const chatSocketConnect = () => {
    if (chatSocketref.current != null) {
      chatSocketref.current = null;
    }
    const token = localStorage.getItem("token");
    chatSocketref.current = io(`${process.env.REACT_APP_API_URL}chat`, {
      auth: { token: token },
      transports: ["websocket"],
    });

    chatSocketref.current.on("connect", (value) => {
      console.log(" notification socket connect", value);
    });

    chatSocketref.current.on("newMessage", (value) => {
      console.log("newMessage", value);

      setMessages((prev) => {
        let newMessage = { ...prev };
        value?.forEach((item) => {
          if (!newMessage[item?.senderId])
            newMessage[item?.senderId] = new Map();
          newMessage[item?.senderId].set(item?._id, item);
        });

        return newMessage;
      });
    });

    chatSocketref.current.on("updateMessage", (value) => {
      console.log("updateMessage", value);

      setMessages((prev) => {
        let newMessage = { ...prev };

        value?.forEach((item) => {
          if (newMessage[item?.receiverId]) {
            const oldKey = item?.tempId;
            if (oldKey) {
              newMessage[item?.receiverId].delete(oldKey);
              newMessage[item?.receiverId].set(item?._id, item);
            } else {
              if (newMessage[item?.receiverId].has(item?._id)) {
                newMessage[item?.receiverId].set(item?._id, item);
              }
            }
          }
        });
        return newMessage;
      });
    });

    chatSocketref.current.on("disconnect", (value) => {
      console.log("notification socket disconnect", value);
    });
  };

  // life cycle
  useEffect(() => {
    chatSocketConnect();

    return () => {
      chatSocketref.current.disconnect();
    };
  }, []);

  // socket emit
  const doSend = (obj) => {
    if (obj?.event) {
      chatSocketref.current?.emit(obj?.event, obj?.payload);
    }
  };

  // life cycle
  useEffect(() => {
    if (activeUser) {
      doSend({ event: "chat-tab", payload: activeUser });
      chatMutate(activeUser);
    }
  }, [activeUser]);

  useEffect(() => {
    if (chatData?.status === 200) {
      const mapData = chatData?.data?.data.reduce((acc, item) => {
        acc.set(item?._id, item);
        return acc;
      }, new Map());
      setMessages((prev) => ({
        ...prev,
        [activeUser]: mapData,
      }));
    }
  }, [chatData]);

  useEffect(() => {
    if (
      activeUser &&
      messageEndRef.current &&
      messages[activeUser]?.length > 0
    ) {
      const firstUnreadIndex = messages[activeUser]?.findIndex(
        (el) => el?.status !== "seen" && el?.senderId !== login_id
      );

      if (firstUnreadIndex === -1) {
        messageEndRef.current?.scrollIntoView();
      } else {
        const unreadMessageElement = document.getElementById(
          `message-${messages[activeUser][firstUnreadIndex]?._id}`
        );

        if (unreadMessageElement) {
          unreadMessageElement?.scrollIntoView();
        }
      }
    }
  }, [activeUser, messages[activeUser]]);

  // function
  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  // tabs
  const TabsItem = [
    {
      key: "Client",
      label: "Client",
      children: (
        <ClientListSection
          childUsers={childUsers}
          childUserLoading={childUserLoading}
          setActiveUser={setActiveUser}
          activeUser={activeUser}
          messages={messages}
          login_id={login_id}
        />
      ),
    },
  ];

  // grouped messages
  const groupedMessages = useMemo(() => {
    const userMessages = messages[activeUser];

    if (!userMessages || userMessages.size === 0) return [];

    return Array.from(userMessages?.values())?.reduce((groups, message) => {
      const date = dayjs(message?.timestamp).format("YYYY-MM-DD");

      if (!groups[date]) {
        groups[date] = [];
      }

      groups[date].push(message);
      return groups;
    }, {});
  }, [messages, activeUser]);

  return (
    <ThemeCard title="Notification">
      <Layout>
        <Sider
          width={
            window.innerWidth < 500
              ? "50%"
              : window.innerWidth < 768
              ? "35%"
              : "25%"
          }
          breakpoint="md"
          collapsedWidth="0"
          theme="light"
        >
          <Tabs
            activeKey={activeTab}
            onChange={handleTabChange}
            type="card"
            tabBarGutter={0}
            defaultActiveKey="client"
            items={TabsItem}
          />
        </Sider>

        <Layout>
          <ChatArea>
            {activeUser ? (
              <>
                {/* Chat header */}
                <ChatboxHeaderDiv>
                  <Avatar
                    size={40}
                    icon={<UserOutlined />}
                    style={{ marginRight: "1rem" }}
                  />
                  <div>
                    <Text strong style={{ display: "block", marginBottom: 0 }}>
                      {
                        childUsers.find((u) => u._id === activeUser)
                          ?.accountName
                      }
                    </Text>
                    <Text type="secondary">
                      {
                        childUsers.find((u) => u._id === activeUser)
                          ?.accountType
                      }
                    </Text>
                  </div>
                </ChatboxHeaderDiv>

                {/* Message area */}
                <ChatAreaDiv ref={chatAreaRef}>
                  {Object.entries(groupedMessages).map(
                    ([date, dateMessages]) => (
                      <React.Fragment key={date}>
                        <DateHeader>
                          <span>{formatDateHeader(date)}</span>
                        </DateHeader>

                        {dateMessages?.map((message) => (
                          <MessageBubble
                            key={message?._id}
                            // id={`message-${message?._id}`}
                            data={message}
                            isMe={message?.senderId === login_id}
                          />
                        ))}
                      </React.Fragment>
                    )
                  )}
                  <div ref={messageEndRef} />
                </ChatAreaDiv>

                {/* Message input */}
                <MessageInput
                  setMessages={setMessages}
                  activeUser={activeUser}
                  doSend={doSend}
                />
              </>
            ) : (
              <SelectUserDiv>
                <UserIconForEmptyChat />
                <Text>Select a user to start chatting</Text>
              </SelectUserDiv>
            )}
          </ChatArea>
        </Layout>
      </Layout>
    </ThemeCard>
  );
}

export default NotificationPage;
