import React, { useLayoutEffect, useRef } from "react";
import ThemeCard from "../../components/ThemeCard";
import { Button, Col, Pagination, Row } from "antd";
import ThemeCardTable from "../../components/ThemeCardTable";
import UserFilterComponant from "../../components/UserFilterComponant/UserFilterComponant";
import useGetQueryParams from "../../hooks/useGetQueryParams";
import { useDispatch, useSelector } from "react-redux";
import { useEditLogs } from "../../APICall/apiHooks";
import { valanBillDate } from "../../components/CommanFuction/CommanFunction";
import { setUserClearFilter } from "../../store/slice/globleSlice";
import { PaginationRow } from "../../styles/commonStyle";

function PointLedgerLog() {
  const valueRef = useRef({});
  const { setSearchParams, params } = useGetQueryParams();
  const dispatch = useDispatch();

  //redux
  const loginId = useSelector((state) => state.authSlice?.userDetail?.userId);

  //custome hook for get ledger logs
  const { logsData, logsLoading } = useEditLogs({ params, loginId });

  //left cycle
  useLayoutEffect(() => {
    setSearchParams({ userId: loginId });
  }, []);

  //function
  const handleSearch = () => {
    let value = valueRef.current;
    if (value) {
      setSearchParams({
        ...params,
        userId: value?.userId || loginId,
        page: 1,
      });
    }
  };
  const ClearSearch = () => {
    valueRef.current = {};
    dispatch(setUserClearFilter(true));
    setSearchParams({ userId: loginId });
  };
  const onChagePage = (page) => {
    setSearchParams({ ...params, page: page });
  };

  //columns
  const columns = [
    {
      title: "CLIENT",
      dataIndex: "client",
      key: "client",
      align: "left",
      width: "7rem",
      render: (text, record) => {
        return <div>{`${record?.accountName} (${record?.userId})`}</div>;
      },
    },
    {
      title: "OLD AMOUNT",
      dataIndex: "oldAmount",
      key: "oldAmount",
      align: "center",
      width: "7rem",
      render: (text, record) => {
        const oldAmount = record?.ledgers?.find((el) => el?.key == "Amount");
        return <div>{oldAmount?.old}</div>;
      },
    },
    {
      title: "NEW AMOUNT",
      dataIndex: "newAmount",
      key: "newAmount",
      align: "center",
      width: "7rem",
      render: (text, record) => {
        const newAmount = record?.ledgers?.find((el) => el?.key == "Amount");
        return <div>{newAmount?.new}</div>;
      },
    },
    {
      title: "OLD REMARK",
      dataIndex: "oldRemark",
      key: "oldRemark",
      align: "center",
      width: "10rem",
      render: (text, record) => {
        const remark = record?.ledgers?.find((el) => el?.key == "Remark");
        return <div>{remark?.old}</div>;
      },
    },
    {
      title: "NEW REMARK",
      dataIndex: "newRemark",
      key: "newRemark",
      align: "center",
      width: "10rem",
      render: (text, record) => {
        const remark = record?.ledgers?.find((el) => el?.key == "Remark");
        return <div>{remark?.new}</div>;
      },
    },
    {
      title: "LOG TYPE",
      dataIndex: "logType",
      key: "logType",
      align: "center",
      width: "5rem",
      render: (text, record) => {
        const type = record?.ledgers?.find((el) => el?.key == "Entry Type");
        return <div>{type?.new}</div>;
      },
    },
    {
      title: "IP",
      dataIndex: "ip",
      key: "ip",
      align: "center",
      width: "10rem",
      render: (text, record) => {
        return <div>{record?.ipAddress}</div>;
      },
    },
    {
      title: "EDIT TIME",
      dataIndex: "editTime",
      key: "editTime",
      align: "center",
      width: "10rem",
      render: (text, record) => {
        return <div>{valanBillDate(record?.updateTime, true)}</div>;
      },
    },
    {
      title: "ADD TIME",
      dataIndex: "addTime",
      key: "addTime",
      align: "center",
      width: "10rem",
      render: (text, record) => {
        const type = record?.ledgers?.find((el) => el?.key == "Date");
        return <div>{valanBillDate(type?.new, true)}</div>;
      },
    },
    {
      title: "ACTION",
      dataIndex: "action",
      key: "action",
      align: "center",
      width: "5rem",
      render: (text, record) => {
        return <div>{record?.logType}</div>;
      },
    },
  ];
  return (
    <>
      <ThemeCard>
        <Row gutter={[20, 20]}>
          <UserFilterComponant valueRef={valueRef} />
        </Row>
        <Row gutter={[10, 20]} style={{ marginTop: "2rem" }}>
          <Col>
            <Button type="primary" onClick={handleSearch}>
              Search
            </Button>
          </Col>
          <Col>
            <Button type="primary" onClick={ClearSearch}>
              Clear Search
            </Button>
          </Col>
        </Row>
      </ThemeCard>
      <ThemeCardTable
        buttonType={false}
        data={logsData?.data}
        column={columns}
        headingStyle={true}
        isLoading={logsLoading}
        filtertype="pointLedgerLog"
      />
      <PaginationRow align={"center"}>
        <Pagination
          size="large"
          showSizeChanger={false}
          current={params?.page || 1}
          pageSize={params?.limit || 10}
          total={logsData?.totalItems}
          onChange={onChagePage}
        />
      </PaginationRow>
    </>
  );
}

export default PointLedgerLog;
