import { initialState } from "../../../store/slice/userAccountSlice";
import store from "../../../store/store";
const _ = require("lodash");

const convertBrokerId = (data) => {
  if (data) {
    return data?.map((el) => ({ ...el, brokerId: el?.brokerId?.[0] }));
  } else {
    return null;
  }
};
const filterBlockedScript = (data, preBlock) => {
  const unicData = [...new Set(data)];
  const previousBlock = [...new Set(preBlock)];
  const filteredData = unicData
    ?.filter((el) => !previousBlock?.includes(el))
    ?.map((item) => ({ symbol: item, status: "block", blockedBy: "parent" }));
  const updatedData = previousBlock
    ?.filter((el) => !data?.includes(el))
    ?.map((item) => ({ symbol: item, status: "unblock", blockedBy: "parent" }));
  return [...new Set([...filteredData, ...updatedData])];
};

export const payloadObject = ({ value, market, isEdit, isRent, type }) => {
  const blockedScriptList = store.getState()?.scriptSettingsRequestSlice;
  let userSlice = store.getState()?.userAccountSlice;
  if (isEdit) {
    const market_access = userSlice?.market_access;
    let parentAccount = userSlice?.parentValidation;
    let isRents = parentAccount?.accountDetails?.partnershipWith === "rent";

    const isCustomer = userSlice?.accountType === "customer";
    let AdditonalInfo = {};
    for (let key in initialState?.accountDetails?.additionalInfo) {
      if (value[key]) {
        AdditonalInfo[key] = value[key];
      }
    }
    let obj = {
      accountType: userSlice?.accountType,
      authorityLevel: userSlice?.authorityLevel,
      basicInfo: {
        accountName: value?.accountName || "XXYY",
        balance: value?.balance === 0 ? 0 : value.balance || "XXYY",
        accountPassword: value?.accountPassword,
        linkLedger: value?.linkLedger ?? "XXYY",
      },
      accountDetails: {
        allowNoOfAccountSuperAdmin:
          Number(value?.allowNoOfAccountSuperAdmin) === 0
            ? 0
            : Number(value?.allowNoOfAccountSuperAdmin) || "XXYY",
        allowNoOfAccountAdmin:
          Number(value?.allowNoOfAccountAdmin) === 0
            ? 0
            : Number(value?.allowNoOfAccountAdmin) || "XXYY",
        allowNoOfAccountMaster:
          Number(value?.allowNoOfAccountMaster) === 0
            ? 0
            : Number(value?.allowNoOfAccountMaster) || "XXYY",
        allowNoOfAccountBroker:
          Number(value?.allowNoOfAccountBroker) === 0
            ? 0
            : Number(value?.allowNoOfAccountBroker) || "XXYY",
        allowNoOfAccountDealer:
          Number(value?.allowNoOfAccountDealer) === 0
            ? 0
            : value?.allowNoOfAccountDealer || "XXYY",
        allowNoOfAccountCustomer:
          Number(value?.allowNoOfAccountCustomer) === 0
            ? 0
            : Number(value?.allowNoOfAccountCustomer) || "XXYY",
        partnershipWith: value?.partnershipWith || "XXYY",
        minimumBrokerageRequire: value?.minimumBrokerageRequire ?? "XXYY",
        additionalInfo: AdditonalInfo || "XXYY",
        orderBetweenHighLow: value?.orderBetweenHighLow ?? "XXYY",
        showDetailBill: value?.showDetailBill ?? "XXYY",
        allowBannedScript: value?.allowBannedScript ?? "XXYY",
        isOnlyPositionSquareup: value?.isOnlyPositionSquareup ?? "XXYY",
        isAutoStopNewPosition: value?.isAutoStopNewPosition ?? "XXYY",
        autoStopNewPosition: {
          profit:
            value?.isAutoStopNewPosition === false
              ? null
              : value?.autoStopNewPosition?.profit !== undefined
              ? value?.autoStopNewPosition?.profit
              : "XXYY",
          loss:
            value?.isAutoStopNewPosition === false
              ? null
              : value?.autoStopNewPosition?.loss !== undefined
              ? value?.autoStopNewPosition?.loss
              : "XXYY",
        },
        isApplyAutoSquareup: value?.isApplyAutoSquareup ?? "XXYY",
        applyAutoSquareup: {
          profit:
            value?.isApplyAutoSquareup === false
              ? null
              : value?.applyAutoSquareup?.profit !== undefined
              ? value?.applyAutoSquareup?.profit
              : "XXYY",
          loss:
            value?.isApplyAutoSquareup === false
              ? null
              : value?.applyAutoSquareup?.loss !== undefined
              ? value?.applyAutoSquareup?.loss
              : "XXYY",
        },
        isM2MAlert: value?.isM2MAlert ?? "XXYY",
        M2MAlert: {
          profit:
            value?.M2MAlert?.profit !== undefined
              ? value?.M2MAlert?.profit
              : "XXYY",
          loss:
            value?.M2MAlert?.loss !== undefined
              ? value?.M2MAlert?.loss
              : "XXYY",
        },
        isNewPositionSquareupTime: value?.isNewPositionSquareupTime ?? "XXYY",
        newPositionSquareupTime: {
          time:
            value?.isNewPositionSquareupTime === false
              ? null
              : value?.newPositionSquareupTime !== undefined
              ? value?.newPositionSquareupTime?.toISOString() || null
              : "XXYY",
          stringTime:
            value?.isNewPositionSquareupTime === false
              ? null
              : value?.newPositionSquareupTime !== undefined
              ? `${
                  Number(
                    Number(value?.newPositionSquareupTime?.format("HH")) * 60
                  ) + Number(value?.newPositionSquareupTime?.format("mm"))
                }:${value?.newPositionSquareupTime?.format("ss")}` || null
              : "XXYY",
        },
        isFreshLimitAllow: value?.isFreshLimitAllow ?? "XXYY",
        freshLimitAllow:
          value?.isFreshLimitAllow === false
            ? null
            : value?.freshLimitAllow !== undefined
            ? value?.freshLimitAllow
            : "XXYY",

        remark: value?.remark,
      },
      marketAccess: [],
    };

    if (isRent) {
      obj.basicInfo = {
        ...obj.basicInfo,
        autoLock: value?.autoLock !== undefined ? value?.autoLock : "XXYY",
        isExpiryMessageBeforeDays:
          value?.isExpiryMessageBeforeDays !== undefined
            ? value?.isExpiryMessageBeforeDays
            : "XXYY",
        expiryMessageBeforeDays:
          value?.expiryMessageBeforeDays !== undefined
            ? value?.expiryMessageBeforeDays
            : "XXYY",
        expiryDate:
          value?.expiryDate !== undefined
            ? value?.expiryDate?.toISOString()
            : "XXYY",
        rent: value?.rent !== undefined ? value?.rent : "XXYY",
      };
    }

    if (userSlice?.accountType === "broker") {
      return obj;
    }

    let initalMarket =
      store?.getState()?.globleSlice?.constantValue?.marketAccess;
    const oldSelectedMarket = store
      ?.getState()
      ?.globleSlice?.constantValue?.allMaketAccess?.filter((el) => !el?.status)
      ?.map((el) => el?.scriptName);

    const difference = new Set(_.difference(market, initalMarket));
    let rentMarket = [];
    market.forEach((scriptName) => {
      if (isRent) {
        rentMarket.push({ scriptName: scriptName });
      } else {
        let isOld = oldSelectedMarket?.includes?.(scriptName);
        let isNew = difference.has(scriptName) && !isOld;

        const name =
          scriptName === "NSE EQU" || scriptName === "CRYPTO"
            ? "totalNoOfQuantity"
            : "totalNoOfLot";
        let remainingPercentage = isCustomer
          ? 0
          : userSlice?.accountType === "masterAdmin" || isRents
          ? 100 - value[scriptName]?.percentage
          : parentAccount?.brokarageDetails[scriptName]?.remainingPercentage -
            value[scriptName]?.percentage;
        const brokerageDetails = {
          deliveryTotal:
            value[scriptName]?.deliveryTotal === 0
              ? 0
              : value[scriptName]?.deliveryTotal || (isNew ? 0 : "XXYY"),
          intradayTotal:
            value[scriptName]?.intradayTotal === 0
              ? 0
              : value[scriptName]?.intradayTotal || (isNew ? 0 : "XXYY"),
          deliveryBuy:
            value[scriptName]?.deliveryBuy === 0
              ? 0
              : (isCustomer
                  ? value[scriptName]?.deliveryBuy
                  : value[scriptName]?.deliveryTotal / 2) ||
                (isNew ? 0 : "XXYY"),
          intradayBuy:
            value[scriptName]?.intradayBuy === 0
              ? 0
              : (isCustomer
                  ? value[scriptName]?.intradayBuy
                  : value[scriptName]?.intradayTotal / 2) ||
                (isNew ? 0 : "XXYY"),
          deliverySell:
            value[scriptName]?.deliverySell === 0
              ? 0
              : (isCustomer
                  ? value[scriptName]?.deliverySell
                  : value[scriptName]?.deliveryTotal / 2) ||
                (isNew ? 0 : "XXYY"),
          intradaySell:
            value[scriptName]?.intradaySell === 0
              ? 0
              : (isCustomer
                  ? value[scriptName]?.intradaySell
                  : value[scriptName]?.intradayTotal / 2) ||
                (isNew ? 0 : "XXYY"),
          percentage:
            isCustomer && isNew
              ? isRents
                ? 100
                : parentAccount?.brokarageDetails[scriptName]
                    ?.remainingPercentage
              : value[scriptName]?.percentage === 0
              ? 0
              : value[scriptName]?.percentage || (isNew ? 0 : "XXYY"),
          ...(value?.[scriptName]?.brokerageType && {
            brokerageType: value?.[scriptName]?.brokerageType
              ? value?.[scriptName]?.brokerageType
              : ["NSE OPT", "MCX", "MCX OPT"]?.includes(scriptName)
              ? "fixed"
              : "percentage",
          }),
          ...(isNew && {
            brokerageType: value?.[scriptName]?.brokerageType
              ? value?.[scriptName]?.brokerageType
              : ["NSE OPT", "MCX", "MCX OPT"]?.includes(scriptName)
              ? "fixed"
              : "percentage",
          }),
          remainingPercentage: remainingPercentage || (isNew ? 0 : "XXYY"),
        };

        const additionalSharing =
          convertBrokerId(value[scriptName]?.additionalSharing) || "XXYY";
        const margin = {
          type:
            value[scriptName]?.type === "value"
              ? "value"
              : value[scriptName]?.type?.[0] || (isNew ? "value" : "XXYY"),
          delivery:
            value[scriptName]?.delivery === 0
              ? 0
              : value[scriptName]?.delivery || (isNew ? 0 : "XXYY"),
          intraday:
            value[scriptName]?.intraday === 0
              ? 0
              : value[scriptName]?.intraday || (isNew ? 0 : "XXYY"),
          total:
            value[scriptName]?.total === 0
              ? 0
              : value[scriptName]?.total || (isNew ? 0 : "XXYY"),
          interestRate:
            value[scriptName]?.interestRate === 0
              ? 0
              : value[scriptName]?.interestRate || (isNew ? 0 : "XXYY"),
          deposit:
            value[scriptName]?.deposit === 0
              ? 0
              : value[scriptName]?.deposit || (isNew ? 0 : "XXYY"),
        };
        const scriptWiseSettingInfo = {
          minimumScriptRate:
            value[scriptName]?.minimumScriptRate === 0
              ? 0
              : value[scriptName]?.minimumScriptRate === null
              ? null
              : value[scriptName]?.minimumScriptRate || (isNew ? null : "XXYY"),
          totalNoOfScripts:
            value[scriptName]?.totalNoOfScripts === 0
              ? 0
              : value[scriptName]?.totalNoOfScripts === null
              ? null
              : value[scriptName]?.totalNoOfScripts || (isNew ? null : "XXYY"),
          orderLimits:
            value[scriptName]?.orderLimits === 0
              ? 0
              : value[scriptName]?.orderLimits === null
              ? null
              : value[scriptName]?.orderLimits || (isNew ? null : "XXYY"),
          strikeRateMaxLot:
            value[scriptName]?.strikeRateMaxLot === 0
              ? 0
              : value[scriptName]?.strikeRateMaxLot === null
              ? null
              : value[scriptName]?.strikeRateMaxLot || (isNew ? null : "XXYY"),
          allowShortSell:
            value[scriptName]?.allowShortSell ?? (isNew ? false : "XXYY"),
          allowLimitOrder:
            value[scriptName]?.allowLimitOrder ?? (isNew ? true : "XXYY"),
          allowQuantityTrading:
            value[scriptName]?.allowQuantityTrading ?? (isNew ? false : "XXYY"),
          [name]:
            value[scriptName]?.[name] === 0
              ? 0
              : value[scriptName]?.[name] === null
              ? null
              : value[scriptName]?.[name] || (isNew ? null : "XXYY"),
          circuitFilter:
            value[scriptName]?.circuitFilter === null
              ? 0
              : value[scriptName]?.circuitFilter ?? (isNew ? 0 : "XXYY"),
          highLowPermission:
            value[scriptName]?.highLowPermission ?? (isNew ? false : "XXYY"),
        };
        const isOnlyPositionSquareup =
          value[scriptName]?.isOnlyPositionSquareup ?? (isNew ? false : "XXYY");
        const allScript =
          blockedScriptList?.[scriptName]?.edited || (isNew ? [] : "XXYY");
        const blockScript =
          filterBlockedScript(
            blockedScriptList?.[scriptName]?.blocked,
            blockedScriptList?.[scriptName]?.preBlock
          ) || (isNew ? [] : "XXYY");

        let marketWisePayload = {
          brokerageDetails,
          additionalSharing,
          margin,
          scriptWiseSettingInfo,
          isOnlyPositionSquareup,
          allScript,
          blockScript,
          scriptName: scriptName,
        };
        obj.marketAccess.push(marketWisePayload);
      }
    });

    if (userSlice?.accountType === "broker") {
      obj.marketAccess = parentAccount?.parentMarketList;
    }
    // let deletedMarket = [];
    // for (let a = 0; a < initalMarket.length; a++) {
    //   let check = market_access?.includes(initalMarket?.[a]);
    //   if (!check) {
    //     deletedMarket.push(initalMarket?.[a]);
    //   }
    // }
    // obj.removeMarket = deletedMarket;

    return obj;
  } else {
    let parentAccount = userSlice?.parentValidation;
    let isRents = parentAccount?.accountDetails?.partnershipWith === "rent";

    const isCustomer = userSlice?.accountType === "customer";
    let AdditonalInfo = {};
    for (let key in initialState?.accountDetails?.additionalInfo) {
      if (value[key]) {
        AdditonalInfo[key] = value[key];
      }
    }
    let obj = {
      accountType: userSlice?.accountType,
      authorityLevel: userSlice?.authorityLevel,
      basicInfo: {
        accountName: value?.accountName || "",
        balance: value?.balance || 0,
        accountPassword: value?.accountPassword,
        linkLedger: value?.linkLedger || false,
        openingBalance: value?.openingBalance || 0,
        partnerShipType: type,
      },
      accountDetails: {
        allowNoOfAccountMasterAdmin: 0,
        allowNoOfAccountSuperAdmin:
          Number(value?.allowNoOfAccountSuperAdmin) || 0,
        allowNoOfAccountAdmin: Number(value?.allowNoOfAccountAdmin) || 0,
        allowNoOfAccountMaster: Number(value?.allowNoOfAccountMaster) || 0,
        allowNoOfAccountBroker: Number(value?.allowNoOfAccountBroker) || 0,
        allowNoOfAccountDealer: Number(value?.allowNoOfAccountDealer) || 0,
        allowNoOfAccountCustomer: Number(value?.allowNoOfAccountCustomer) || 0,
        partnershipWith: value?.partnershipWith || null,
        minimumBrokerageRequire: value?.minimumBrokerageRequire || false,
        additionalInfo: AdditonalInfo || {},
        orderBetweenHighLow: value?.orderBetweenHighLow || false,
        showDetailBill: value?.showDetailBill || false,
        allowBannedScript: value?.allowBannedScript || false,
        isOnlyPositionSquareup: value?.isOnlyPositionSquareup || false,
        isAutoStopNewPosition: value?.isAutoStopNewPosition || false,
        autoStopNewPosition: {
          profit: value?.autoStopNewPosition?.profit || null,
          loss: value?.autoStopNewPosition?.loss || null,
        },
        isApplyAutoSquareup: value?.isApplyAutoSquareup || false,
        applyAutoSquareup: {
          profit: value?.applyAutoSquareup?.profit || null,
          loss: value?.applyAutoSquareup?.loss || null,
        },
        isM2MAlert: value?.isM2MAlert || false,
        M2MAlert: {
          profit: value?.M2MAlert?.profit || null,
          loss: value?.M2MAlert?.loss || null,
        },
        isNewPositionSquareupTime: value?.isNewPositionSquareupTime || false,
        newPositionSquareupTime: {
          time: value?.newPositionSquareupTime
            ? value?.newPositionSquareupTime?.toISOString() || null
            : null,
          stringTime: value?.newPositionSquareupTime
            ? `${
                Number(
                  Number(value?.newPositionSquareupTime?.format("HH")) * 60
                ) + Number(value?.newPositionSquareupTime?.format("mm"))
              }:${value?.newPositionSquareupTime?.format("ss")}`
            : null,
        },
        isFreshLimitAllow: value?.isFreshLimitAllow || false,
        freshLimitAllow: value?.freshLimitAllow || null,
        remark: value?.remark,
      },
      marketAccess: [],
    };

    if (userSlice?.accountType === "broker") {
      delete obj.accountDetails;
      return obj;
    }
    let rentMarket = [];
    market?.forEach((scriptName) => {
      if (isRent) {
        rentMarket.push({
          scriptName: scriptName,
          isOnlyPositionSquareup: false,
          status: true,
        });
      } else {
        const name =
          scriptName === "NSE EQU" || scriptName === "CRYPTO"
            ? "totalNoOfQuantity"
            : "totalNoOfLot";
        let remainingPercentage = isCustomer
          ? 0
          : userSlice?.accountType === "masterAdmin" || isRents
          ? 100 - value[scriptName]?.percentage
          : parentAccount?.brokarageDetails[scriptName]?.remainingPercentage -
            value[scriptName]?.percentage;
        const brokerageDetails = {
          deliveryTotal: value[scriptName]?.deliveryTotal || 0,
          intradayTotal: value[scriptName]?.intradayTotal || 0,
          deliveryBuy: isCustomer
            ? value[scriptName]?.deliveryBuy
            : value[scriptName]?.deliveryTotal / 2 || 0,
          intradayBuy: isCustomer
            ? value[scriptName]?.intradayBuy
            : value[scriptName]?.intradayTotal / 2 || 0,
          deliverySell: isCustomer
            ? value[scriptName]?.deliverySell
            : value[scriptName]?.deliveryTotal / 2 || 0,
          intradaySell: isCustomer
            ? value[scriptName]?.intradaySell
            : value[scriptName]?.intradayTotal / 2 || 0,
          percentage: isCustomer
            ? isRents
              ? 100
              : parentAccount?.brokarageDetails[scriptName]?.remainingPercentage
            : value[scriptName]?.percentage || 0,
          brokerageType: value?.[scriptName]?.brokerageType
            ? value?.[scriptName]?.brokerageType
            : ["NSE OPT", "MCX", "MCX OPT"]?.includes(scriptName)
            ? "fixed"
            : "percentage",
          remainingPercentage: remainingPercentage || 0,
        };

        const additionalSharing =
          convertBrokerId(value[scriptName]?.additionalSharing) || [];
        const margin = {
          type: "value",
          delivery: value[scriptName]?.delivery || 0,
          intraday: value[scriptName]?.intraday || 0,
          total: value[scriptName]?.total || 0,
          interestRate: value[scriptName]?.interestRate || 0,
          deposit: value[scriptName]?.deposit || 0,
        };
        const scriptWiseSettingInfo = {
          minimumScriptRate: value[scriptName]?.minimumScriptRate || null,
          totalNoOfScripts: value[scriptName]?.totalNoOfScripts || null,
          orderLimits: value[scriptName]?.orderLimits || null,
          strikeRateMaxLot: value[scriptName]?.strikeRateMaxLot || null,
          allowShortSell: value[scriptName]?.allowShortSell || false,
          allowLimitOrder: value[scriptName]?.allowLimitOrder || false,
          allowQuantityTrading:
            value[scriptName]?.allowQuantityTrading || false,
          [name]: value[scriptName]?.[name] || null,
          circuitFilter: value[scriptName]?.circuitFilter || 0,
          highLowPermission: value[scriptName]?.highLowPermission || false,
        };
        const isOnlyPositionSquareup =
          value[scriptName]?.isOnlyPositionSquareup || false;
        const allScript = blockedScriptList?.[scriptName]?.edited || [];
        const blockScript =
          filterBlockedScript(
            blockedScriptList?.[scriptName]?.blocked,
            blockedScriptList?.[scriptName]?.preBlock
          ) || [];

        let marketWisePayload = {
          brokerageDetails,
          additionalSharing,
          margin,
          scriptWiseSettingInfo,
          isOnlyPositionSquareup,
          allScript,
          blockScript,
          scriptName: scriptName,
          status: true,
        };
        obj.marketAccess.push(marketWisePayload);
      }
    });
    if (isRent) {
      obj.marketAccess = rentMarket;
    }
    if (userSlice?.accountType === "broker") {
      obj.marketAccess = parentAccount?.parentMarketList;
    }

    return obj;
  }
};
