import { Button, Card, Col, Form, Radio, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AdditionalInfoDiv,
  AdditionalInfoItemRow,
  AdditionalInfoModalCard,
  StyledThemeInput,
} from "../style";
import { additionalInfoNotView, options } from "../../../../Helper/constant";
import { setNewAccountInfo } from "../../../../store/slice/userAccountSlice";

const AdditionalInfoView = ({ parentForm }) => {
  //state
  const [open, setOpen] = useState(false);
  const [showOption, setShowOption] = useState([]);

  //redux
  const parentAdditionaleInfo = useSelector(
    (state) => state.authSlice?.MarketAccessDetails?.additional
  );
  const accountType = useSelector(
    (state) => state.userAccountSlice?.accountType
  );
  const loginAccountType = useSelector(
    (state) => state.authSlice?.userDetail?.accountType
  );

  // life cycle
  useEffect(() => {
    const Option = options?.user?.additional_info;
    const childOptions =
      parentAdditionaleInfo &&
      Object?.keys?.(parentAdditionaleInfo)?.filter((el) => {
        return parentAdditionaleInfo?.[el] !== "block";
      });

    const finalOption = Option?.filter((el) => {
      const flag = childOptions?.includes(el?.value);
      if (flag === true) {
        return el;
      }
    });

    if (loginAccountType === "owner") {
      setShowOption(Option);
    } else {
      setShowOption(finalOption);
    }
  }, [parentAdditionaleInfo]);

  // function
  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const renderItems = (startIndex, endIndex) => {
    return (
      <div>
        <AdditionalInfoItemRow>
          <Col xs={0} sm={0} md={0} lg={10} xl={12}></Col>
          <Col xs={24} sm={24} md={24} lg={14} xl={12}>
            <div className="tab-headers">
              <div span={8}>Block</div>
              <div span={8}>View</div>
              <div span={8}>Allow</div>
            </div>
          </Col>
        </AdditionalInfoItemRow>
        {showOption.slice(startIndex, endIndex)?.map((item, index) => {
          return (
            <ModalCardItem
              item={item}
              key={item?.value}
              index={endIndex}
              parentAdditionaleInfo={parentAdditionaleInfo}
              accountType={accountType}
              parentForm={parentForm}
            />
          );
        })}
      </div>
    );
  };

  return (
    <AdditionalInfoDiv>
      {showOption.length > 0 && (
        <Button onClick={showModal} style={{ width: "100%" }}>
          Additional Info
        </Button>
      )}

      <StyledThemeInput.Modal
        title="Additional Info"
        className="additional-info-modal"
        centered
        open={open}
        onOk={handleOk}
        forceRender
        onCancel={handleCancel}
        width={"100%"}
        // className="app-modal"
        footer={[
          <Button key="ok" type="primary" onClick={handleOk}>
            OK
          </Button>,
        ]}
        style={{ padding: "0rem 3.5rem" }}
      >
        <div>
          <Card bordered={false} style={{ width: "100%", height: "100%" }}>
            <Row style={{ width: "100%", margin: "0px" }} gutter={[20, 30]}>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                {renderItems(0, Math.ceil(showOption?.length / 2))}
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                {renderItems(
                  Math.ceil(showOption?.length / 2),
                  showOption?.length
                )}
              </Col>
            </Row>
          </Card>
        </div>
      </StyledThemeInput.Modal>
    </AdditionalInfoDiv>
  );
};

export default React.memo(AdditionalInfoView);

export const ModalCardItem = ({ item, parentAdditionaleInfo, accountType }) => {
  const dispatch = useDispatch();
  const view = additionalInfoNotView?.includes(item?.value);
  // redux
  const parentValidationDetails = useSelector((state) => state.authSlice);
  const parentAccountType = parentValidationDetails.userDetail.accountType;

  // function
  const handleChange = (e, item) => {
    let value = e.target.value;
    let name = item?.value;
    if (
      parentAdditionaleInfo?.[name] === "allow" ||
      (parentAdditionaleInfo?.[name] === "view" && value !== "allow") ||
      accountType === "masterAdmin"
    ) {
      dispatch(
        setNewAccountInfo({
          parent_key: "accountDetails",
          key: "additionalInfo",
          value: { [name]: value },
        })
      );
    }
  };

  return (
    <Row style={{ marginBottom: "1rem" }}>
      <AdditionalInfoModalCard xs={24} sm={24} md={24} lg={10} xl={12}>
        {item?.label}
      </AdditionalInfoModalCard>
      <Col
        xs={24}
        sm={24}
        md={24}
        lg={14}
        xl={12}
        style={{ display: "flex", alignItems: "center" }}
      >
        <Form.Item
          name={item.value}
          // initialValue={InitialAdditionalInfo[item?.value]}
        >
          <Radio.Group
            onChange={(e) => handleChange(e, item)}
            buttonStyle="solid"
            style={{ gap: "2rem" }}
            // value={additionalInfo[item?.value]}
          >
            <Radio.Button value="block">block</Radio.Button>
            <Radio.Button value="view" disabled={view ? true : false}>
              view
            </Radio.Button>
            <Radio.Button
              value="allow"
              disabled={
                parentAccountType === "owner"
                  ? false
                  : parentValidationDetails?.MarketAccessDetails?.additional?.[
                      item.value
                    ] === "view"
              }
            >
              Allow
            </Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Col>
    </Row>
  );
};
