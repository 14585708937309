import { batch, useDispatch, useSelector } from "react-redux";
import { Checkbox, Col, Row } from "antd";
import {
  resetSelectedMarket,
  setNewAccountInfo,
} from "../../../store/slice/userAccountSlice";
import ThemeDropDown from "../../../components/ThemeDropDown";
import { accountObj, options } from "../../../Helper/constant";
import React, { useEffect, useState } from "react";
import { removeBlockedAndEdited } from "../../../store/slice/scriptSettingsRequestSlice";
import ThemeDatePicker from "../../../components/ThemeDatePicker";
import { DemoColumn } from "./style";

const HeaderView = ({ is_edits }) => {
  const dispatch = useDispatch();

  //state
  const [finalOption, setFinalOption] = useState([]);

  //redux
  const accountType = useSelector(
    (state) => state.userAccountSlice?.accountType
  );
  const isDemoAccount = useSelector(
    (state) => state?.userAccountSlice?.isDemoAccount
  );

  const userDetail = useSelector((state) => state.authSlice?.userDetail);
  const parentDetails = useSelector(
    (state) => state.authSlice?.MarketAccessDetails
  );

  //functions
  const onChangeAccountType = (name, value, options) => {
    dispatch(removeBlockedAndEdited());
    dispatch(resetSelectedMarket());
    batch(() => {
      dispatch(setNewAccountInfo({ [name]: value ? value[0] : null }));

      dispatch(
        setNewAccountInfo({
          authorityLevel: value ? options?.[0]?.authorityLevel : null,
        })
      );
    });
  };

  //life cycle
  useEffect(() => {
    if (userDetail?.accountType === "owner") {
      setFinalOption([...finalOption, options.user.accountType[1]]);
    } else {
      let temp = [];
      for (let i = 0; i < options.user.accountType.length; i++) {
        if (
          options.user.accountType[i].authorityLevel >
          userDetail?.authorityLevel
        ) {
          if (
            userDetail.accountType === "customer" ||
            userDetail.accountType === "broker"
          ) {
            break;
          } else {
            if (
              parentDetails?.accountDetails?.[
                accountObj[options?.user?.accountType?.[i].value]
              ] === 0
            ) {
              // temp.push({
              //   ...options.user.accountType[i],
              //   disabled: true,
              // });
            } else {
              temp.push(options?.user?.accountType?.[i]);
            }
          }
        }
      }
      setFinalOption(temp);
    }
  }, [userDetail, parentDetails]);

  useEffect(() => {
    let obj = {
      accountType: "masterAdmin",
      authorityLevel: 1,
    };
    if (userDetail?.accountType === "owner" && !is_edits) {
      dispatch(setNewAccountInfo(obj));
    } else if (finalOption.length !== 0 && !is_edits) {
      let showAccount = false;
      for (let i = 0; i < finalOption.length; i++) {
        if (
          parentDetails?.accountDetails?.[accountObj?.[finalOption[i].value]] >
          0
        ) {
          let obj = {
            accountType: finalOption?.[i].value,
            authorityLevel: finalOption?.[i].authorityLevel,
          };

          dispatch(setNewAccountInfo(obj));
          showAccount = true;
          break;
        }
      }
      if (!showAccount) {
        dispatch(setNewAccountInfo({ accountType: null }));
      }
    } else if (finalOption.length === 0 && !is_edits) {
      dispatch(setNewAccountInfo({ accountType: null }));
    }
  }, [is_edits, parentDetails, finalOption]);

  const handleDemoAccount = (e) => {
    const { name, checked } = e.target;
    dispatch(setNewAccountInfo({ [name]: checked }));
  };

  return (
    <Row align={"middle"} justify="space-between" style={{ width: "100%" }}>
      <Col xs={24} sm={12} md={8} lg={6} xl={3}>
        <ThemeDropDown
          name={"accountType"}
          title={"Account Type"}
          placeholder="Select Account"
          options={finalOption}
          // width={"16rem"}
          required
          $value={accountType}
          $onChange={onChangeAccountType}
          disabled={is_edits}
          formProps={{
            name: "accountType",
          }}
        />
      </Col>

      {/* <DemoColumn xs={24} sm={12} md={8} lg={6} xl={3}>
        <Checkbox
          name="isDemoAccount"
          onChange={handleDemoAccount}
          checked={isDemoAccount}
          disabled={is_edits}
        >
          Demo Account
        </Checkbox>
        <ThemeDatePicker
          title="Expiry Date"
          formProps={{
            name: "expiryDate",
          }}
          inputProps={{
            name: "expiryDate",
          }}
        />
      </DemoColumn> */}
    </Row>
  );
};

export default React.memo(HeaderView);
