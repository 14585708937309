import React, { useEffect, useRef, useState } from "react";
import ThemeCard from "../../components/ThemeCard";
import { Col, Row, Tag, notification } from "antd";
import { FilePdfOutlined, LinkOutlined } from "@ant-design/icons";
import ThemeCardTable from "../../components/ThemeCardTable";
import { useQuery } from "@tanstack/react-query";
import APICall from "../../APICall";
import EndPoints from "../../APICall/EndPoints";
import ValanComponant from "../../components/ValanComponant/ValanComponant";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import UserFilterComponant from "../../components/UserFilterComponant/UserFilterComponant";
import {
  setUserClearFilter,
  setSummaryDataFilter,
  setPositionFromSummay,
  setValanValue,
} from "../../store/slice/globleSlice";
import MarketScriptList from "../../components/MarketScriptList/MarketScriptList";
import { showValue } from "../../components/CommanFuction/CommanFunction";
import { formatToINRForSummay } from "../../components/FormateToINR/formatToINR";
import GetDate from "../../components/DatePicker/GetDate";
import {
  PdfDiv,
  ResetButton,
  StyledSpan,
  UserInfoDiv,
  accountTypeObj,
  buttonTitles,
} from "./style";
import useGetQueryParams from "../../hooks/useGetQueryParams";
import { StyledButton } from "../../styles/commonStyle";

const brokerColumns = [
  {
    title: "%(Sharing)",
    dataIndex: "",
    align: "center",
    key: "time",
    width: "6rem",
    render: (text, record) => {
      if (record?.brokerPercentage) {
        return <div>{formatToINRForSummay(record?.brokerPercentage * -1)}</div>;
      } else {
        return "-";
      }
    },
  },
  {
    title: "BR(Sharing)",
    dataIndex: "",
    align: "center",
    key: "id",
    width: "6rem",
    render: (text, record) => {
      if (record?.brokerBrokerage) {
        return <div>{formatToINRForSummay(record?.brokerBrokerage)}</div>;
      } else {
        return "-";
      }
    },
  },
];

function SummaryReport() {
  const valueRef = useRef({});
  const { params, setSearchParams } = useGetQueryParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // state only for render page after getting valan data from child component because summary api call after getting valan data
  const [getValan, setGetValan] = useState(false);

  //redux
  const loginDetails = useSelector((state) => state.authSlice?.userDetail);
  const filterUser = useSelector((state) => state?.globleSlice?.filterUsers);
  const loginId = loginDetails?.userId;
  const isCustomer = loginDetails?.accountType === "customer";
  const isBroker = loginDetails?.accountType === "broker";

  // life cycle
  useEffect(() => {
    if (Object.keys(params)?.length === 0) {
      setSearchParams({ userId: loginId });
    }
  }, [params]);

  useEffect(() => {
    dispatch(setValanValue(true));
    setSearchParams({
      ...params,
      userId: params?.userId ? params.userId : loginId,
    });

    if (isCustomer) setSummaryColumn(columns?.filter((el, index) => index < 4));

    if (isBroker) {
      const brColumn = [
        ...columns?.filter((el, index) => index < 7 && ![4, 5].includes(index)),
        ...brokerColumns,
      ];
      setSummaryColumn(brColumn);
    }
  }, []);

  // api call
  const getSummeryReport = async () => {
    let isAllow = filterUser?.includes(Number(params?.userId));
    if (!isAllow) {
      notification.error({ message: "User Id is not valid" });
      return [];
    }
    let obj = {
      userId: Number(params?.userId),
      marketName: params?.marketName || null,
      symbolName: params?.symbolName || null,
      valan: params?.valan || valueRef?.current?.valan || null,
      startDate: params?.startDate || null,
      endDate: params?.endDate || null,
    };

    let response = await APICall("post", `${EndPoints.summaryReport}`, obj);
    return response?.data?.data;
  };

  const {
    isLoading,
    error: summaryError,
    data: summaryData,
  } = useQuery({
    queryKey: ["getSummeryReport", params],
    queryFn: getSummeryReport,
    enabled:
      Object.keys(params)?.length > 0 && valueRef?.current?.valan !== undefined,
  });

  if (summaryError) notification.error({ message: summaryError?.message });

  // column
  const columns = [
    isLoading
      ? {
          title: "SR NO.",
          dataIndex: "srNo",
          key: "srNo",
          align: "center",
          width: "4rem",
        }
      : {
          title: "SR NO.",
          dataIndex: "srNo",
          key: "srNo",
          align: "center",
          width: "4rem",
          render: (text, record, index) => {
            return index + 1;
          },
        },
    {
      title: "USER INFO",
      dataIndex: "userId",
      key: "userId",
      align: "center",
      width: "16rem",
      render: (text, record) =>
        !isLoading && (
          <UserInfoDiv>
            <span
              style={{ marginRight: "auto", textAlign: "left" }}
            >{`${record?.userId} (${record?.AccountName})`}</span>
            <Tag color="magenta" bordered={false} style={{ fontSize: "1rem" }}>
              {accountTypeObj[record?.AccountType]}
            </Tag>
          </UserInfoDiv>
        ),
    },
    {
      title: "VALAN",
      dataIndex: "valan",
      key: "valan",
      align: "center",
      width: "10rem",
      render: (text, record) => {
        return !isLoading && <span>{record?.valan?.valanName}</span>;
      },
    },
    {
      title: "TOTAL VALAN BILL",
      dataIndex: "totalValanBill",
      key: "totalValanBill",
      align: "center",
      width: "16rem",
      render: (text, record) => {
        const valanId = valueRef.current?.valan;
        return (
          !isLoading && (
            <StyledSpan
              style={{ display: "flex", justifyContent: "space-around" }}
            >
              <div style={{ width: "50%", textAlign: "right" }}>
                {showValue(record?.totalNetAmount)}
              </div>
              <PdfDiv>
                <FilePdfOutlined
                  onClick={() => {
                    navigate(`/valanBill/${record?.userId}`);
                    dispatch(
                      setSummaryDataFilter({
                        // ...valueRef.current,
                        withoutBrokerage: false,
                        parentId: valueRef.current?.userId
                          ? valueRef.current?.userId
                          : loginId,
                        marketName: valueRef.current?.value?.marketName || null,
                        symbolName: valueRef.current?.value?.symbolName || null,
                        valan: valueRef.current?.valan || null,
                        startDate: valueRef.current?.startDate || null,
                        endDate: valueRef.current?.endDate || null,
                      })
                    );
                  }}
                />
              </PdfDiv>
            </StyledSpan>
          )
        );
      },
    },
    {
      title: "NET POSITION",
      dataIndex: "netPosition",
      key: "netPosition",
      align: "center",
      width: "7rem",
      render: (text, record) => {
        // console.log("record", record);
        return (
          !isLoading && (
            <PdfDiv>
              <LinkOutlined
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate(`/trading/position`);
                  dispatch(
                    setPositionFromSummay({
                      userId: record?.userId,
                      userName: record?.AccountName,
                      accountType: record?.AccountType,
                    })
                  );
                }}
              />
            </PdfDiv>
          )
        );
      },
    },
    {
      title: "WITHOUT BROKERAGE",
      dataIndex: "withoutBrokerage",
      key: "withoutBrokerage",
      align: "center",
      width: "16rem",
      render: (text, record) => {
        const valanId = valueRef.current?.valan;
        return (
          !isLoading && (
            <StyledSpan
              style={{ display: "flex", justifyContent: "space-around" }}
            >
              <div style={{ width: "50%", textAlign: "right" }}>
                {showValue(record?.withoutBrokerage)}
              </div>
              <PdfDiv>
                <FilePdfOutlined
                  onClick={() => {
                    navigate(`/valanBill/${record?.userId}`);
                    dispatch(
                      setSummaryDataFilter({
                        withoutBrokerage: true,
                        parentId: valueRef.current?.userId
                          ? valueRef.current?.userId
                          : loginId,
                        marketName: valueRef.current?.value?.marketName || null,
                        symbolName: valueRef.current?.value?.symbolName || null,
                        valan: valueRef.current?.valan || null,
                        startDate: valueRef.current?.startDate || null,
                        endDate: valueRef.current?.endDate || null,
                      })
                    );
                  }}
                />
              </PdfDiv>
            </StyledSpan>
          )
        );
      },
    },
    {
      title: "BROKERAGE",
      dataIndex: "brokerage",
      key: "brokerage",
      align: "center",
      width: "12rem",
      render: (text, record) => {
        return (
          !isLoading && (
            <div>{formatToINRForSummay(record?.brokerage || 0)}</div>
          )
        );
      },
    },
    {
      title: "DOWNLINE BILL",
      dataIndex: "downlinem2m",
      key: "downlinem2m",
      align: "center",
      width: "12rem",
      render: (text, record) => {
        const downlineValue = record?.downlinem2m * -1;
        return (
          !isLoading && <span>{formatToINRForSummay(downlineValue || 0)}</span>
        );
      },
    },
    {
      title: "UPLINE BILL",
      dataIndex: "uplinem2m",
      key: "uplinem2m",
      align: "center",
      width: "12rem",
      render: (text, record) => {
        const uplineValue = record?.uplinem2m * -1;
        return (
          !isLoading && <span>{formatToINRForSummay(uplineValue || 0)}</span>
        );
      },
    },
    {
      title: "SELF BILL",
      dataIndex: "selfm2m",
      key: "selfm2m",
      align: "center",
      width: "12rem",
      render: (text, record) => {
        const selfValue = record?.selfm2m * -1;
        return (
          !isLoading && <span>{formatToINRForSummay(selfValue || 0)}</span>
        );
      },
    },
  ];

  //state
  const [summaryColumn, setSummaryColumn] = useState(columns);

  // function
  const handleSubmit = () => {
    let payloadValue = valueRef.current;
    let valueObj = {
      userId: payloadValue?.userId || loginId,
      marketName: payloadValue?.value?.marketName || null,
      symbolName: payloadValue?.value?.symbolName || null,
      valan: payloadValue?.valan || null,
      startDate: payloadValue?.startDate?.toISOString() || null,
      endDate: payloadValue?.endDate?.toISOString() || null,
      page: 1,
    };

    for (let key in valueObj) {
      if (!valueObj[key]) {
        delete valueObj[key];
      }
    }
    setSearchParams(valueObj);
  };

  const handleReset = () => {
    dispatch(setUserClearFilter(true));
    // valueRef.current = {};
    setSearchParams({ userId: loginId });
  };

  return (
    <>
      <ThemeCard>
        <Row gutter={[10, 15]}>
          <MarketScriptList valueRef={valueRef} />
          <ValanComponant valueRef={valueRef} setGetValan={setGetValan} />
          <GetDate valueRef={valueRef} />
          <UserFilterComponant valueRef={valueRef} deducted={"Broker"} />
        </Row>

        <Row style={{ marginTop: "2rem" }} gutter={[10, 10]}>
          <Col xs={24} sm={12} md={4} lg={3} xl={2}>
            <StyledButton type="primary" onClick={handleSubmit}>
              Search
            </StyledButton>
          </Col>
          <Col xs={24} sm={12} md={4} lg={3} xl={3}>
            <ResetButton type="primary" onClick={handleReset}>
              Reset / Refresh
            </ResetButton>
          </Col>
        </Row>
      </ThemeCard>

      <ThemeCardTable
        buttonType={isCustomer ? false : true}
        buttonTitle={buttonTitles}
        data={summaryData}
        column={summaryColumn}
        isLoading={isLoading}
        filtertype="summary"
        footer={!isCustomer && summaryData?.length > 0 ? true : false}
      />
    </>
  );
}

export default SummaryReport;
