import React, { useEffect, useMemo, useRef, useState } from "react";
import ThemeCard from "../../components/ThemeCard";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Pagination,
  Row,
  Skeleton,
  Tabs,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import {
  BlockScriptTitle,
  CheckBoxColumn,
  EmptyDiv,
  IndexColumn,
  PaginationColumn,
  PaginationRow,
  ShowEntryColumn,
  StyleTitle,
  StyledCollapse,
  StyledRow,
  TableTitle,
  TitleColumn,
} from "./style";
import EditBlockScript from "./EditBlockScript";
import {
  useBlockScriptList,
  useEditUserDetails,
  useUpdateCSV,
} from "../../APICall/apiHooks";
import ThemeDropDown from "../../components/ThemeDropDown";
import {
  TableTitleSearch,
  itemOption,
} from "../../components/ThemeCardTable/style";
import { marketTag } from "../../Helper/constant";
import { SearchOutlined } from "@ant-design/icons";
import ThemeInput from "../../components/ThemeInput";
import { debounce } from "lodash";
import UserFilterComponant from "../../components/UserFilterComponant/UserFilterComponant";
import { StyledButton } from "../../styles/commonStyle";
import { setUserClearFilter } from "../../store/slice/globleSlice";

function TradingBlocked() {
  //state
  const [unBlock, setUnBlock] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    totalPage: 1,
    totalCount: 1,
    pageSize: 10,
    data: [],
  });

  const scriptRef = useRef({});
  const searchRef = useRef("");
  const valueRef = useRef({});
  const [form] = Form.useForm();
  const [marketWiseForm] = Form.useForm();
  const dispatch = useDispatch();

  // custom hook for update csv
  const { updateCSVData, updateCSVLoading, updateCSVMutate } = useUpdateCSV();

  // redux
  const loginUserId = useSelector(
    (state) => state.authSlice?.userDetail?.userId
  );
  //state
  const [userId, setUserId] = useState(loginUserId);
  //redux
  const loginAccountType = useSelector(
    (state) => state.authSlice?.userDetail?.accountType
  );
  const marketList = useSelector((state) => state?.globleSlice?.marketListData);

  const isOwner = loginAccountType === "owner";
  const canShowBlock =
    loginAccountType !== "customer" && loginAccountType !== "broker";

  // custom hook for get blocked scripts
  const { allBlockScriptData, allBlockScriptLoading } =
    useBlockScriptList(userId);

  // custom hook for edit user, use for edit block script
  const { editUserLoading, editUserData, editUserMutate } =
    useEditUserDetails(loginUserId);

  // life cycle
  useEffect(() => {
    if (allBlockScriptData) {
      const size = canShowBlock ? 10 : 20;
      const total = allBlockScriptData?.length;
      setPagination({
        page: 1,
        totalPage: Math.ceil(total / size),
        totalCount: total,
        data: allBlockScriptData.slice(0, size),
        pageSize: size,
      });
      const initialFormValues = {};
      allBlockScriptData.forEach((item) => {
        initialFormValues[
          `${item.Script}$${item.marketName}$${item.blockedBy}`
        ] = false;
      });
      form.setFieldsValue(initialFormValues);
      setUnBlock(false);
    }
  }, [allBlockScriptData]);

  // function
  const tabList = useMemo(() => {
    return marketList?.map((el, index) => {
      return {
        key: index + 1,
        label: el?.label,
        children: (
          <EditBlockScript
            form={marketWiseForm}
            tab={el?.scriptName}
            scriptRef={scriptRef}
            preBlocked={allBlockScriptData?.filter(
              (item) => item?.blockedBy !== "Blocked BY NSE"
            )}
          />
        ),
      };
    });
  }, [marketList]);

  const items = useMemo(() => {
    return [
      {
        key: "1",
        label: <StyleTitle level={5}>Edit Block Script</StyleTitle>,
        children: <Tabs defaultActiveKey="1" items={tabList} />,
      },
    ];
  }, [marketList]);

  const middleIndex = Math.ceil(pagination.data?.length / 2);
  const leftItems = pagination.data?.slice(0, middleIndex);
  const rightItems = pagination.data?.slice(middleIndex);

  const handleUnBlock = (value) => {
    const allValue = form.getFieldValue();
    const filteredMarket = Object.entries(allValue)?.reduce(
      (acc, [key, isBlock]) => {
        let [symbolName, marketName, blockedBy] = key.split("$");

        if (isBlock) {
          const findindex = acc.findIndex(
            (element) => element?.scriptName === marketName
          );
          if (findindex === -1) {
            let obj = {
              scriptName: marketName,
              blockScript: [
                { symbol: symbolName, status: "unblock", blockedBy: "self" },
              ],
            };
            acc.push(obj);
          } else {
            acc[findindex].blockScript.push({
              symbol: symbolName,
              status: "unblock",
              blockedBy: "self",
            });
          }
        }
        return acc;
      },
      []
    );

    const payload = {
      accountType: loginAccountType,
      marketAccess: filteredMarket,
    };
    form.resetFields();
    editUserMutate(payload);
  };

  const handleFomChange = (e) => {
    const formValue = form.getFieldValue();

    const hasAnyTruthyValue = Object.values(formValue).some(Boolean);
    setUnBlock(hasAnyTruthyValue);
  };
  const handleUpdateCSV = () => {
    updateCSVMutate();
  };

  const handlePageChange = (value) => {
    const start = (value - 1) * pagination.pageSize;
    const { value: search, data } = searchRef.current;
    const isSearch = search?.length > 0;
    setPagination((pre) => ({
      ...pre,
      page: value,
      data: isSearch
        ? data.slice(start, start + pagination.pageSize)
        : allBlockScriptData.slice(start, start + pagination.pageSize),
    }));
  };

  const handlePageSizeChange = (value) => {
    value = value[0];
    const { value: search, data } = searchRef.current;
    const isSearch = search?.length > 0;
    setPagination((pre) => ({
      page: 1,
      totalPage: Math.ceil(pre?.totalCount / value),
      totalCount: pre?.totalCount,
      data: isSearch
        ? data.slice(0, value)
        : allBlockScriptData.slice(0, value),
      pageSize: value,
    }));
  };

  const debouncedSearch = debounce((value) => {
    value = value?.toUpperCase();

    if (!value) {
      searchRef.current = { value: value, data: [] };
      setPagination((pre) => ({
        ...pre,
        data: allBlockScriptData?.slice(0, pre?.pageSize),
        page: 1,
        totalPage: allBlockScriptData?.length
          ? Math.ceil(allBlockScriptData?.length / pre?.pageSize)
          : 1,
        totalCount: allBlockScriptData?.length,
      }));
    } else {
      const filteredData = allBlockScriptData?.filter((el) =>
        el?.Script?.includes(value)
      );
      searchRef.current = { value: value, data: filteredData };

      setPagination((pre) => ({
        ...pre,
        data: filteredData?.slice(0, pre?.pageSize),
        page: 1,
        totalPage: filteredData?.length
          ? Math.ceil(filteredData?.length / pre?.pageSize)
          : 1,
        totalCount: filteredData?.length,
      }));
    }
  }, 300);

  const handleSearch = (e) => {
    const value = e.target.value;
    debouncedSearch(value);
  };

  const searchBlockedScript = () => {
    setUserId(valueRef.current?.userId || loginUserId);
  };

  const handleClear = () => {
    dispatch(setUserClearFilter(true));
    valueRef.current = {};
    setUserId(loginUserId);
  };

  return (
    <>
      <ThemeCard>
        <Row gutter={[10, 20]}>
          <UserFilterComponant valueRef={valueRef} />
        </Row>
        <Row style={{ marginTop: "2rem" }} gutter={[10, 10]}>
          <Col xs={24} sm={12} md={4} lg={3} xl={2}>
            <StyledButton type="primary" onClick={searchBlockedScript}>
              Search
            </StyledButton>
          </Col>
          <Col xs={24} sm={12} md={4} lg={3} xl={2}>
            <StyledButton type="primary" onClick={handleClear}>
              Clear Search
            </StyledButton>
          </Col>
        </Row>
      </ThemeCard>
      <ThemeCard
        title={
          <BlockScriptTitle align={"middle"} isOwner={isOwner}>
            <Col style={{ display: "flex" }}>
              <TableTitle>Blocked Script List</TableTitle>
            </Col>
            <TableTitleSearch xxl={4} xl={4} lg={6} md={8} sm={12} xs={24}>
              <ThemeInput
                wrapperwidth={"100%"}
                inputProps={{
                  placeholder: "Search anything here",
                  prefix: <SearchOutlined />,
                  style: { width: "100%" },
                  onChange: handleSearch,
                }}
                marginBottom={"-5px"}
              />
            </TableTitleSearch>
            {isOwner && (
              <Col>
                <Button
                  type="primary"
                  onClick={handleUpdateCSV}
                  loading={updateCSVLoading}
                >
                  Update CSV
                </Button>
              </Col>
            )}
          </BlockScriptTitle>
        }
      >
        <Form form={form} onFinish={handleUnBlock} onChange={handleFomChange}>
          <Row gutter={[20, 5]} style={{ overflowX: "auto" }}>
            <Col xs={24} md={12}>
              <StyledRow align={"middle"} justify={"space-between"}>
                <TitleColumn span={2}>Sr No.</TitleColumn>
                <TitleColumn span={12}>Script Name</TitleColumn>
                <TitleColumn span={10} style={{ paddingLeft: "1.5rem" }}>
                  Blocked By
                </TitleColumn>
              </StyledRow>
              {allBlockScriptLoading ? (
                <Skeleton active />
              ) : leftItems?.length === 0 ? (
                <EmptyDiv>No Data Found</EmptyDiv>
              ) : (
                leftItems?.map((el, index) => (
                  <Row key={index} gutter={[10, 5]} justify={"space-between"}>
                    <IndexColumn span={2}>{index + 1}</IndexColumn>
                    <CheckBoxColumn span={12} style={{ overflowX: "auto" }}>
                      <Form.Item
                        name={`${el?.Script}$${el?.marketName}$${el?.blockedBy}`}
                        // initialValue={true}
                        valuePropName="checked"
                      >
                        <Checkbox
                          name={`${el?.Script}$${el?.marketName}$${el?.blockedBy}`}
                          disabled={!(loginUserId === el?.userId)}
                        ></Checkbox>
                      </Form.Item>
                      <p>
                        {el?.Script}
                        {"  "}
                        <Tooltip title={el?.marketName} placement={"top"}>
                          <Tag color="magenta" bordered={false}>
                            {marketTag[el?.marketName]}
                          </Tag>
                        </Tooltip>
                      </p>
                    </CheckBoxColumn>

                    <Col span={10}>
                      <p>{`${el?.blockedBy} ${
                        el?.userId ? `(${el?.userId})` : ""
                      }${el?.date ? `(${el?.date})` : ""}`}</p>
                    </Col>
                  </Row>
                ))
              )}
            </Col>

            <Col xs={24} md={12}>
              <StyledRow
                align={"middle"}
                display="block"
                justify={"space-between"}
              >
                <TitleColumn span={2}>Sr No.</TitleColumn>
                <TitleColumn span={12}>Script Name</TitleColumn>
                <TitleColumn span={10} style={{ paddingLeft: "1.5rem" }}>
                  Blocked By
                </TitleColumn>
              </StyledRow>
              {allBlockScriptLoading ? (
                <Skeleton active />
              ) : (
                rightItems?.map((el, index) => (
                  <Row key={index} gutter={10} justify={"space-between"}>
                    <IndexColumn span={2}>
                      {index + middleIndex + 1}
                    </IndexColumn>

                    <CheckBoxColumn span={12}>
                      <Form.Item
                        name={`${el?.Script}$${el?.marketName}$${el?.blockedBy}`}
                        // initialValue={true}
                        valuePropName="checked"
                      >
                        <Checkbox
                          name={`${el?.Script}$${el?.marketName}$${el?.blockedBy}`}
                          disabled={!(loginUserId === el?.userId)}
                        ></Checkbox>
                      </Form.Item>
                      <p>
                        {el?.Script}{" "}
                        <Tooltip title={el?.marketName} placement={"top"}>
                          <Tag color="magenta" bordered={false}>
                            {marketTag[el?.marketName]}
                          </Tag>
                        </Tooltip>
                      </p>
                    </CheckBoxColumn>

                    <Col span={10}>
                      <p>{`${el?.blockedBy} ${
                        el?.userId ? `(${el?.userId})` : ""
                      }${el?.date ? `(${el?.date})` : ""}`}</p>
                    </Col>
                  </Row>
                ))
              )}
            </Col>
          </Row>
          <Divider style={{ margin: "0px" }} />
          <PaginationRow gutter={[10, 10]}>
            <ShowEntryColumn xs={24} sm={12}>
              <Typography.Text style={{ margin: "0px" }}>
                Show Entries
              </Typography.Text>
              <ThemeDropDown
                placeholder="Select"
                allowClear={false}
                value={pagination?.pageSize}
                onChange={handlePageSizeChange}
                options={itemOption}
              />
            </ShowEntryColumn>
            <PaginationColumn xs={24} sm={12}>
              <Pagination
                current={pagination.page}
                total={pagination.totalCount}
                pageSize={pagination.pageSize}
                onChange={handlePageChange}
                showSizeChanger={false}
              />
              {canShowBlock && (
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={!unBlock}
                  loading={editUserLoading}
                >
                  Un-Block
                </Button>
              )}
            </PaginationColumn>
          </PaginationRow>
        </Form>
      </ThemeCard>
      {canShowBlock && userId === loginUserId && (
        <StyledCollapse items={items} />
      )}
    </>
  );
}

export default TradingBlocked;
