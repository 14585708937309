import { SettingOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Dropdown,
  Modal,
  Radio,
  Row,
  Switch,
  Table,
  Tag,
  Typography,
} from "antd";
import styled from "styled-components";

export const marketWatchItem = [
  { key: 1, label: "Rename", value: "rename" },
  { key: 2, label: "Delete", value: "delete" },
];
export const StyledMarketButton = styled(Button)`
  width: 100%;
  color: ${(props) => (props.$isSelected ? "white" : "")};
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  font-weight: 400;
  padding: 1.5rem;
  justify-content: center;
  /* border: 1px solid red; */
  background-color: ${(props) =>
    props.$isSelected ? props.theme.token.colorPrimary : ""};
`;
export const SearchColumn = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  @media (min-width: 1200px) {
    margin-left: auto;
  }
`;
export const ThemeSwitch = styled(Switch)`
  background-color: rgba(0, 0, 0, 0.45);
  font-weight: 500;
  .ant-switch-inner {
    margin: 0rem 0.3rem !important;
  }
  .ant-switch-handle {
    margin: 0rem 0.1rem !important;
  }
`;
export const ThemeColumn = styled(Col)`
  display: flex;
  gap: 0.3rem;

  @media (min-width: 1200px) {
    margin-left: auto;
  }
`;
export const WatchListRow = styled(Row)`
  margin-block: 1rem;
  gap: 2rem;
`;
export const SettingIcon = styled(SettingOutlined)`
  cursor: pointer;
  font-size: large;
`;
export const WatchListName = styled(Col)`
  font-weight: 700;
  color: ${({ theme }) => theme.token.green5};
`;
export const RemoveAllDiv = styled.div`
  cursor: pointer;
  font-weight: 600;
  color: ${({ theme }) => theme.token.red4};
`;
export const MarketTableTag = styled(Tag)`
  margin-left: 0.5rem;
  font-weight: 500;
`;
export const StyledMarketButtonAll = styled(Dropdown.Button)`
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: ${(props) => (props.$isSelected ? "white" : "")};
  }

  & .ant-btn-compact-first-item {
    width: 100%;
  }
  & .ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-block: 1.5rem;
    background-color: ${(props) =>
      props.$isSelected ? props.theme.token.colorPrimary : ""} !important;
    color: ${(props) =>
      props.$isSelected ? props.theme.token.colorBgBase : ""};
    font-size: 1.1rem;
  }
`;
export const MarketWatchButton = styled(Button)`
  border-radius: ${(props) => props.theme.token.borderRadiusLG + "px"};
  font-size: 1.1rem;
  height: 3rem;
  background-color: ${(props) => props.theme.token.colorBgActionButton};
  box-shadow: ${(props) => props.theme.token.boxShadowNone};
  width: 98%;
  color: white;
  @media (max-width: 1079px) {
    font-size: 0.8rem;
    height: 2.5rem;
  }
`;
export const StyledRow = styled(Row)`
  width: 100%;
  display: flex;
  justify-content: row;
  align-items: center;
  background-color: ${(props) => props.theme.token.colorBgBase};
  padding: 25px 15px;
`;
export const ForexDescription = styled.pre`
  font-size: 1.2rem;
  margin: 0rem !important;
  padding-block: 0.5rem;
  line-height: 1.6rem;
`;
export const StyledDropDownItem = styled.div`
  width: 13rem;
  font-size: 1rem;
  padding-inline: 1rem;
  padding-block: 0.3rem;
  border-radius: ${(props) => props.theme.token.borderRadiusSM + "px"};
  cursor: pointer;
  background-color: ${(props) =>
    props?.$selected
      ? props.theme.token.controlItemBgHover
      : props.theme.token.controlItemBg};
  &:hover {
    background-color: ${(props) => props.theme.token.controlItemBgHover};
  }
`;
export const StyledTableWrapper = styled(Row)`
  width: 100%;
  display: flex;
  flex-direction: column;
  scrollbar-width: thin;
  flex-flow: column;
  background-color: rgb(240, 242, 245) !important;
  overflow: ${(props) => (props.isFullScreen ? "auto" : "unset")};
`;
export const TextTitle = styled(Typography.Title)`
  &.ant-typography {
    color: ${(props) => props.theme.token.colorTextLabel};
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 0.5rem;
    line-height: 1.125rem;
    text-align: left;
    & .styled-drop-down-star {
      color: ${(props) => props.theme.token.red4};
      font-size: 1rem;
    }
  }
`;
export const ActiveScriptWrapper = styled.div`
  align-items: center;
  font-weight: 600;
  line-height: 1.4;
`;
export const ActiveDot = styled(Col)`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5rem 0rem 0rem 0.5rem;
  background-color: ${(props) => props?.active};
`;
export const ExpairyDateRow = styled(Row)`
  font-size: 0.8rem;
  font-weight: 550;
`;
export const TradeModalRadio = styled(Radio)`
  font-weight: 600;
  color: ${(props) => props.theme.token.colorTextLabel};
  font-size: 1rem;
`;
export const TradeModalDivider = styled.div`
  height: 2px;
  background-color: ${(props) => props.theme.token.colorBgBase};
  margin-top: 1.5rem !important;
`;
export const TradeModalRadioGroup = styled(Radio.Group)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;
export const DropdownWatchListDiv = styled.div`
  background-color: ${(props) => props.theme.token.colorBgElevated};
  border-radius: ${(props) => props.theme.token.borderRadiusLG + "px"};
  box-shadow: ${(props) => props.theme.token.boxShadowSecondary};
  padding-block: 0.5rem;
`;
export const ScriptCardCol = styled(Col)`
  display: flex;
  align-items: center;
  gap: 0.1rem;
  cursor: pointer;
`;
export const ScriptCardText = styled(Typography.Text)`
  display: flex;
  align-items: center;
  /* margin-right: 0.1rem; */
  margin-bottom: 0px;
`;
export const ScriptCardTitleDiv = styled.div`
  color: ${(props) => (props.isactive ? "green" : "red")};
`;
export const ScriptCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
export const ScriptPositionDiv = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;
export const style = {
  headerScriptBtnStyle: {
    marginTop: "1.5rem",
  },
};
export const StyledCol = styled(Col)`
  margin-top: 1.5rem;

  @media (min-width: 770px) {
    margin-left: auto;
  }
`;
export const options = [
  { label: "Market", value: "market" },
  { label: "Limit/SL", value: "limit" },
  { label: "Intraday", value: "intraday" },
  { label: "Intraday-Limit", value: "intradaylimit" },
];
export const offlineOptions = [
  { label: "Market", value: "market" },
  { label: "Intraday", value: "intraday" },
  { label: "C/F", value: "C/F" },
  { label: "Bonus/Split", value: "bonus" },
];
export const StyledTheme = {
  Root: styled.div`
    display: flex;
    justify-content: space-between;
  `,
  Cell: styled.div`
    height: 6rem;
    width: 100%;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: ${(props) => props.theme.token.borderRadiusLG}px;
    background-color: ${(props) => props.theme.token.colorBgBase};
    color: ${(props) => props.theme.token.colorTextBase};

    & .cell-title {
      font-size: 1rem;
      font-weight: 600;
      color: ${(props) => props.theme.token.colorTextLabel};
    }
    .cell-number {
      font-weight: 500;
      font-size: 1rem;
      color: ${(props) => props.theme.token.colorTextLabel};
    }
  `,
  Modal: styled(Modal)`
    transform: none !important;
    width: 90vw !important;
    & .ant-modal-content {
      border-radius: ${(props) => props.theme.token.borderRadiusLG}px;
      padding: 2rem;
      background-color: ${(props) =>
        props.$type === 1
          ? "#4c4cf1"
          : // props.theme.token.tradeModalBuyColor
            props.theme.token.marketWatchDown};
    }
    .ant-modal-footer {
      display: none;
    }
    .ant-modal-close {
      display: none;
    }
  `,
  BuySellWrapper: styled(Row)`
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
  `,
  MarketTypeRadio: styled(Radio.Group)`
    width: 100%;
    & .ant-radio-button-wrapper {
      font-size: 0.9rem;
    }
    .ant-radio-button-wrapper {
      width: 25%;
      padding-inline: 5px;
      text-align: center;

      @media (max-width: 900px) {
        width: 50%;
      }
    }
  `,
  RadioDiv: styled.div`
    width: 30%;
    @media (max-width: 1150px) {
      width: 50%;
    }
    @media (max-width: 575px) {
      width: 100%;
    }
  `,
};
export const StyledThemeSimpleTable = {
  Root: styled.div`
    width: 100%;
    height: fit-content;
    background-color: ${(props) => props.theme.token.colorPrimary};
    border-radius: ${(props) => props.theme.token.borderRadiusLG}px;
    /* margin-bottom: 1.5rem; */
    /* overflow-x: auto; */
  `,
  Table: styled(Table)`
    transform: none !important;

    & .ant-table-thead tr .ant-table-cell {
      background-color: ${(props) => props.theme.token.colorPrimary};
      color: ${(props) => props.theme.token.colorTextLightSolid};
      font-size: 0.9rem;
      font-weight: 500;
    }
    @media screen and (max-width: 1079px) {
      .ant-table-thead tr .ant-table-cell {
        font-size: 0.6rem;
      }
      .ant-table-body .ant-table-cell {
        font-size: 0.75rem;
      }
    }
    @media screen and (max-width: 991px) {
      .ant-table-thead tr .ant-table-cell {
        font-size: 0.65rem;
      }
      .ant-table-body .ant-table-cell {
        font-size: 0.65rem;
      }
    }

    .ant-table-thead tr .ant-table-cell::before {
      display: none;
    }

    .ant-table-thead tr .ant-table-cell .ant-table-column-has-sorters {
      border-top: ${(props) =>
        `1px solid ${props.theme.token.colorBorder} !important`};
    }

    .ant-table-wrapper .ant-table-tbody > tr > td {
      padding: 0.5rem !important;
    }
    .ant-table-thead tr th {
      height: 2rem !important;
      padding: 0rem 0.5rem !important;
    }
    .collapseTable .ant-collapse-expand-icon span svg {
      fill: ${(props) => props.theme.token.colorTextLightSolid} !important;
    }
    .collapseTable .ant-collapse-header-text {
      color: ${(props) => props.theme.token.colorTextLightSolid};
    }

    .ant-switch-checked .ant-switch-inner {
      background-color: ${(props) =>
        props.theme.token.colorBgActionButton} !important;
    }

    .ant-table-thead {
      height: 3.18rem;
    }
    .ant-table-thead .ant-table-cell {
      &:hover {
        background: ${(props) =>
          props.theme.token.colorPrimaryBgHover} !important;
      }
    }
    .ant-table-body .ant-table-cell {
      padding: 0.5rem !important;
    }
    .ant-table-body .ant-table-cell {
      background-color: ${(props) =>
        props?.isDark ? "#1b1b1b" : ""} !important;
      color: ${(props) => (props?.isDark ? "white" : "")} !important;
    }

    .ant-table-row-expand-icon {
      color: ${(props) => (props.isDark ? "white" : "")} !important;
      background-color: ${(props) => (props.isDark ? "black" : "")} !important;
    }
  `,
};
export const CloseImage = styled.img`
  width: 25px;
  @media screen and (max-width: 1079px) {
    width: 20px;
  }
`;
export const StyledSwitch = styled(Switch)`
  /* Base switch styling */
  &.ant-switch {
    min-width: 50px;
    height: 24px;
    border-radius: 24px;
  }

  /* Handle positioning */
  .ant-switch-handle {
    width: 28px;
    height: 28px;
    top: -2px;
    left: -2px;

    &::before {
      border-radius: 50%;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }
  }

  &.ant-switch-checked .ant-switch-handle {
    left: calc(100% - 28px);
  }

  /* Dark mode styling */
  &.dark-mode {
    background-color: #ccc;

    &.ant-switch-checked {
      background-color: ${(props) => props?.theme?.token?.colorPrimary};
    }

    .ant-switch-handle::before {
      background: #281f1f url("https://i.ibb.co/FxzBYR9/night.png") no-repeat
        center center !important;
      background-size: 80%;
      /* content: ""; */
    }
  }

  /* Light mode styling */
  &.light-mode {
    background-color: #ccc;

    &.ant-switch-checked {
      background-color: #1890ff;
    }

    .ant-switch-handle::before {
      background: white url("https://i.ibb.co/7JfqXxB/sunny.png") no-repeat
        center center;
      background-size: 80%;
      /* content: ""; */
    }
  }
`;
export const MarketWatchFilterColumns = [
  { title: "Script Name", value: "script", initalShow: true, disable: true },
  { title: "Sell Rate", value: "sellRate", initalShow: true, disable: true },
  { title: "Buy Rate", value: "buyRate", initalShow: true, disable: true },
  {
    title: "LTP",
    value: "ltp",
    initalShow: true,
    disable: true,
  },
  { title: "CHANGE(%)", value: "change%", initalShow: true, disable: false },
  { title: "(₹)", value: "change(₹)", initalShow: true, disable: false },
  { title: "High", value: "high", initalShow: true, disable: false },
  {
    title: "Low",
    value: "low",
    initalShow: true,
    disable: false,
  },
  { title: "Open", value: "open", initalShow: true, disable: false },
  { title: "Close", value: "close", initalShow: true, disable: false },
  {
    title: "Position",
    value: "position",
    initalShow: true,
    disable: false,
  },
  { title: "M2M Buy", value: "m2mBuy", initalShow: true, disable: false },
  { title: "M2M Sell", value: "m2mSell", initalShow: true, disable: false },
  { title: "Remove All", value: "remove", initalShow: true, disable: false },
];
