import React, { useState } from "react";
import { Input, Button } from "antd";
import { useSelector } from "react-redux";
import { SendOutlined } from "@ant-design/icons";
import { MessageStatus } from "./commanFunction";
import { MessageInputDiv } from "./style";

const MessageInput = ({ activeUser, setMessages, doSend }) => {
  const [messageInput, setMessageInput] = useState("");

  //redux
  const login_id = useSelector((state) => state.authSlice?.userDetail?._id);

  const handleSendMessage = () => {
    if (!messageInput.trim() || !activeUser) return;

    const newMessage = {
      id: `m${Date.now()}`,
      timestamp: new Date(Date.now()).toISOString(),
      status: MessageStatus.SENT,
      senderId: login_id,
      receiverId: activeUser,
      message: messageInput,
    };

    // setMessages((prev) => ({
    //   ...prev,
    //   [activeUser]:
    //     prev[activeUser].set(newMessage.id, newMessage) ||
    //     new Map([newMessage.id, newMessage]),
    // }));

    setMessages((prev) => {
      const existingMessages = prev[activeUser] || new Map(); // Ensure it's always a Map
      const updatedMessages = new Map(existingMessages); // Create a new Map to trigger state updates

      updatedMessages.set(newMessage.id, newMessage); // Add new message

      return {
        ...prev,
        [activeUser]: updatedMessages, // Store the updated Map
      };
    });

    doSend({
      event: "sendMessage",
      payload: newMessage,
    });

    setMessageInput("");
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSendMessage();
    }
  };
  return (
    <MessageInputDiv>
      {/* <Button type="text" icon={<PaperClipOutlined />} />
      <Button type="text" icon={<SmileOutlined />} /> */}
      <Input
        placeholder="Type your message..."
        value={messageInput}
        onChange={(e) => setMessageInput(e.target.value)}
        onKeyPress={handleKeyPress}
        style={{ flex: 1 }}
      />
      <Button
        type="primary"
        icon={<SendOutlined />}
        onClick={handleSendMessage}
        style={{ marginLeft: "8px" }}
      />
    </MessageInputDiv>
  );
};

export default React.memo(MessageInput);
