import React, { useEffect, useRef, useState } from "react";
import ThemeCard from "../../components/ThemeCard";
import { Col, Pagination, Row, Tooltip, notification } from "antd";
import ThemeCardTable from "../../components/ThemeCardTable";
import APICall from "../../APICall";
import EndPoints from "../../APICall/EndPoints";
import { useQuery } from "@tanstack/react-query";
import UserFilterComponant from "../../components/UserFilterComponant/UserFilterComponant";
import MarketScriptList from "../../components/MarketScriptList/MarketScriptList";
import { useDispatch, useSelector } from "react-redux";
import {
  findMarket,
  formatNumber,
} from "../../components/FormateToINR/formatToINR";
import { setUserClearFilter } from "../../store/slice/globleSlice";
import { limitNumberLength } from "../../components/SocketLiveData";
import GetDate from "../../components/DatePicker/GetDate";
import useGetQueryParams from "../../hooks/useGetQueryParams";
import {
  readableString,
  valanBillDate,
} from "../../components/CommanFuction/CommanFunction";
import { PaginationRow, StyledButton } from "../../styles/commonStyle";
import { ActiveDot } from "../TradingTrade/style";
import ValanComponant from "../../components/ValanComponant/ValanComponant";

function RejectionLog() {
  // state
  const [pages, setPage] = useState({ page: 1 });
  // const [deleteLoading, setDeleteLoading] = useState({
  //   flag: false,
  //   index: null,
  // });
  const valueRef = useRef({});
  const { params, setSearchParams } = useGetQueryParams();
  const dispatch = useDispatch();

  // redux
  const loginAccountType = useSelector(
    (state) => state?.authSlice?.userDetail?.accountType
  );
  const loginUserId = useSelector(
    (state) => state?.authSlice?.userDetail?.userId
  );
  const filterUser = useSelector((state) => state?.globleSlice?.filterUsers);
  const isOwner = loginAccountType === "owner";

  // life cycle
  useEffect(() => {
    setSearchParams({
      ...params,
      userId: params?.userId ? params.userId : loginUserId,
    });
  }, []);

  useEffect(() => {
    if (Object.keys(params)?.length === 0) {
      setSearchParams({ userId: loginUserId });
    }
  }, [params]);

  // api call
  const getRejectionLog = async () => {
    let isAllow = filterUser?.includes(Number(params?.userId));
    if (!isAllow) {
      notification.error({ message: "User Id is not valid" });
      return [];
    }
    let obj = {
      query: {
        childRejectionLogs: {
          page: params?.page || 1,
          limit: params?.limit || 10,
        },
      },
      filters: {
        userId: Number(params?.userId),
        startDate: params?.startDate || null,
        endDate: params?.endDate || null,
        symbolName: params?.symbolName || null,
        marketName: params?.marketName || null,
      },
    };

    if (params?.valanId) obj.filters.valanId = params.valanId;

    let response = await APICall("post", `${EndPoints.getTradeLog}`, obj);
    const data = response?.data?.data?.childRejectionLogsPagination;
    setPage({
      page: data?.currentPage,
      nextPage: data?.nextPage,
      totalPages: data?.totalPages,
      totalCount: data?.totalCount,
    });

    return response?.data?.data?.childRejectionLogs;
  };

  const {
    isLoading,
    data,
    error: rejectionLogError,
  } = useQuery({
    queryKey: ["rejectionLog", params],
    queryFn: getRejectionLog,
    enabled: Object.keys(params)?.length > 0,
  });

  if (rejectionLogError)
    notification.error({ message: rejectionLogError?.message });

  // const handleExecute = async (record, index) => {
  //   setDeleteLoading({ flag: true, index: index });
  //   try {
  //     let response = await APICall(
  //       "get",
  //       `${EndPoints.exucuteRejectedTrade}${record?._id}`
  //     );
  //     if (response?.status === 200) {
  //       notification.success({ message: response.data?.message });
  //     } else {
  //       notification.error({ message: response?.data?.message });
  //     }
  //   } catch (error) {
  //     notification.error({ message: error?.message });
  //   } finally {
  //     setDeleteLoading({ flag: false, index: null });
  //   }
  // };

  // function
  const onChagePage = (page) => {
    setSearchParams({ ...params, page: page });
  };
  const handleFind = () => {
    let postData = valueRef.current;

    let payload = {
      userId: postData?.userId || loginUserId,
      marketName: postData?.value?.marketName || null,
      symbolName: postData?.value?.symbolName || null,
      endDate: postData?.endDate?.toISOString() || null,
      startDate: postData?.startDate?.toISOString() || null,
      valanId: postData?.valan || null,
      page: 1,
    };
    for (let key in payload) {
      if (!payload[key]) {
        delete payload[key];
      }
    }
    setSearchParams(payload);
  };
  const handleReset = () => {
    dispatch(setUserClearFilter(true));
    valueRef.current = {};
    setSearchParams({ userId: loginUserId });
  };

  // columns
  const columns = [
    {
      title: "DEVICE",
      dataIndex: "deviceType",
      key: "D",
      width: "50px",
      align: "left",
      render: (text, record) => {
        return (
          <Tooltip title={`${record?.marketStatus} trade`}>
            <div style={{ display: "flex", gap: "5px" }}>
              <div>{record?.deviceType}</div>
              <ActiveDot status={record?.marketStatus}></ActiveDot>
            </div>
          </Tooltip>
        );
      },
    },
    isLoading
      ? {
          title: "TIME",
          dataIndex: "client",
          key: "time",
          align: "left",
          width: "5rem",
        }
      : {
          title: "TIME",
          dataIndex: "client",
          key: "time",
          align: "left",
          width: "5rem",
          render: (text, record) => {
            const finalDate = valanBillDate(record?.tradeTime, true);
            return <span>{finalDate}</span>;
          },
        },
    {
      title: "CLIENT",
      dataIndex: "client",
      key: "client",
      align: "left",
      width: "10rem",
      render: (text, record) => {
        return (
          !isLoading && (
            <div>{`${record?.client?.userId} (${record?.client?.accountName})`}</div>
          )
        );
      },
    },
    {
      title: "MARKET",
      dataIndex: "marketName",
      key: "marketName",
      align: "left",
      width: "5rem",
      render: (text, record) => {
        return !isLoading && <div>{record?.marketName}</div>;
      },
    },
    {
      title: "SCRIPT",
      dataIndex: "symbol",
      key: "symbol",
      align: "left",
      width: "10rem",
      render: (text, record) => {
        const date = valanBillDate(record?.expiryDate, false);
        const opt = record?.marketName?.endsWith("OPT");
        const name = opt
          ? `${record?.symbolName} ${record?.series ? record?.series : ""} ${
              record?.strikePrice ? record?.strikePrice : ""
            }`
          : record?.symbolName;
        return (
          !isLoading && (
            <div>
              {name} {date}
            </div>
          )
        );
      },
    },
    {
      title: "ORDER TYPE",
      dataIndex: "ordertype",
      key: "ordertype",
      align: "left",
      width: "7rem",
      render: (text, record) => {
        return (
          !isLoading && (
            <div>{record?.title ? readableString(record?.title) : "-"}</div>
          )
        );
      },
    },
    {
      title: "B/S",
      dataIndex: "tradePattern",
      key: "tradePattern",
      align: "left",
      width: "4rem",
    },
    {
      title: "QTY (LOT)",
      dataIndex: "lot",
      key: "lot",
      align: "left",
      width: "7rem",
      render: (text, record) => {
        const lot = formatNumber(record?.lot);
        const quantity = formatNumber(record?.quantity);
        const data =
          record?.marketName === "NSE EQU" ? quantity : `${quantity} (${lot})`;
        return !isLoading && <div>{data}</div>;
      },
    },
    {
      title: "ORDER PRICE",
      dataIndex: "tradePrice",
      key: "tradePrice",
      align: "left",
      width: "6rem",
      render: (text, record) => {
        const price = findMarket(record?.marketName)
          ? limitNumberLength(record?.tradePrice)
          : record?.tradePrice?.toFixed(2);
        return !isLoading && <div>{price}</div>;
      },
    },
    {
      title: "CODE",
      dataIndex: "code",
      key: "code",
      align: "left",
      width: "3rem",
      render: (text, record) => {
        return !isLoading && <span>{record?.message?.code}</span>;
      },
    },
    {
      title: "REJECTION REASON",
      dataIndex: "message",
      key: "message",
      width: "15rem",
      align: "left",
      render: (text, record) => {
        return !isLoading && <div>{record?.message?.text}</div>;
      },
    },
    {
      title: "USER IP",
      dataIndex: "tradeIpAddress",
      key: "tradeIpAddress",
      align: "left",
      width: "8rem",
      render: (text, record) => {
        return !isLoading && <div>{record?.tradeIpAddress}</div>;
      },
    },
    {
      title: "ACCEPTED BY",
      key: "acceptedBy",
      dataIndex: "acceptedBy",
      align: "left",
      width: "7rem",
      render: (text, record) => {
        if (record?.logType === "UPDATE") {
          return (
            !isLoading && (
              <div>{`${record?.modifiedBy?.userId} (${record?.modifiedBy?.accountName})`}</div>
            )
          );
        } else {
          return <div style={{ textAlign: "center" }}>{"-"}</div>;
        }
      },
    },
  ];

  return (
    <>
      <ThemeCard>
        <Row gutter={[20, 20]}>
          <MarketScriptList valueRef={valueRef} />
          {isOwner && <ValanComponant valueRef={valueRef} />}
          <GetDate valueRef={valueRef} />
          {loginAccountType !== "customer" && (
            <UserFilterComponant valueRef={valueRef} />
          )}
        </Row>
        <Row gutter={[10, 15]} style={{ marginTop: "2rem" }}>
          <Col xs={24} sm={12} md={4} lg={3} xl={2}>
            <StyledButton type="primary" onClick={handleFind}>
              Search
            </StyledButton>
          </Col>
          <Col xs={24} sm={12} md={4} lg={3} xl={2}>
            <StyledButton type="primary" onClick={handleReset}>
              Clear Search
            </StyledButton>
          </Col>
        </Row>
      </ThemeCard>
      <ThemeCardTable
        buttonType={false}
        data={data}
        column={columns}
        isLoading={isLoading}
        headingStyle={false}
        isFilterTrue={true}
        filtertype="rejectionLog"
      />
      <PaginationRow align={"center"}>
        <Pagination
          size="large"
          current={params?.page || 1}
          pageSize={params?.limit || 10}
          total={pages?.totalCount || 1}
          onChange={onChagePage}
          showSizeChanger={false}
        />
      </PaginationRow>
    </>
  );
}

export default RejectionLog;
