import { theme, Avatar, Dropdown, Tag, Col, Badge, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  UserOutlined,
  LogoutOutlined,
  ReloadOutlined,
  ProfileOutlined,
  BellOutlined,
  NotificationOutlined,
  ExclamationCircleOutlined,
  CalculatorOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { HEADER_HEIGHT, sequence } from "../../Helper/constant";
import { setAuth } from "../../store/slice/authSlice";
import NiftySenView from "../NiftySenView/index";
import React, { useEffect, useState, useMemo, useCallback } from "react";
import { clearLocalStorage } from "../../store/store";
import APICall from "../../APICall";
import EndPoints from "../../APICall/EndPoints";
import {
  StyleHeader,
  DigitalClockContainer,
  ClockBorder,
  FlipDigitContainer,
  CurrentDigit,
  Colon,
  ActiveTotalUser,
  ActiveText,
  ActiveNumber,
  ExpDiv,
  ValidText,
  ExpText,
  TickerContainer,
  ScrollingMessage,
  IconWrapper,
} from "./index.style";
import { useCalculateMargin, useGetMarketStatus } from "../../APICall/apiHooks";
import { valanBillDate } from "../CommanFuction/CommanFunction";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const isMobile = breakpoint.xs || (breakpoint.sm && !breakpoint.md);

  //state
  const [showVideo, setShowVideo] = useState(false);
  const [dragPosition, setDragPosition] = useState({ x: 0, y: 0 });
  const [marketData, setMarketData] = useState([]);

  const { marginLoading, marginMutate } = useCalculateMargin({
    id: null,
    query: null,
  });
  // const { marketStatusData, marketStatusLoading } = useGetMarketStatus();

  //redux
  const userDetail = useSelector((state) => state.authSlice.userDetail);
  const hasExp = userDetail?.expiryDate;
  const isOwner = userDetail?.accountType === "owner";
  const isBroker = userDetail?.accountType === "broker";
  // const Usertoken = useSelector((state) => state.authSlice?.accessToken);
  // const socketflag = useSelector(
  //   (state) => state?.webSocketSlice?.webSocket?.socket
  // );

  // useEffect(() => {
  //   if (marketStatusData) {
  //     const sortedArray = marketStatusData?.sort((a, b) => {
  //       const indexA = sequence.indexOf(a.marketName);
  //       const indexB = sequence.indexOf(b.marketName);

  //       if (indexA === -1) return 1;
  //       if (indexB === -1) return -1;

  //       return indexA - indexB;
  //     });
  //     const marketString = sortedArray?.reduce((acc, curr) => {
  //       if (curr?.marketName) {
  //         acc += `${curr?.marketName}:
  //         ${curr?.sessionStartTime?.slice(0, 5)} -
  //         ${curr?.sessionEndTime?.slice(0, 5)}, `;
  //       }
  //       return acc;
  //     }, "");

  //     setMarketData(marketString);
  //   }
  // }, [marketStatusData]);

  //items
  const items = useMemo(
    () => [
      {
        key: "avatar",
        label: (
          <>
            Welcome {userDetail?.accountName} ({userDetail?.userId}){" "}
            <Tag
              color="magenta"
              bordered={false}
              style={{
                fontSize: "1rem",
                marginLeft: "0.5rem",
              }}
            >
              {userDetail?.accountType}
            </Tag>
          </>
        ),
        icon: <UserOutlined />,
      },
      !isOwner &&
        !isBroker && {
          key: "profile",
          label: <span>Profile</span>,
          icon: <ProfileOutlined />,
        },
      {
        key: "resetPassword",
        label: <span>Reset Password</span>,
        icon: <ReloadOutlined />,
      },
      isOwner && {
        key: "ResetAllMargin",
        label: <span>Re-set All Margin</span>,
        icon: <CalculatorOutlined />,
      },
      {
        key: "RulesAndRegulation",
        label: <span>Rules And Regulation</span>,
        icon: <ExclamationCircleOutlined />,
      },
      {
        key: "logout",
        label: <span>Logout</span>,
        icon: <LogoutOutlined />,
      },
    ],
    [userDetail]
  );
  // console.log("render");

  //api call
  const logout = useCallback(async () => {
    try {
      let response = await APICall("post", EndPoints.userLogout);
      console.log("response Logout", response);
    } catch (error) {
      console.log("error", error);
    } finally {
      dispatch(setAuth(null));
      localStorage.removeItem("token");
      clearLocalStorage();
      setTimeout(() => {
        localStorage.removeItem("persist:root");
      }, 1000);
      navigate("/login");
    }
  }, [dispatch, navigate]);

  //functions
  const handleMenuClick = useCallback(
    (value) => {
      if (value?.key === "logout") {
        logout();
      } else if (value?.key === "resetPassword") {
        navigate("/resetPassward");
      } else if (value?.key === "profile") {
        navigate("/profile");
      } else if (value?.key === "RulesAndRegulation") {
        navigate("/rules-and-regulation");
      } else if (value?.key === "ResetAllMargin") {
        marginMutate();
      }
    },
    [logout, navigate, marginMutate]
  );

  const menuProps = useMemo(
    () => ({
      items,
      onClick: handleMenuClick,
    }),
    [items, handleMenuClick]
  );
  return (
    <StyleHeader>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Col style={{ alignItems: "start" }}>
          <NiftySenView />
        </Col>
      </div>

      <div
        // style={style.icon_wrapper}
        style={{ display: "flex", alignItems: "center" }}
      >
        <>
          {/* <div style={{ position: "relative", marginRight: "1rem" }}>
          <Tooltip title={"CNBC LIVE"}>
            <PlaySquareOutlined
              style={{ fontSize: "24px", color: "white", cursor: "pointer" }}
              onClick={() => setShowVideo(true)}
            />
          </Tooltip>
          {showVideo && (
            <Draggable
              position={dragPosition}
              onDrag={(e, data) => setDragPosition({ x: data.x, y: data.y })}
              bounds="body"
            >
              <div
                style={{
                  position: "fixed",
                  zIndex: 1000,
                  boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                  background: "#f0f0f0",
                  padding: "10px",
                  borderRadius: "4px",
                  cursor: "move",
                }}
              >
                <div
                  style={{
                    padding: "5px",
                    marginBottom: "5px",
                    background: "#e0e0e0",
                    textAlign: "center",
                    borderRadius: "4px",
                    display: "flex",
                    color: "black",

                    justifyContent: "space-between",
                  }}
                >
                  <Typography.Text style={{ fontWeight: "600" }}>
                    CNBC LIVE
                  </Typography.Text>

                  <CloseOutlined
                    style={{
                      fontSize: "15px",
                      cursor: "pointer",
                    }}
                    onClick={() => setShowVideo(false)}
                  />
                </div>

                <iframe
                  width="560"
                  height="315"
                  src={`https://www.youtube.com/embed/${"Uyr9dCg0JyQ"}?autoplay=1&rel=0`}
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </Draggable>
          )}
        </div> */}
        </>
        {/* <LiveUserDivider type="vertical" />
        <TotalActiveUser /> */}
        {/* <ScrollingText marketData={marketData} /> */}
        {hasExp && (
          <ExpDiv>
            <ValidText>Valid till</ValidText>
            <ExpText>{valanBillDate(hasExp)}</ExpText>
          </ExpDiv>
        )}
        <DigitalClock />
        {/* <div style={{ ...style.iconWrapper }}>
          <Notification />
        </div> */}
        <IconWrapper>
          {marginLoading ? (
            <Spin indicator={<LoadingOutlined spin />} />
          ) : (
            <Dropdown
              menu={menuProps}
              placement="bottomLeft"
              arrow={{
                pointAtCenter: true,
              }}
            >
              <Avatar size={"small"} icon={<UserOutlined />} />
            </Dropdown>
          )}
        </IconWrapper>
      </div>
    </StyleHeader>
  );
};

export default React.memo(Header);

const DigitalClock = React.memo(() => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const hours = time.getHours().toString().padStart(2, "0");
  const minutes = time.getMinutes().toString().padStart(2, "0");
  const seconds = time.getSeconds().toString().padStart(2, "0");

  return (
    <DigitalClockContainer>
      <ClockBorder>
        <FlipDigit digit={hours[0]} />
        <FlipDigit digit={hours[1]} />
        <Colon>:</Colon>
        <FlipDigit digit={minutes[0]} />
        <FlipDigit digit={minutes[1]} />
        <Colon>:</Colon>
        <FlipDigit digit={seconds[0]} />
        <FlipDigit digit={seconds[1]} />
      </ClockBorder>
    </DigitalClockContainer>
  );
});

const FlipDigit = React.memo(({ digit }) => {
  const [flip, setFlip] = useState(false);

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     setFlip(true);
  //   }, 500);

  //   return () => clearTimeout(timeout);
  // }, [digit]);

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     setFlip(false);
  //   }, 1000);

  //   return () => clearTimeout(timeout);
  // }, [digit, flip]);

  return (
    <FlipDigitContainer>
      {/* <TopDigit flip={flip.toString()}>{digit}</TopDigit> */}
      <CurrentDigit>{digit}</CurrentDigit>
      {/* <BottomDigit flip={flip.toString()}>{digit}</BottomDigit> */}
    </FlipDigitContainer>
  );
});

// const ScrollingText = React.memo(({ marketData }) => {
//   const duration = marketData.length * 0.1; //
//   return (
//     <TickerContainer>
//       <ScrollingMessage duration={duration}>{marketData}</ScrollingMessage>
//     </TickerContainer>
//   );
// });

// const Notification = () => {
//   // state
//   const [m2mMessage, setm2mMessage] = useState([]);
//   const [m2mCount, setM2mCount] = useState(0);

//   // redux
//   const m2mAlert = useSelector((state) => state?.globleSlice?.m2mAlert);

//   // life cycle
//   useEffect(() => {
//     if (m2mAlert) {
//       const updatedM2m = m2mAlert?.map((el, index) => {
//         return {
//           ...el,
//           key: index,
//           label: <span>{`${el?.message}(${el?.userId}-${el?.userName})`}</span>,
//           icon: <NotificationOutlined />,
//         };
//       });
//       setm2mMessage(updatedM2m);
//       setM2mCount(updatedM2m.length);
//     }
//   }, [m2mAlert]);

//   // function
//   const notificationProps = {
//     items: m2mMessage,
//   };

//   const handleDropdownVisibleChange = (visible) => {
//     if (!visible) {
//       setM2mCount(0);
//     }
//   };

//   return (
//     <Dropdown
//       menu={notificationProps}
//       placement="bottomLeft"
//       arrow={{
//         pointAtCenter: true,
//       }}
//       trigger={["click"]}
//       onOpenChange={handleDropdownVisibleChange}
//       // onClick={handleDropdown}
//     >
//       <div>
//         <Badge count={m2mCount > 0 ? m2mCount : ""} size="small">
//           <BellOutlined style={{ fontSize: "1.4rem", color: "white" }} />
//         </Badge>
//       </div>
//     </Dropdown>
//   );
// };

// const TotalActiveUser = () => {
//   const total = useSelector((state) => state?.webSocketSlice?.totalLiveUser);

//   return (
//     <ActiveTotalUser>
//       <ActiveText>{`Active Users`}</ActiveText>
//       <ActiveNumber>{total ? total : 0}</ActiveNumber>
//     </ActiveTotalUser>
//   );
// };
