import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CaretDownOutlined, SettingOutlined } from "@ant-design/icons";
import { Dropdown, Grid } from "antd";
import Items, {
  BrokerItems,
  CustomerItems,
  DealerItems,
  UserItems,
} from "./NavbarItems";
import { options } from "../../Helper/constant";
import NavbarStyle from "./index.style";
import { useDispatch, useSelector } from "react-redux";
import {
  resetUserAccountSlice,
  setNewAccountInfo,
} from "../../store/slice/userAccountSlice";

const { useBreakpoint } = Grid;

const addValanSetting = (data) => {
  let element = {
    label: "Settings",
    to: "/settings",
    icon: <SettingOutlined />,
    subMenu: [
      {
        label: "Valan Setting",
        to: "/settings/valan-setting",
        key: "/settings/valan-setting",
      },
    ],
  };
  if (data[data?.length - 1]?.label !== "Settings") {
    data.push(element);
  }
  return data;
};

const initalNavItem = (userDetail, showValanSetting) => {
  switch (userDetail?.accountType) {
    case "owner":
      return Items;

    case "broker":
      return BrokerItems;

    case "customer":
      return CustomerItems;

    case "dealer":
      return showValanSetting ? addValanSetting(DealerItems) : DealerItems;

    default:
      return showValanSetting ? addValanSetting(UserItems) : UserItems;
  }
};

const Navbar = () => {
  const breakpoint = useBreakpoint();
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const isMobile = breakpoint.xs || (breakpoint.sm && !breakpoint.md);
  const dispatch = useDispatch();

  //states
  // const [isSidebarOpen, setSidebarOpen] = useState(false);
  // const [openSubmenu, setOpenSubmenu] = useState([]);

  //redux
  const userDetail = useSelector((state) => state.authSlice?.userDetail);
  const additionalSharing = useSelector(
    (state) => state.authSlice?.additionalSharing
  );
  const showValanSetting = additionalSharing?.valanDelete;

  //state
  const [navbarItem, setNavbarItem] = useState(
    initalNavItem(userDetail, showValanSetting)
  );

  const showAccountPointEntry = additionalSharing?.accountPointEntry;
  const showNewAccount = additionalSharing?.newClientAdd;
  const showIpAddressReport = additionalSharing?.ipAddressReport;
  const isRent = userDetail?.partnershipWith === "rent";

  // useEffect(() => {
  //   switch (userDetail?.accountType) {
  //     case "owner":
  //       setNavbarItem(Items);
  //       break;
  //     case "broker":
  //       setNavbarItem(BrokerItems);
  //       break;
  //     case "customer":
  //       setNavbarItem(CustomerItems);
  //       break;
  //     case "dealer":
  //       setNavbarItem(
  //         showValanSetting ? addValanSetting(DealerItems) : DealerItems
  //       );
  //       break;
  //     default:
  //       setNavbarItem(
  //         showValanSetting ? addValanSetting(UserItems) : UserItems
  //       );
  //       break;
  //   }
  // }, [userDetail]);

  useEffect(() => {
    if (!showAccountPointEntry) {
      setNavbarItem((pre) => {
        return pre.map((item) => {
          if (item?.label === "Accounts") {
            return {
              ...item,
              subMenu: item?.subMenu?.filter(
                (subItem) => subItem?.label === "Point Ledger"
              ),
            };
          } else {
            return item;
          }
        });
      });
    }
    if (
      !showNewAccount &&
      userDetail?.accountType !== "customer" &&
      userDetail?.accountType !== "broker"
    ) {
      setNavbarItem((pre) => {
        return pre.map((item) => {
          if (item?.label === "Users") {
            return {
              ...item,
              subMenu: item?.subMenu?.filter(
                (subItem) => subItem?.label === "Accounts"
              ),
            };
          } else {
            return item;
          }
        });
      });
    }
    if (!showIpAddressReport) {
      setNavbarItem((pre) => {
        return pre.map((item) => {
          if (item?.label === "Reports") {
            return {
              ...item,
              subMenu: item?.subMenu?.filter(
                (subItem) => subItem?.label !== "IP Address Report"
              ),
            };
          } else {
            return item;
          }
        });
      });
    }
    if (!isRent && userDetail?.accountType !== "owner") {
      setNavbarItem((pre) => {
        return pre.map((item) => {
          if (item?.label === "Users") {
            return {
              ...item,
              subMenu: item?.subMenu?.filter(
                (subItem) => subItem?.label !== "New Rent Account"
              ),
            };
          } else {
            return item;
          }
        });
      });
    }
  }, [additionalSharing, userDetail]);

  //functions
  // const toggleSidebar = () => {
  //   setSidebarOpen(!isSidebarOpen);
  // };

  const getAccountOption = useCallback(() => {
    let finalOption = [];

    if (userDetail?.accountType === "owner") {
      finalOption.push(options.user.accountType[1]);
    } else {
      for (let i = 0; i < options.user.accountType.length; i++) {
        if (
          options.user.accountType[i].authorityLevel >
          userDetail?.authorityLevel
        ) {
          if (
            userDetail.accountType == "customer" ||
            userDetail.accountType == "broker"
          ) {
            break;
          } else {
            finalOption.push(options.user.accountType[i]);
          }
        }
      }
    }
    if (finalOption.length !== 0) {
      let obj = {
        accountType: finalOption[0].value,
        authorityLevel: finalOption[0].authorityLevel,
      };
      dispatch(setNewAccountInfo(obj));
    }
  }, [userDetail]);

  const onSubMenuClicked = ({ key }) => {
    if (key === "/users/new-account") {
      dispatch(resetUserAccountSlice());
      getAccountOption();
      // window.open(key, "_blank");
    }
    //  else if (key === "/users/create-rent-account") {
    //   window.open(key, "_blank");
    // } else {
    //   navigate(key);
    // }
    navigate(key);
  };

  // const toggleSubmenu = (to) => {
  //   setOpenSubmenu((prev) => {
  //     if (prev.includes(to)) {
  //       // Create a new array excluding the item to be removed
  //       const updatedSubmenu = prev.filter((item) => item !== to);
  //       return updatedSubmenu;
  //     } else {
  //       // Create a new array by appending the item to the existing array
  //       const updatedSubmenu = [...prev, to];
  //       return updatedSubmenu;
  //     }
  //   });
  // };

  const handletitle = (e) => {
    e.preventDefault();
  };

  return (
    <NavbarStyle.Wrapper>
      {/* {loading && <LoadingBar />} */}
      <NavbarStyle.Items>
        {/* {isMobile && (
          <NavbarStyle.Item onClick={toggleSidebar}>
            {isSidebarOpen ? <CloseOutlined /> : <MenuOutlined />}
          </NavbarStyle.Item>
        )} */}
        {navbarItem?.map(({ to, subMenu, icon, label }, index) => {
          // const valid = isValid(label);

          if (subMenu) {
            return (
              <Dropdown
                key={index}
                menu={{
                  items: subMenu,
                  //  valid
                  //   ? subMenu
                  //   : subMenu?.filter(
                  //       (item) => item.label !== "New Rent Account"
                  //     ),
                  onClick: onSubMenuClicked,
                  selectable: true,
                  selectedKeys: pathname,
                }}
                placement="bottomLeft"
              >
                <NavbarStyle.Item
                  key={index}
                  $active={pathname.startsWith(to)}
                  onClick={handletitle}
                >
                  {icon}
                  {label}
                  <CaretDownOutlined />
                </NavbarStyle.Item>
              </Dropdown>
            );
          } else {
            return (
              <NavbarStyle.Item key={index} to={to} $active={pathname === to}>
                {icon}
                {label}
              </NavbarStyle.Item>
            );
          }
        })}
      </NavbarStyle.Items>

      {/* {isMobile && isSidebarOpen && (
        <div
          style={{
            position: "absolute",
            top: HEADER_HEIGHT,
            paddingTop: "10px",
            left: 0,
            width: "100%",
            zIndex: 100,
            height: MAIN_CONTENT_HEIGHT,
            backgroundColor: NAVBAR.BACKGROUND_COLOR,
            paddingInline: "2rem",
            borderTop: `1px solid gray`,
            overflowY: "auto",
          }}
        >
          {navbarItem?.map(({ to, subMenu, icon, label }, index) =>
            subMenu ? (
              <React.Fragment key={index}>
                <NavbarStyle.Item
                  style={{ justifyContent: "space-between" }}
                  $active={pathname.startsWith(to)}
                  $isMobile
                  onClick={() => toggleSubmenu(to)}
                >
                  <Space>
                    {icon}
                    {label}
                  </Space>
                  <ArrowDown />
                </NavbarStyle.Item>
                {openSubmenu.includes(to) && (
                  <div style={{ marginLeft: "2.5rem" }}>
                    {subMenu.map(({ to, icon, label }, index) => (
                      <NavbarStyle.Item
                        key={index}
                        to={to}
                        $active={pathname === to}
                        $isMobile
                        $subMenu
                        onClick={() => setSidebarOpen(false)}
                      >
                        {icon}
                        {label}
                      </NavbarStyle.Item>
                    ))}
                  </div>
                )}
              </React.Fragment>
            ) : (
              <NavbarStyle.Item
                onClick={() => setSidebarOpen(false)}
                key={index}
                to={to}
                $active={pathname === to}
                $isMobile
              >
                {icon}
                {label}
              </NavbarStyle.Item>
            )
          )}
          <CloseCircleOutlined
            onClick={() => setSidebarOpen(false)}
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "9rem",
              fontSize: "2rem",
              color: "white",
              marginBottom: "2rem",
            }}
          />
        </div>
      )} */}
    </NavbarStyle.Wrapper>
  );
};

export default React.memo(Navbar);

// const isValid = (label) => {
//   if (!isRent && userDetail?.accountType !== "owner" && label === "Users") {
//     return false;
//   } else {
//     return true;
//   }
// };
