import { Button, Table } from "antd";
import styled from "styled-components";

export const RootDiv = styled.div`
  margin-top: ${(props) =>
    props?.querykey === "allTrade" || props?.querykey === "pendingTrade"
      ? "0rem"
      : "2rem"};

  @media (max-width: 1200px) {
    margin-top: 2rem;
  }
`;

export const StyledTheme = {
  Root: styled.div`
    margin-top: 2rem;
  `,
  MainHeader: styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 3rem;
    align-items: center;
    margin-bottom: 1rem;
  `,
  Title: styled.div`
    font-size: 1.2rem;
    font-weight: 600;
    @media (max-width: 1079px) {
      font-size: 1rem;
    }
  `,
  RightHeaderWrapper: styled.div`
    display: flex;
    align-items: center;
    @media (max-width: 1440px) {
      align-items: center;
      display: flex;
    }
  `,
  ViewMore: styled.div`
    font-size: 1.2rem;
    font-weight: 600;
    color: ${(props) => props.theme.token.colorPrimary};
    text-decoration: underline;
    cursor: pointer;
    @media (max-width: 1079px) {
      font-size: 1rem;
    }
  `,
  AllowButton: styled(Button)`
    border-radius: ${({ theme }) => theme.token.borderRadiusLG};
    font-size: 1rem;
    height: 3rem;
    width: unset !important;
    background-color: ${({ theme }) => theme.token.colorBgActionButton};
    margin-right: 20px;
    @media (max-width: 1079px) {
      height: 2rem;
      font-size: 0.8rem;
    }
  `,
  Table: styled(Table)`
    transform: none !important;
    & .ant-table-thead tr .ant-table-cell {
      background-color: ${(props) => props.theme.token.colorPrimary};
      color: ${(props) => props.theme.token.colorTextLightSolid};
      font-size: 0.9rem;
      font-weight: 500;
    }
    .ant-table-cell-ellipsis {
      max-width: 100px;
    }
    @media screen and (max-width: 1079px) {
      .ant-table-thead tr .ant-table-cell {
        font-size: 0.75rem;
      }
    }
    .ant-table-thead tr .ant-table-cell::before {
      display: none;
    }

    .ant-table-thead tr .ant-table-cell .ant-table-column-has-sorters {
      border-top: ${(props) =>
        `1px solid ${props.theme.token.colorBorder} !important`};
    }
    .ant-table-body .ant-table-cell {
      border-bottom: 0px;
    }

    .ant-table-wrapper .ant-table-tbody > tr > td {
      padding: 0.5rem !important;
    }
    .ant-table-thead tr th {
      height: 2rem !important;
      padding: 0rem 0.5rem !important;
    }
    .collapseTable .ant-collapse-expand-icon span svg {
      fill: ${(props) => props.theme.token.colorTextLightSolid} !important;
    }
    .collapseTable .ant-collapse-header-text {
      color: ${(props) => props.theme.token.colorTextLightSolid};
    }

    .ant-switch-checked .ant-switch-inner {
      background-color: ${(props) =>
        props.theme.token.colorBgActionButton} !important;
    }

    .ant-table-thead {
      height: 3.18rem;
    }

    .ant-table-thead .ant-table-cell {
      &:hover {
        background: ${(props) =>
          props.theme.token.colorPrimaryBgHover} !important;
      }
    }
    .ant-table-body .ant-table-cell {
      padding: 0.5rem !important;
    }

    .ant-table-tbody .ant-table-row:hover {
      background: #f5f5f5;
    }
    .ant-table-body {
      scroll-behavior: smooth; /* Enables smooth scrolling */
      /* overflow-x: hidden !important; */
    }
  `,
  ReloadSpan: styled.span`
    margin-left: 2rem;
    margin-right: 1rem;
    color: ${(props) => props.theme?.token.colorPrimary};
  `,
  TableWrapper: styled.div`
    height: 270px !important;
    background-color: white !important;
    border-radius: 5px !important;
  `,
};

export const columnSpan = (name) => {
  switch (name) {
    case name === "allTrade":
      return "8";
    case name === "pendingTrade":
      return "9";
    case name === "rejectionLogs":
      return "10";
    case name === "m2mAlerts":
      return "5";
    default:
      return "10";
  }
};
