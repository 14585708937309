import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import APICall from "../../APICall";
import EndPoints from "../../APICall/EndPoints";
import { useQuery } from "@tanstack/react-query";
import { Col, notification } from "antd";
import ThemeDropDown from "../ThemeDropDown";
import {
  OptionMarket,
  ThemeDropDowns,
  UserFilterCompareObj,
  filterLevels,
  options,
} from "../../Helper/constant";
import {
  setFilterUsers,
  setPositionFromSummay,
  setUserClearFilter,
} from "../../store/slice/globleSlice";
import useGetQueryParams from "../../hooks/useGetQueryParams";
import { useGetChildUsers } from "../../APICall/apiHooks";

const setOptionsInitial = (data) => {
  const accountTypeOptions = data?.reduce(
    (acc, el) => {
      if (acc[el?.accountType]) {
        acc[el.accountType].push({
          label: `${el?.accountName} (${el?.userId})`,
          value: el?.userId,
          id: el?._id,
        });
      }
      return acc;
    },
    {
      masterAdmin: [],
      superAdmin: [],
      admin: [],
      master: [],
      dealer: [],
      broker: [],
      customer: [],
    }
  );

  return accountTypeOptions;
};

const UserFilterComponant = React.memo(
  ({ valueRef, userType, role, deducted, multipleValueRef }) => {
    // state
    const [userOptions, setUserOptions] = useState({
      masterAdmin: [],
      superAdmin: [],
      admin: [],
      master: [],
      dealer: [],
      broker: [],
      customer: [],
    });
    const [optionValue, setOptionValue] = useState({
      customer: null,
      broker: null,
      dealer: null,
      master: null,
      admin: null,
      superAdmin: null,
      masterAdmin: null,
    });
    const [selectedChild, setSelectedChild] = useState(null);
    const { params } = useGetQueryParams();
    const dispatch = useDispatch();

    // custom hook for child user api call
    const {
      isLoading: childLoading,
      childUsers: child,
      userDetails: selectedUser,
    } = useGetChildUsers({ id: selectedChild });

    // redux
    const authorityLevel = useSelector(
      (state) => state.authSlice.userDetail.authorityLevel
    );
    const clearUserList = useSelector(
      (state) => state.globleSlice?.userClearFilter
    );
    const summaryPosition = useSelector(
      (state) => state?.globleSlice?.positionFromSummay
    );
    const loginUserId = useSelector(
      (state) => state.authSlice?.userDetail?.userId
    );
    const filterUser = useSelector((state) => state?.globleSlice?.filterUsers);

    // api
    const getUsers = async () => {
      let url = role
        ? `${EndPoints.getAllUser}?role=${role}`
        : EndPoints.getAllUser;

      let res = await APICall("get", url);
      return res.data.data.userData;
    };

    const {
      isLoading: userLoading,
      data: userData,
      error: usersfilterError,
    } = useQuery({
      queryKey: ["usersFilter", role],
      queryFn: getUsers,
      enabled: true,
    });

    if (usersfilterError) {
      notification.error({ message: usersfilterError?.message });
    }

    // life cycle
    useEffect(() => {
      if (summaryPosition?.userId && userData) {
        let setUser = userData?.find(
          (el) => el.userId == summaryPosition?.userId
        );
        if (setUser) {
          setOptionValue((pre) => ({
            ...pre,
            [setUser?.accountType]: setUser?.userId,
          }));
          valueRef.current = {
            ...valueRef.current,
            userId: setUser?.userId,
          };
        }
        dispatch(setPositionFromSummay({}));
      }
    }, [summaryPosition, userData]);

    useEffect(() => {
      if (params.userId && userData) {
        let setUser = userData?.find((el) => el.userId == params?.userId);
        if (setUser) {
          setOptionValue((pre) => ({
            ...pre,
            [setUser?.accountType]: setUser?.userId,
          }));
          valueRef.current = {
            ...valueRef.current,
            userId: setUser?.userId,
          };
        }
      }
      const filterUsersId = userData?.map((el) => el?.userId);
      if (filterUsersId?.length > 0) {
        dispatch(setFilterUsers([...filterUsersId, loginUserId]));
      } else {
        if (filterUser?.length === 0) {
          dispatch(setFilterUsers([loginUserId]));
        }
      }

      if (userData) {
        setUserOptions(setOptionsInitial(userData));
      }
    }, [userData]);

    useEffect(() => {
      if (clearUserList) {
        setOptionValue({
          customer: null,
          broker: null,
          dealer: null,
          master: null,
          admin: null,
          superAdmin: null,
          masterAdmin: null,
        });
        valueRef.current = {
          ...valueRef.current,
          userId: null,
        };
        if (multipleValueRef) {
          multipleValueRef.current = {};
        }
        setSelectedChild(null);
        setUserOptions(setOptionsInitial(userData));
        dispatch(setUserClearFilter(false));
      }
    }, [clearUserList]);

    useEffect(() => {
      if (selectedUser) {
        valueRef.current = { ...valueRef.current, userId: selectedChild };
        const obj = child?.reduce((acc, child) => {
          const childInfo = {
            label: `${child.accountName} (${child.userId})`,
            value: child.userId,
            id: child._id,
          };
          acc[child.accountType] = [
            ...(acc[child.accountType] || []),
            childInfo,
          ];
          return acc;
        }, {});

        let newOption = { ...userOptions };
        const findAccount = options?.user?.accountType?.find(
          (el) => el?.value === selectedUser.accountType
        );
        let authorityLevel = findAccount?.authorityLevel;
        if (authorityLevel === 6) {
          authorityLevel = 7;
        }
        if (authorityLevel === 7) {
          authorityLevel = 6;
        }

        for (let key in newOption) {
          if (authorityLevel < filterLevels[key]) {
            newOption[key] = obj[key] || [];
          }
        }

        setUserOptions(newOption);
        let newValue = { ...optionValue };

        for (let key in newValue) {
          if (key === selectedUser.accountType) {
            newValue[key] = selectedChild;
            break;
          }
          newValue[key] = null;
        }
        setOptionValue({ ...newValue });
        if (multipleValueRef) {
          multipleValueRef.current = newValue;
        }
      }
    }, [selectedUser]);

    // function
    const filteredDropDowns = useMemo(() => {
      return ThemeDropDowns.filter(
        (item) => item.authorityLevel > authorityLevel
      );
    }, [authorityLevel]);

    const onChangeDropDown = (name, selectedValue, selectedOptions) => {
      if (selectedValue === null) {
        setOptionValue((pre) => {
          let obj = {
            customer: null,
            broker: null,
            dealer: null,
            master: null,
            admin: null,
            superAdmin: null,
            masterAdmin: null,
          };

          for (let key in pre) {
            if (UserFilterCompareObj[name] === key) {
              obj[key] = null;
            } else {
              obj[key] = pre[key];
            }
          }
          let flage = true;
          for (let key in obj) {
            if (obj[key]) {
              valueRef.current = { ...valueRef.current, userId: obj[key] };
              // getChildTrades(obj[key]);
              setSelectedChild(obj[key]);
              flage = false;
              break;
            }
          }
          if (flage) {
            valueRef.current = { ...valueRef.current, userId: loginUserId };
            // getChildTrades(loginUserId);
            setSelectedChild(loginUserId);
          }
          if (multipleValueRef) {
            multipleValueRef.current = obj;
          }
          return obj;
        });
      }

      if (name === "Customer") {
        valueRef.current = { ...valueRef.current, userId: selectedValue?.[0] };
        setSelectedChild(null);
        setOptionValue((pre) => ({
          customer: selectedValue?.[0],
          broker: pre?.broker,
          dealer: pre?.dealer,
          master: pre?.master,
          admin: pre?.admin,
          superAdmin: pre?.superAdmin,
          masterAdmin: pre?.masterAdmin,
        }));
      }
      if (selectedValue?.[0] && name !== "Customer") {
        setSelectedChild(selectedValue?.[0]);
      }
    };

    return (
      <>
        {!userType &&
          filteredDropDowns.map((dropDown, index) => {
            if (dropDown?.title !== deducted) {
              return (
                <Col xs={24} sm={12} md={8} lg={6} xl={4} key={index}>
                  <ThemeDropDown
                    title={dropDown.title}
                    placeholder={dropDown.placeholder}
                    name={dropDown.title}
                    options={userOptions[OptionMarket[dropDown?.title]]}
                    width={dropDown.width}
                    showSearch={true}
                    $onChange={onChangeDropDown}
                    $value={optionValue[OptionMarket[dropDown?.title]]}
                    disabled={childLoading}
                    loadings={childLoading || userLoading ? "true" : "false"}
                    // suffixIcon={
                    //   <Spin size="small" spinning={childLoading || userLoading} />
                    // }
                  />
                </Col>
              );
            }
          })}

        {userType &&
          ThemeDropDowns?.filter((el) => userType?.includes(el?.title))?.map(
            (dropDown, index) => (
              <Col xs={24} sm={12} md={8} lg={6} xl={4} key={index}>
                <ThemeDropDown
                  title={dropDown.title}
                  placeholder={dropDown.placeholder}
                  name={dropDown.title}
                  options={userOptions[OptionMarket[dropDown?.title]]}
                  width={dropDown.width}
                  showSearch={true}
                  $onChange={onChangeDropDown}
                  $value={optionValue[OptionMarket[dropDown?.title]]}
                  disabled={childLoading}
                  loadings={childLoading || userLoading ? "true" : "false"}
                  // suffixIcon={
                  //   <Spin size="small" spinning={childLoading || userLoading} />
                  // }
                />
              </Col>
            )
          )}
      </>
    );
  }

  // (prevProps, nextProps) => {
  //   return (
  //     prevProps.role === nextProps.role &&
  //     prevProps.userType === nextProps.userType &&
  //     false
  //   );
  // }
);

export default UserFilterComponant;
