import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import APICall from ".";
import EndPoints from "./EndPoints";
import { notification } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { SEGMENTS } from "../Helper/constant";

export const useGetChildUsers = ({ id, type }) => {
  const getChildUser = async () => {
    let url = `${EndPoints.getChildUsers}${id}`;
    if (type) {
      url += `&type=${type}`;
    }
    let response = await APICall("get", url);
    return response?.data?.data;
  };
  const { data, isLoading, isFetching } = useQuery({
    queryKey: ["childUser", id],
    queryFn: getChildUser,
    onError: (error) => {
      notification.error({ message: error?.message });
    },
    refetchOnMount: true,
    keepPreviousData: false,
    enabled: !!id,
  });

  return {
    userDetails: data?.userDetail,
    childUsers: data?.childUsers,
    isLoading: isLoading || isFetching,
  };
};

export const useDeleteTrade = ({ key, type, handleCancel, setSelectedRow }) => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationKey: ["deleteUserTrade"],
    mutationFn: async (payload) => {
      let url = `${EndPoints.deleteTrade}`;
      if (!type) url += `?id=${payload?.id}`;
      let respnose = await APICall("delete", url, payload);
      return respnose;
    },
    onError: (error) => {
      notification.error({ message: error?.message });
    },
    onSuccess: (respnose) => {
      if (respnose?.status === 200) {
        notification.success({ message: respnose?.data?.message });
        queryClient.refetchQueries({ queryKey: key });
        handleCancel();
        if (type) setSelectedRow([]);
      } else notification.error({ message: respnose?.data?.message });
    },
  });

  return {
    deleteTradeLoading: mutation.status === "pending",
    deleteTradeData: mutation.data,
    deleteTradeMutate: mutation.mutate,
  };
};

export const useGetUplineUsers = ({ id }) => {
  const getUplineUser = async () => {
    let response = await APICall("get", `${EndPoints.getUpline}${id}`);
    return response?.data?.data;
  };

  const { isLoading, data } = useQuery({
    queryKey: ["uplineUsers", id],
    queryFn: getUplineUser,
    onError: (error) => {
      notification.error({ message: error?.message });
    },
    enabled: !!id,
  });

  return {
    upLineLoading: isLoading,
    upLineData: data,
  };
};

export const useGetFilteredUsers = ({ type, editable }) => {
  // state
  const [filteredData, setFilteredData] = useState([]);

  // api call function
  const getFilteredUser = async () => {
    let respnose = await APICall("get", `${EndPoints.getUserByFilter}${type}`);
    return respnose?.data?.data?.finalResult;
  };
  const { isLoading, isFetching, data } = useQuery({
    queryKey: ["filteredUsers", type],
    queryFn: getFilteredUser,
    enabled: editable,
    onError: (error) => {
      notification.error({ message: error?.message });
    },
    refetchOnMount: true,
  });

  // life cycle
  useEffect(() => {
    // set filtered data
    if (data) {
      setFilteredData(() => {
        return data?.map((el) => {
          return {
            label: `${el?.userId} (${el?.accountName})`,
            value: el?.userId,
          };
        });
      });
    }
  }, [data]);

  return {
    filterDataLoading: isLoading || isFetching,
    filteredData,
  };
};

export const useGetScript = ({ marketName }) => {
  //state
  const [scriptList, setScriptList] = useState([]);

  // api call for get script
  const getScript = async () => {
    let respnose = await APICall(
      "get",
      `${EndPoints.getScriptList}${marketName}`
    );
    return respnose?.data?.data;
  };

  const {
    isLoading,
    data: scriptData,
    error,
  } = useQuery({
    queryKey: ["scriptList", marketName],
    queryFn: getScript,
    enabled: marketName ? true : false,
    onError: (error) => {
      notification.error({ message: error?.message });
    },
  });

  // life cycle
  useEffect(() => {
    // get customized data from script data
    if (scriptData) {
      setScriptList(() => {
        return scriptData?.map((el, index) => ({
          value: el?.symbol,
          label: el?.symbol,
          key: index,
        }));
      });
    }
  }, [scriptData]);

  if (error) notification.error({ message: error?.message });

  return {
    scriptLoading: isLoading,
    scriptList: scriptList,
  };
};

export const useEditUser = ({ item, params }) => {
  const queryClient = useQueryClient();
  const { isLoading: editUserLoading, mutate: editUserMutate } = useMutation({
    mutationKey: ["editUser"],
    mutationFn: async (data) => {
      let response = await APICall(
        "post",
        `${EndPoints.editUsers}${item?.userId}`,
        data
      );
      return response;
    },
    onSuccess: (response) => {
      if (response?.status === 200) {
        queryClient.refetchQueries({ queryKey: ["usersDetails", params] });
        notification.success({
          message: `Only Position Squareoff of ${item?.userId}(${item?.accountName}) Updated Successfully`,
        });
      } else {
        notification.error({ message: response?.data?.message });
      }
    },
    onError: (error) => {
      notification.error({ message: error?.message });
    },
  });

  return { editUserLoading, editUserMutate };
};

export const useCalculateLedger = ({ id }) => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationKey: ["calculateLedger"],
    mutationFn: async (type) => {
      let url = `${EndPoints.recalculateLedger}?clientId=${id}`;
      if (type === "all") url += `&type=${type}`;
      const response = await APICall("get", url);
      return response;
    },
    onError: (error) => {
      notification.error({ message: error?.message });
    },
    onSuccess: (data) => {
      if (data?.status === 200) {
        queryClient.refetchQueries({ queryKey: ["userLedger"] });
        notification.success({ message: data?.data?.message });
      } else {
        notification.error({ message: data?.data?.message });
      }
    },
  });

  return {
    ledgerCalculateLoading: mutation?.status === "pending",
    ledgerMutate: mutation?.mutate,
  };
};

export const useCalculateMargin = ({ id, query }) => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationKey: ["calculateMargin", id],
    mutationFn: async (marketName) => {
      let url = `${EndPoints.calculateMargin}`;
      if (id) url += `?userId=${id}`;
      if (marketName) url += `&marketName=${marketName}`;
      const response = await APICall("get", url);
      return response;
    },
    onError: (error) => {
      notification.error({ message: error?.message });
    },
    onSuccess: (data) => {
      if (data?.status === 200) {
        notification.success({ message: data?.data?.message });
        if (query) queryClient.refetchQueries({ queryKey: query });
      } else {
        notification.error({ message: data?.message });
      }
    },
  });

  return {
    marginLoading: mutation.status === "pending",
    marginMutate: mutation.mutate,
    updatedMargin: mutation.data,
  };
};

export const useCheckPassward = (update) => {
  // state
  const [flage, setFlage] = useState(false);

  // api call
  const {
    status,
    mutate: passwardMutate,
    data: passwardData,
  } = useMutation({
    mutationKey: ["checkPassward"],
    mutationFn: async (pass) => {
      let response = await APICall("post", EndPoints.passwordVerification, {
        password: pass,
      });
      return response;
    },
    onSuccess: (data) => {
      if (data.status === 200) {
        setFlage(true);
        if (update) update();
      } else {
        notification.error({ message: data?.data?.message });
        setFlage(false);
      }
    },
    onError: (error) => {
      notification.error({ message: error?.message });
    },
  });

  return {
    flage,
    passwardLoding: status === "pending",
    passwardMutate,
    setFlage,
    passwardData,
  };
};

export const useRevertAutoSquare = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationKey: ["revertAutoSquare"],
    mutationFn: async (id) => {
      const response = await APICall(
        "delete",
        `${EndPoints.restoreAutoSquareOff}${id}`
      );
      return response;
    },
    onSuccess: (data) => {
      if (data?.status === 200) {
        notification.success({ message: data?.data?.message });
        queryClient.refetchQueries({ queryKey: ["autoSqure"] });
      } else {
        notification.error({ message: data?.data?.message });
      }
    },
    onError: (error) => {
      notification.error({ message: error?.message });
    },
  });

  return {
    revertLoading: mutation.status === "pending",
    revertData: mutation.data,
    revertMutate: mutation.mutate,
  };
};

export const useEditUserDetails = (id) => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationKey: ["editUserDetails", id],
    mutationFn: async (data) => {
      const response = await APICall(
        "post",
        `${EndPoints.editUsers}${id}`,
        data
      );
      return response;
    },
    onSuccess: (data) => {
      if (data?.status === 200) {
        notification.success({ message: data?.data?.message });
        queryClient.refetchQueries({ queryKey: ["allBlockedScript", id] });
      } else {
        notification.error({ message: data?.data?.message });
      }
    },
    onError: (error) => {
      notification.error({ message: error?.message });
    },
  });

  return {
    editUserLoading: mutation.status === "pending",
    editUserData: mutation.data,
    editUserMutate: mutation.mutate,
  };
};

export const useUpdateCSV = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationKey: ["updateCSVFile"],
    mutationFn: async () => {
      const response = await APICall("get", `${EndPoints.updateCSV}`);
      return response;
    },
    onSuccess: (data) => {
      if (data?.status === 200) {
        notification.success({ message: data?.data?.message });
        queryClient.refetchQueries({ queryKey: ["blockScript"] });
      } else {
        notification.error({ message: data?.data?.message });
      }
    },
    onError: (error) => {
      notification.error({ message: error?.message });
    },
  });

  return {
    updateCSVLoading: mutation.status === "pending",
    updateCSVData: mutation.data,
    updateCSVMutate: mutation.mutate,
  };
};

export const useBlockScriptList = (userId) => {
  const filterUser = useSelector((state) => state?.globleSlice?.filterUsers);
  const query = useQuery({
    queryKey: ["allBlockedScript", userId],
    queryFn: async () => {
      // let isAllow = filterUser?.includes(Number(userId));
      // if (!isAllow) {
      //   notification.error({ message: "User Id is not valid" });
      //   return [];
      // }
      let respnose = await APICall(
        "get",
        `${EndPoints.banScriptList}?userId=${Number(userId)}`
      );
      return respnose?.data?.data;
    },
    enabled: userId ? true : false,
    refetchOnMount: true,
    // onSuccess: (respnose) => {
    //   if (respnose?.status === 200) {
    //   } else {
    //     notification.error({ message: respnose?.data?.message });
    //   }
    // },
    // onError: (error) => {
    //   notification.error({ message: error?.message });
    // },
  });

  if (query.error) notification.error({ message: query.error?.message });

  return {
    allBlockScriptLoading: query?.isLoading || query?.isFetching,
    allBlockScriptData: query?.data,
    // allBlockScriptMutation: mutation?.mutate,
  };
};

export const useGetLedger = ({ userId, value }) => {
  const query = useQuery({
    queryKey: ["userLedger"],
    queryFn: async () => {
      let url = `${EndPoints.getLedger}?clientId=${userId}`;
      for (let key in value) {
        if (value[key]) {
          if (key === "entryAfter") {
            url += `&${key}=${value[key]?.$d?.toISOString()}`;
          } else {
            url += `&${key}=${value[key]}`;
          }
        }
      }
      let response = await APICall("get", url);
      return response?.data?.data;
    },
    refetchOnMount: true,
  });

  if (query?.error) notification.error({ message: query?.error?.message });

  return {
    ledgerLoading: query?.isLoading || query?.isFetching,
    ledgerData: query?.data,
    refetchLeger: query?.refetch,
  };
};

export const useGetMarketStatus = () => {
  const query = useQuery({
    queryKey: ["marketStatus"],
    queryFn: async () => {
      const response = await APICall(
        "get",
        `${EndPoints.getMarketStatus}get-market-status`
      );
      return response?.data?.data;
    },
    // refetchOnMount: true,
  });

  useEffect(() => {
    if (query.error) notification.error({ message: query.error?.message });
  }, [query.error]);

  return useMemo(
    () => ({
      marketStatusLoading: query?.isLoading,
      marketStatusData: query?.data,
    }),
    [query.isLoading, query.data]
  );
};

export const useGetBarScript = ({
  tab,
  activeTab,
  page,
  search,
  sortColumn,
  sortType,
}) => {
  const query = useQuery({
    queryKey: ["barScript", tab, search, sortColumn, sortType, page],
    queryFn: async () => {
      let url = `${EndPoints.getTableScript}${
        SEGMENTS[tab]
      }&page=${page}&limit=${10}`;
      if (search) {
        url += `&search=${search}`;
      }
      if (sortColumn) {
        url += `&sortColumn=${sortColumn}`;
      }
      if (sortType) {
        url += `&sortType=${sortType}`;
      }
      let response = await APICall("get", url);
      return response?.data?.data;
    },
    enabled: activeTab ? true : false,
  });

  if (query?.error) notification.error({ message: query?.error?.message });

  return {
    barScriptLoading: query?.isLoading,
    barScriptData: query?.data,
  };
};

export const useEditLogs = ({ params, loginId }) => {
  const getUserEditLog = async () => {
    let response = await APICall(
      "get",
      `${EndPoints.getUserEditLog}?userId=${params?.userId || loginId}&page=${
        params?.page || 1
      }&&limit=${params?.limit || 10}&type=ledgers`
    );
    return response?.data?.data;
  };

  const query = useQuery({
    queryKey: ["userEditLog", params],
    queryFn: getUserEditLog,
    enabled: Object.keys(params)?.length > 0,
  });

  if (query.error) {
    notification.error({ message: query.error?.message });
  }

  return {
    logsData: query.data,
    logsLoading: query.isLoading,
  };
};

export const useGetChat = () => {
  const query = useMutation({
    mutationKey: ["getChat"],
    mutationFn: async (id) => {
      const response = await APICall("post", `${EndPoints.getChatList}`, {
        id: id,
      });
      return response;
    },
    onSuccess: (data) => {
      // console.log("data", data?.data?.data);
    },
    onError: (error) => {
      notification.error({ message: error?.message });
    },
  });

  return {
    chatLoading: query.status === "pending",
    chatData: query.data,
    chatMutate: query.mutate,
  };
};

export const useGetBrokerageAllData = () => {
  const mutation = useMutation({
    mutationKey: ["getBrokerageAllData"],
    mutationFn: async (data) => {
      let payload = { ...data };
      delete payload.type;
      const response = await APICall(
        "post",
        `${EndPoints.getBrokerage}`,
        payload
      );
      return { ...response, type: data?.type };
    },
    onSuccess: (data) => {
      // console.log("data", data?.data?.data);
    },
    onError: (error) => {
      notification.error({ message: error?.message });
    },
  });

  return {
    brokerageLoading: mutation.status === "pending",
    brokerageData: mutation.data,
    brokerageMutate: mutation.mutate,
  };
};

export const useGetCSV = () => {
  const query = useQuery({
    queryKey: ["bhavCopy"],
    queryFn: async () => {
      const response = await APICall("get", `${EndPoints.getCSV}`);
      return response?.data?.data;
    },
    refetchOnMount: true,
  });

  if (query?.error) notification.error({ message: query?.error?.message });

  return {
    csvLoading: query.isLoading,
    csvData: query.data,
  };
};

export const useGetFile = () => {
  const mutation = useMutation({
    mutationKey: ["getFile"],
    mutationFn: async (data) => {
      const response = await APICall("get", `${EndPoints.getFiles}${data}`);
      return response;
    },
    onSuccess: (data) => {
      // console.log("data", data?.data?.data);
    },
    onError: (error) => {
      notification.error({ message: error?.message });
    },
  });

  return {
    fileLoading: mutation.status === "pending",
    fileData: mutation.data,
    fileMutate: mutation.mutate,
  };
};

export const useBhavCopy = () => {
  const mutation = useMutation({
    mutationKey: ["bhavCopy"],
    mutationFn: async (payload) => {
      const respnose = await APICall("post", EndPoints.bhavCopy, payload);
      return respnose;
    },
    onSuccess: (response) => {
      if (response.status === 200) {
        notification.success({ message: response?.data?.message });
      } else {
        notification.error({ message: response?.data?.message });
      }
    },
    onError: (error) => {
      notification.error({ message: error?.message });
    },
  });

  return {
    bhavCopyLoading: mutation.isPending,
    bhavCopyData: mutation.data,
    bhavCopyMutate: mutation.mutate,
  };
};
