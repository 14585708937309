import React, { useMemo } from "react";
import upAero from "../../assets/icons/upAero.svg";
import downAero from "../../assets/icons/downAero.svg";
import { useSelector } from "react-redux";
import {
  StyleSpan,
  StyleDiv,
  MainComponant,
  LiveDataDiv,
  ScriptDiv,
  ThemeSpan,
  WrapDiv,
  ImageDiv,
  StyleDivider,
} from "./style";

const NiftySenView = () => {
  return (
    <MainComponant>
      <ScriptDiv>
        <StyleSpan>
          <WrapDiv>
            <StyleDiv>{`Nifty 50`}</StyleDiv>
            <NityData symbol={"256265"} />
          </WrapDiv>
          <WrapDiv>
            <NiftyChange symbol={"256265"} />
            <ChangePercentage symbol={"256265"} />
          </WrapDiv>
        </StyleSpan>
      </ScriptDiv>
      <StyleDivider type="vertical" />
      <ScriptDiv>
        <StyleSpan>
          <WrapDiv>
            <StyleDiv>{`Sensex`}</StyleDiv>
            <NityData symbol={"265"} />
          </WrapDiv>
          <WrapDiv>
            <NiftyChange symbol={"265"} />
            <ChangePercentage symbol={"265"} />
          </WrapDiv>
        </StyleSpan>
      </ScriptDiv>
      <StyleDivider type="vertical" />
      <ScriptDiv>
        <StyleSpan>
          <WrapDiv>
            <StyleDiv>{`Gift Nifty`}</StyleDiv>
            <NityData symbol={"291849"} />
          </WrapDiv>
          <WrapDiv>
            <NiftyChange symbol={"291849"} />
            <ChangePercentage symbol={"291849"} />
          </WrapDiv>
        </StyleSpan>
      </ScriptDiv>
      <StyleDivider type="vertical" />
      <ScriptDiv>
        <StyleSpan>
          <WrapDiv>
            <StyleDiv>{`Bank Nifty`}</StyleDiv>
            <NityData symbol={"260105"} />
          </WrapDiv>
          <WrapDiv>
            <NiftyChange symbol={"260105"} />
            <ChangePercentage symbol={"260105"} />
          </WrapDiv>
        </StyleSpan>
      </ScriptDiv>
      {/* <Divider
        type="vertical"
        style={{ borderColor: "white", height: "inherit" }}
      /> */}

      <div>
        {/* <StyleSpan>
          <StyleDiv>{`Gift Nifty`}</StyleDiv> <NityData symbol={"291849"} />
          <NiftyChange symbol={"291849"} />
          <ChangePercentage symbol={"291849"} />
        </StyleSpan>
        <StyleSpan>
          <StyleDiv>{`Bank Nifty`}</StyleDiv> <NityData symbol={"260105"} />
          <NiftyChange symbol={"260105"} />
          <ChangePercentage symbol={"260105"} />
        </StyleSpan> */}
      </div>
    </MainComponant>
  );
};

export default React.memo(NiftySenView);

const NityData = ({ symbol }) => {
  //redux
  const nifty =
    useSelector(
      (state) => state?.webSocketSlice?.webSocket?.data?.[symbol]?.["LTP"]
    ) || 0;

  const niftyPrice = useMemo(() => {
    return <LiveDataDiv>: {parseFloat(nifty)?.toFixed(2)}</LiveDataDiv>;
  }, [nifty]);
  return <div style={{ width: "4rem" }}>{niftyPrice}</div>;
};

const NiftyChange = ({ symbol }) => {
  //redux
  const Change =
    useSelector(
      (state) =>
        state?.webSocketSlice?.webSocket?.data?.[symbol]?.["Net_Change"]
    ) || 0;

  const finalChange = useMemo(() => {
    return (
      <>
        <ImageDiv>
          {Change >= 0 ? (
            <img src={upAero} alt="Product" width="12" />
          ) : (
            <img src={downAero} alt="Product" width="12" />
          )}
        </ImageDiv>
        <ThemeSpan>{parseFloat(Change)?.toFixed(2)}</ThemeSpan>
      </>
    );
  }, [Change]);

  return <>{finalChange}</>;
};

const ChangePercentage = ({ symbol }) => {
  //redux
  let Price_Change =
    useSelector(
      (state) =>
        state?.webSocketSlice?.webSocket?.data?.[symbol]?.["Price_Change"]
    ) || 0;

  return (
    <span style={{ alignItems: "center" }}>{`(${Price_Change.toFixed(
      2
    )}%)`}</span>
  );
};
