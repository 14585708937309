import dayjs from "dayjs";
import store from "../../store/store";
import { sequence } from "../../Helper/constant";
import { useCallback } from "react";
import { updateScriptSlice } from "../../store/slice/scriptSettingSlice";
import { useDispatch } from "react-redux";

export const useSetUserDetails = () => {
  const dispatch = useDispatch();
  const setUserDetails = useCallback((userInfo, m2m, defaultSetting, form) => {
    const {
      MarketAccessDetails: parentDetails,
      userDetail: { accountType },
    } = store.getState().authSlice;
    const PreviousData = store.getState().userAccountSlice;
    const isOwner = accountType === "owner";
    const isRent = parentDetails?.accountDetails?.partnershipWith === "rent";
    let additionalSharingObj = sequence?.reduce(
      (acc, curr) => ({ ...acc, [curr]: [] }),
      {}
    );

    if (userInfo?.additionalSharing) {
      userInfo?.additionalSharing?.forEach((sharing) => {
        const { marketAccessId, brokerId } = sharing;
        additionalSharingObj[marketAccessId.scriptName].push({
          ...sharing,
          brokerId: Number(brokerId.userId),
        });
      });

      Object.values(additionalSharingObj)?.forEach((scripts) =>
        scripts.sort((a, b) => a.brokerId - b.brokerId)
      );
    }

    const brokerageObj = userInfo?.sharingBrokerageSchema?.reduce(
      (acc, schema) => {
        const { marketAccessId } = schema;
        acc[marketAccessId.scriptName] = schema;
        return acc;
      },
      {}
    );

    const scriptWiseObj = userInfo?.scriptWiseSettingInfo?.reduce(
      (acc, setting) => {
        const { marketAccessId } = setting;
        acc[marketAccessId.scriptName] = setting;
        return acc;
      },
      {}
    );

    const marginObj = userInfo?.margin?.reduce((acc, marginData) => {
      const { marketAccessId } = marginData;
      acc[marketAccessId.scriptName] = marginData;
      return acc;
    }, {});

    let onlyPositionObj = {};
    userInfo.marketAccess?.forEach((el) => {
      if (!onlyPositionObj[el?.scriptName]) {
        onlyPositionObj[el?.scriptName] = {};
      }
      onlyPositionObj[el?.scriptName] = {
        isOnlyPositionSquareup: el?.isOnlyPositionSquareup,
      };
    });

    const marketAccess = userInfo?.marketAccess?.map(
      ({ scriptName }) => scriptName
    );

    const allowAccount = userInfo?.accountDetails?.totalAccounts?.[0];
    const importUser = PreviousData?.basicInfo;

    const marketArray = store
      .getState()
      ?.globleSlice?.marketListData?.map(({ scriptName }) => scriptName);

    const obj = {
      accountType: userInfo?.user?.accountType,
      basicInfo: {
        accountName: form.getFieldValue("accountName") || "",
        balance: m2m
          ? userInfo?.user?.balance
          : form.getFieldValue("balance") || "",
        accountPassword: 1234,
        linkLedger: importUser?.linkLedger || false,
        importUserSettings: defaultSetting
          ? ""
          : importUser?.importUserSettings,
        defaultUser: importUser?.defaultUser || "",
      },
      accountDetails: {
        allowNoOfAccountSuperAdmin: allowAccount?.SuperAdmin,
        allowNoOfAccountAdmin: allowAccount?.Admin,
        allowNoOfAccountMaster: allowAccount?.Master,
        allowNoOfAccountBroker: allowAccount?.Broker,
        allowNoOfAccountDealer: allowAccount?.Dealer,
        allowNoOfAccountCustomer: allowAccount?.Customer,
        partnershipWith: isOwner
          ? userInfo.accountDetails?.partnershipWith
          : isRent
          ? "percentage"
          : parentDetails?.accountDetails?.partnershipWith,
        minimumBrokerageRequire: isOwner
          ? userInfo.accountDetails?.minimumBrokerageRequire
          : parentDetails?.accountDetails?.minimumBrokerageRequire === true
          ? true
          : userInfo.accountDetails?.minimumBrokerageRequire,
        additionalInfo: isOwner
          ? userInfo?.additionalInfoSchema?.[0]
          : parentDetails?.additional || {},
        orderBetweenHighLow: userInfo.accountDetails?.orderBetweenHighLow,
        showDetailBill: userInfo.accountDetails?.showDetailBill,
        allowBannedScript: userInfo.accountDetails?.allowBannedScript,
        isOnlyPositionSquareup: userInfo.accountDetails?.isOnlyPositionSquareup,
        isAutoStopNewPosition: userInfo.accountDetails?.isAutoStopNewPosition,
        autoStopNewPosition: {
          profit: userInfo.accountDetails?.applyAutoSquareupProfit,
          loss: userInfo.accountDetails?.autoStopNewPositionLoss,
        },
        isApplyAutoSquareup: userInfo.accountDetails?.applyAutoSquareup,
        applyAutoSquareup: {
          profit: userInfo.accountDetails?.applyAutoSquareupProfit,
          loss: userInfo.accountDetails?.applyAutoSquareupLoss,
        },
        isM2MAlert: userInfo.accountDetails?.isM2MAlert,
        M2MAlert: {
          profit: userInfo.accountDetails?.m2mAlertProfit,
          loss: userInfo.accountDetails?.m2mAlertLoss,
        },
        isNewPositionSquareupTime:
          userInfo.accountDetails?.isNewPositionSqaureupTime,
        newPositionSquareupTime: userInfo.accountDetails
          ?.newPositionSqaureupTime
          ? dayjs(userInfo.accountDetails?.newPositionSqaureupTime) || null
          : null || null,
        isFreshLimitAllow: userInfo.accountDetails?.isFreshLimitAllow,
        freshLimitAllow: userInfo.accountDetails?.freshLimitAllow,
        remark: userInfo.accountDetails?.remark,
      },
      market_access: marketAccess?.filter((el) => marketArray?.includes(el)),
    };
    let scriptObj = {};
    marketArray.forEach((scriptName) => {
      const name =
        scriptName === "NSE EQU" || scriptName === "CRYPTO"
          ? "totalNoOfQuantity"
          : "totalNoOfLot";

      let isOnlyPositionSquareup =
        onlyPositionObj?.[scriptName]?.isOnlyPositionSquareup || false;
      const brokerageDetails = {
        deliveryTotal: brokerageObj[scriptName]?.deliveryTotal || null,
        deliveryBuy: brokerageObj[scriptName]?.deliveryBuy || null,
        intradayBuy: brokerageObj[scriptName]?.intradayBuy || null,
        intradayTotal: brokerageObj[scriptName]?.intradayTotal || null,
        deliverySell: brokerageObj[scriptName]?.deliverySell || null,
        intradaySell: brokerageObj[scriptName]?.intradaySell || null,
        percentage: brokerageObj[scriptName]?.percentage || null,
        brokerageType:
          isOwner || isRent
            ? brokerageObj?.[scriptName]?.brokerageType
            : parentDetails?.brokarageDetails?.[scriptName]?.brokerageType,
        remainingPercentage: brokerageObj[scriptName]?.remainingPercentage || 0,
      };
      const additionalSharing = additionalSharingObj[scriptName] || [];
      const margin = {
        type: marginObj[scriptName]?.type || "value",
        delivery: marginObj[scriptName]?.history?.[0]?.delivery || null,
        intraday: marginObj[scriptName]?.history?.[0]?.intraday || 0,
        total: marginObj[scriptName]?.history?.[0]?.total || 0,
        interestRate: marginObj[scriptName]?.interestRate || 0,
      };
      const scriptWiseSettingInfo = {
        minimumScriptRate: scriptWiseObj[scriptName]?.minimumScriptRate || null,
        totalNoOfScripts: scriptWiseObj[scriptName]?.totalNoOfScripts || null,
        orderLimits: scriptWiseObj[scriptName]?.orderLimits || null,
        strikeRateMaxLot: scriptWiseObj[scriptName]?.strikeRateMaxLot || null,
        allowShortSell: scriptWiseObj[scriptName]?.allowShortSell || false,
        allowLimitOrder: scriptWiseObj[scriptName]?.allowLimitOrder || false,
        allowQuantityTrading:
          scriptWiseObj[scriptName]?.allowQuantityTrading || false,
        [name]: scriptWiseObj[scriptName]?.[name] || null,
        circuitFilter: scriptWiseObj[scriptName]?.circuitFilter || 0,
        highLowPermission:
          scriptWiseObj[scriptName]?.highLowPermission || false,
      };
      const allScript = isOwner
        ? userInfo.scriptWiseSetting?.allScript?.[scriptName] || []
        : PreviousData?.[scriptName]?.allScript || [];
      const blockScript = isOwner
        ? userInfo.scriptWiseSetting?.blockScript?.[scriptName] || []
        : PreviousData?.[scriptName]?.blockScript || [];
      // const formatedScript = allScript?.reduce((acc, curr) => {
      //   if (curr?.scriptName) {
      //     acc[curr?.scriptName] = curr;
      //   }
      //   return acc;
      // }, {});
      // scriptObj[scriptName] = formatedScript;

      obj[scriptName] = {
        isOnlyPositionSquareup,
        brokerageDetails,
        additionalSharing,
        margin,
        scriptWiseSettingInfo,
        allScript,
        blockScript,
      };
    });

    // dispatch(updateScriptSlice(scriptObj));

    return obj;
  }, []);
  return setUserDetails;
};
