import { styled } from "styled-components";
import { Avatar, Card, Tabs } from "antd";
import { UserOutlined } from "@ant-design/icons";
const { TabPane } = Tabs;

export const CustomCard = styled(Card)`
  &&& .ant-card-body {
    padding: 16px;
  }
  cursor: pointer;
  border: ${(props) =>
    props?.isactive ? `1px solid ${props?.theme?.token?.colorPrimary1}` : ""};
`;
export const DateHeader = styled.div`
  text-align: center;
  margin: 16px 0;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #e0e0e0;
    z-index: 1;
  }

  span {
    background-color: #f5f5f5;
    padding: 0 10px;
    font-size: 12px;
    color: #666;
    position: relative;
    z-index: 2;
    border-radius: 4px;
  }
`;
export const CustomTabPane = styled(TabPane)`
  && {
    .ant-tabs-tab {
      background-color: #1677ff;
      color: #ffffff;
      border-radius: 8px;
      margin-right: 8px;
    }

    .ant-tabs-tab-active {
      background-color: transparent;
      color: #1677ff;
    }
  }
`;
export const ClientListWrapper = styled.div`
  max-height: 60vh;
  overflow-y: auto;
`;
export const ClientSectionWrapper = styled.div`
  pad: 1rem;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 1.3rem;
`;
export const ClientCartDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const ClientTypeDiv = styled.div`
  font-size: ${(props) => (props?.activeTab === props.type ? "18px" : "16px")};
  color: ${(props) =>
    props?.activeTab === props.type ? "#1677ff" : "#798478"};
  border-bottom: ${(props) =>
    props?.activeTab === props.type ? "2px solid #1677ff" : "none"};
  padding-bottom: ${(props) => (props?.activeTab === props.type ? "2px" : "0")};
`;
export const ChatboxHeaderDiv = styled.div`
  padding: 16px;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  align-items: center;
`;
export const ChatAreaDiv = styled.div`
  /* flex: 1; */
  padding: 16px;
  /* overflow: auto; */
  display: flex;
  flex-direction: column;
  height: 60vh !important;
  overflow: auto;
  /* border: 5px solid red; */
`;
export const SelectUserDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  color: #999;
`;
export const UserIconForEmptyChat = styled(UserOutlined)`
  font-size: xx-large;
  margin-bottom: 1rem;
`;
export const MessageInputDiv = styled.div`
  padding: 1rem;
  border-top: 1px solid #e8e8e8;
  display: flex;
  align-items: center;
`;
export const ChatWrapperDiv = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #fff;
`;
export const MessageBubbleDiv = styled.div`
  display: flex;
  justify-content: ${(props) => (props?.isMe ? "flex-end" : "flex-start")};
  margin-bottom: 8px;
`;
export const MessageDiv = styled.div`
  background: ${({ isMe }) => (isMe ? "#dcf8c6" : "#f0f0f0")};
  color: black;
  padding: 8px 12px;
  border-radius: 16px;
  max-width: 70%;
  word-break: break-word;
  position: relative;
`;
export const StatusDiv = styled.div`
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 4px;
  gap: 4px;
`;
export const ChatUserIcon = styled(Avatar)`
  margin-right: 8px;
`;
export const ChatUserCartTimeSection = styled.div`
  display: flex;
  flex-direction: column;
`;
export const ChatUserMessageText = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
`;
export const ChatUserRightSelction = styled.div`
  display: flex;
  align-items: center;
`;
