import styled, { css, keyframes } from "styled-components";
import { NavLink } from "react-router-dom";
import { HEADER_HEIGHT, NAVBAR } from "../../Helper/constant";
import { Divider, Layout } from "antd";

const activeStyles = css`
  &::after {
    content: "";
    position: absolute;
    bottom: 1px;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: white;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
  }
`;
const activeStylesMobile = css`
  border: 1px solid black;
  color: white;
  border-radius: 10px;
  background-color: #565656;
`;
const activeStylesSubMenu = css`
  /* background-color: #e6f4ff; */
  color: #1677ff;
  border-radius: 10px;
`;
const NavbarStyle = {
  Wrapper: styled.nav`
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    background-color: ${NAVBAR.BACKGROUND_COLOR};
    align-self: flex-start;
    padding-inline: 2rem;
    height: ${NAVBAR.HEIGHT};
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  Logo: styled.h1`
    color: white;
    font-weight: normal;
    font-size: 2rem;
  `,
  Items: styled.div`
    height: 100%;
    display: flex;
    gap: 2rem;
    align-items: center;
  `,
  Item: styled(NavLink)`
    color: white;
    font-size: 1.4rem;
    font-weight: bold;
    height: ${NAVBAR.HEIGHT};
    cursor: pointer;
    text-decoration: none;
    padding-inline: 1rem;
    display: flex;
    gap: 1rem;
    align-items: center;
    position: relative;
    ${(props) => props.$active && !props.$isMobile && activeStyles}
    ${(props) =>
      props.$active && props.$isMobile && !props.$subMenu && activeStylesMobile}
    ${(props) => props.$active && props.$subMenu && activeStylesSubMenu}
  `,
  //dropdown from antd

  IconButton: styled.button`
    color: white;
    font-size: 1.4rem;
    padding: 0 1rem;
    cursor: pointer;
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;

    &:focus {
      outline: none;
    }

    &:hover {
      opacity: 0.8;
    }
  `,
};
export const StyleHeader = styled(Layout.Header)`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${(props) => props.theme.token.colorTextLightSolid};
  height: ${HEADER_HEIGHT};
  background-color: ${(props) => props.theme.token.colorPrimary1};
  font-family: ${(props) => props.theme.token.fontFamily};
  padding-inline: 2rem;
  line-height: normal;

  @media (max-width: 500px) {
    padding-inline: 1rem;
  }
`;
export const DigitalClockContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 900px) {
    display: none;
  }

  /* border-radius: 1rem; */
`;
export const ClockBorder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid black;
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid white;

  @media (max-width: 1200px) {
    padding: 2px 4px;
  }
`;
export const FlipDigitContainer = styled.div`
  position: relative;
  height: 30px;
  width: 20px;
  margin: 0 2px;
  /* overflow: hidden; */
  @media (max-width: 1200px) {
    width: 10px;
    margin: 0 1px;
  }
`;
export const DigitSpan = styled.span`
  display: block;
  width: 100%;
  height: 30px;
  line-height: 30px;
  text-align: center;
  transition: all 0.5s ease;
`;
export const TopDigit = styled(DigitSpan)`
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: bottom center;
  ${({ flip }) => flip && `transform: rotateX(-90deg);`}
`;
export const BottomDigit = styled(DigitSpan)`
  position: absolute;
  bottom: 0;
  left: 0;
  transform-origin: top center;
  ${({ flip }) => flip && `transform: rotateX(90deg);`}
`;
export const CurrentDigit = styled(DigitSpan)`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
`;
export const Colon = styled.div`
  margin: 0 4px;
`;
export const ActiveTotalUser = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
`;
export const ActiveText = styled.div`
  width: 60%;
  line-height: 1.2rem;
`;
export const ActiveNumber = styled.div`
  width: 30%;
  font-size: 2rem;
  color: ${(props) => props?.theme?.token?.marketWatchUp};
`;
export const LiveUserDivider = styled(Divider)`
  border-color: white !important;
  background-color: white !important;
  height: 3rem;
`;

export const ExpDiv = styled.div`
  text-align: left;
  margin-inline: 0.5rem;
  font-weight: 600;
`;
export const ValidText = styled.p`
  font-size: 0.8rem;
`;
export const ExpText = styled.p`
  font-size: 1.1rem;
`;

export const scrollLeft = keyframes`
  /* from { transform: translateX(100%); }
  to { transform: translateX(-100%); } */
  /* 0% { transform: translateX(100%); } */
  100% { transform: translateX(-100%); }
`;

export const ScrollingMessage = styled.p`
  display: inline-block;
  white-space: nowrap;
  animation: ${scrollLeft} 30s linear infinite;
  color: red;
  font-weight: 400;
  font-size: 1.5rem;
  width: fit-content;
`;

export const TickerContainer = styled.div`
  width: 20rem;
  overflow: hidden;
  position: relative;

  @media (max-width: 1056px) {
    /* width: 10rem; */
    display: none;
  }

  /* @media (max-width: 500px) {
    display: none;
  } */
`;

export const IconWrapper = styled.div`
  display: flex;
  width: 3rem;
  height: 3rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 6.25rem;
  background-color: ${(props) => props.theme.token.colorPrimaryLight};
  margin-left: 1rem;
`;

export default NavbarStyle;
