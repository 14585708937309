import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { ProtectedRoute } from "../ProtectedRoute";

// Import all your pages
import Login from "../../pages/Auth/Login";
import Home from "../../pages/Home";
import Users from "../../pages/Users";
import NewAccount from "../../pages/Users/NewAccount";
import Accounts from "../../pages/Users/Accounts";
import MainLayout from "../../pages/MainLayout";
import MarketWatch from "../../pages/MarketWatch";
import NotFound from "../../components/NotFound";
import DashboardScreen from "../../pages/Dashboard";
import SummaryReport from "../../pages/SummaryReport";
import TradingTrade from "../../pages/TradingTrade";
import TradingBlocked from "../../pages/TradingBlocked";
import TradingPosition from "../../pages/TradingPosition";
import TradeLog from "../../pages/TradeLog";
import RejectionLog from "../../pages/RejectionLog";
import BlukTrading from "../../pages/BulkTrading";
import BrokerageReport from "../../pages/BrokerageReport";
import TradeReport from "../../pages/TradeReport";
import ProfitLossReport from "../../pages/ProfitLossReport";
// import AccountPointLedger from "../../pages/AccountPointLedger";
import AccountPointEntry from "../../pages/AccountPointEntry";
import AccountPointJVEntry from "../../pages/AccountPointJVEntry";
import ExpirySetting from "../../pages/ExpirySetting";
import ResetPassward from "../../pages/ResetPassward/ResetPassward";
import MarketSetting from "../../pages/MarketSetting/MarketSetting";
import Profile from "../../pages/Profile/Profile";
import ReportCart from "../../pages/SummaryReport/ReportCart";
import UserLedger from "../../pages/UserLedger/UserLedger";
import M2mAlertLog from "../../pages/M2mAlertLog/M2mAlertLog";
import PrivacyPolicy from "../../pages/PrivacyPolicy/PrivacyPolicy";
import PointLedgerLog from "../../pages/PointLedgerLog";
import UserEditLog from "../../pages/UserEditLog/UserEditLog";
import CreateRentAccount from "../../pages/Users/RentAccount/CreateRentAccount";
import BrokerSharingReport from "../../pages/BrokerSharingReport/BrokerSharingReport";
import ValanSetting from "../../pages/ValanSetting";
import IPAddressReport from "../../pages/IPAddressReport/IPAddressReport";
import UserProfile from "../../pages/Users/Accounts/UserProfile";
import RulesAndRegulation from "../../pages/Rules";
import NotificationPage from "../../pages/Notification";
import BhavCopy from "../../pages/BhavCopy/BhavCopy";

// Define your routes dynamically
const AllRoutes = () => {
  // Get user info from Redux
  const loginUser = useSelector(
    (state) => state.authSlice?.userDetail?.accountType
  );
  const isCustomer = loginUser === "customer";
  const isOwner = loginUser === "owner";
  const isBroker = loginUser === "broker";
  const additionalSharing = useSelector(
    (state) => state.authSlice?.additionalSharing
  );
  const showValanSetting = additionalSharing?.valanDelete;
  const showPointEntry = additionalSharing?.accountPointEntry;
  const showNewAccount = additionalSharing?.newClientAdd;
  const showIpAddressReport = additionalSharing?.ipAddressReport;

  // Define route configurations
  const publicRoutes = [
    { path: "/login", element: <Login /> },
    { path: "/privacy-policy", element: <PrivacyPolicy /> },
    { path: "/resetPassward", element: <ResetPassward /> },
    { path: "/rules-and-regulation", element: <RulesAndRegulation /> },
  ];

  const profileRoute = {
    path: "/profile",
    element: (
      <ProtectedRoute>
        <Profile />
      </ProtectedRoute>
    ),
    showIf: !isBroker && !isOwner,
  };

  const mainLayoutRoutes = [
    {
      path: "/",
      element: (
        <ProtectedRoute>
          <DashboardScreen />
        </ProtectedRoute>
      ),
    },
    {
      path: "/dashboard",
      element: (
        <ProtectedRoute>
          <DashboardScreen />
        </ProtectedRoute>
      ),
    },
    {
      path: "/market",
      element: (
        <ProtectedRoute>
          <MarketWatch />
        </ProtectedRoute>
      ),
      showIf: !isBroker,
    },
    {
      path: "/report",
      element: (
        <ProtectedRoute>
          <SummaryReport />
        </ProtectedRoute>
      ),
    },
    {
      path: "/valanBill/:combinedParams",
      element: (
        <ProtectedRoute>
          <ReportCart />
        </ProtectedRoute>
      ),
    },
    {
      path: "/trading",
      element: null,
    },
    {
      path: "/trading/trade",
      element: (
        <ProtectedRoute>
          <TradingTrade />
        </ProtectedRoute>
      ),
    },
    {
      path: "/trading/blocked-scripts",
      element: (
        <ProtectedRoute>
          <TradingBlocked />
        </ProtectedRoute>
      ),
    },
    {
      path: "/trading/position",
      element: (
        <ProtectedRoute>
          <TradingPosition />
        </ProtectedRoute>
      ),
    },
  ];

  // Users section routes
  const usersRoutes = {
    path: "/users",
    element: (
      <ProtectedRoute>
        <Users />
      </ProtectedRoute>
    ),
    showIf: !isCustomer && !isBroker,
    children: [
      {
        path: "new-account",
        element: (
          <ProtectedRoute>
            <NewAccount is_edits={false} key="new" />
          </ProtectedRoute>
        ),
        showIf: showNewAccount,
      },
      {
        path: "edit-account/:id",
        element: (
          <ProtectedRoute>
            <NewAccount is_edits={true} key="edit-account" />
          </ProtectedRoute>
        ),
      },
      {
        path: "accounts",
        element: (
          <ProtectedRoute>
            <Accounts />
          </ProtectedRoute>
        ),
      },
      {
        path: "create-rent-account",
        element: (
          <ProtectedRoute>
            <CreateRentAccount key="createRentAccount" />
          </ProtectedRoute>
        ),
        showIf: loginUser !== "dealer" && showNewAccount,
      },
      {
        path: "edit-rent-account/:id",
        element: (
          <ProtectedRoute>
            <CreateRentAccount is_edits={true} key="editRentAccount" />
          </ProtectedRoute>
        ),
        showIf: loginUser !== "dealer",
      },
      {
        path: "user-profile/:id",
        element: (
          <ProtectedRoute>
            <UserProfile />
          </ProtectedRoute>
        ),
      },
    ],
  };

  // Utilities routes
  const utilitiesRoutes = [
    {
      path: "/utilities",
      element: (
        <ProtectedRoute>
          <Home />
        </ProtectedRoute>
      ),
      showIf: !isCustomer,
    },
    {
      path: "/utilities/trade-log",
      element: (
        <ProtectedRoute>
          <TradeLog />
        </ProtectedRoute>
      ),
      showIf: !isCustomer,
    },
    {
      path: "/utilities/user-edit-log",
      element: (
        <ProtectedRoute>
          <UserEditLog />
        </ProtectedRoute>
      ),
      showIf: !isCustomer,
    },
    {
      path: "/utilities/point-ledger-log",
      element: (
        <ProtectedRoute>
          <PointLedgerLog />
        </ProtectedRoute>
      ),
      showIf: !isCustomer,
    },
    {
      path: "/utilities/rejection-log",
      element: (
        <ProtectedRoute>
          <RejectionLog />
        </ProtectedRoute>
      ),
    },
    {
      path: "/utilities/M2mAlert-log",
      element: (
        <ProtectedRoute>
          <M2mAlertLog />
        </ProtectedRoute>
      ),
      showIf: !isCustomer,
    },
    {
      path: "/utilities/bulk-trading",
      element: (
        <ProtectedRoute>
          <BlukTrading />
        </ProtectedRoute>
      ),
      showIf: !isCustomer,
    },
  ];

  // Reports routes
  const reportsRoutes = [
    {
      path: "/reports/brokerage-report",
      element: (
        <ProtectedRoute>
          <BrokerageReport />
        </ProtectedRoute>
      ),
      showIf: !isCustomer,
    },
    {
      path: "/reports/IP-address-report",
      element: (
        <ProtectedRoute>
          <IPAddressReport />
        </ProtectedRoute>
      ),
      showIf: showIpAddressReport && !isCustomer && !isBroker,
    },
    {
      path: "/reports/broker-sharing-report",
      element: (
        <ProtectedRoute>
          <BrokerSharingReport />
        </ProtectedRoute>
      ),
      showIf: !isCustomer && !isOwner && !isBroker,
    },
    {
      path: "/reports/trade-report",
      element: (
        <ProtectedRoute>
          <TradeReport />
        </ProtectedRoute>
      ),
      showIf: !isCustomer,
    },
    {
      path: "/reports/profit-and-loss-percentage",
      element: (
        <ProtectedRoute>
          <ProfitLossReport />
        </ProtectedRoute>
      ),
      showIf: !isCustomer,
    },
  ];

  // Settings routes
  const settingsRoutes = [
    {
      path: "/settings/notification",
      element: (
        <ProtectedRoute>
          <NotificationPage />
        </ProtectedRoute>
      ),
    },
    {
      path: "/settings/valan-setting",
      element: (
        <ProtectedRoute>
          <ValanSetting />
        </ProtectedRoute>
      ),
      showIf: isOwner || (showValanSetting && !isCustomer && !isBroker),
    },
    {
      path: "/settings/expiry-setting",
      element: (
        <ProtectedRoute>
          <ExpirySetting />
        </ProtectedRoute>
      ),
      showIf: isOwner,
    },
    {
      path: "/settings/market-setting",
      element: (
        <ProtectedRoute>
          <MarketSetting />
        </ProtectedRoute>
      ),
      showIf: isOwner,
    },
    {
      path: "/settings/bhav-copy",
      element: (
        <ProtectedRoute>
          <BhavCopy />
        </ProtectedRoute>
      ),
      showIf: isOwner,
    },
  ];

  // Other routes
  const otherRoutes = [
    {
      path: "/user-ledger/:id",
      element: (
        <ProtectedRoute>
          <UserLedger />
        </ProtectedRoute>
      ),
    },
    {
      path: "/account-point-entry",
      element: (
        <ProtectedRoute>
          <AccountPointEntry />
        </ProtectedRoute>
      ),
      showIf: !isCustomer && showPointEntry,
    },
    {
      path: "/account-point-jv-entry",
      element: (
        <ProtectedRoute>
          <AccountPointJVEntry />
        </ProtectedRoute>
      ),
      showIf: !isCustomer && showPointEntry,
    },
  ];

  // Helper function to render routes based on conditions
  const renderRoutes = (routesArray) => {
    return routesArray
      .filter((route) => route.showIf !== false)
      .map((route, index) => {
        if (route.children) {
          return (
            <Route key={index} path={route.path} element={route.element}>
              {route.children
                .filter((childRoute) => childRoute.showIf !== false)
                .map((childRoute, childIndex) => (
                  <Route
                    key={`${index}-${childIndex}`}
                    path={childRoute.path}
                    element={childRoute.element}
                  />
                ))}
            </Route>
          );
        }
        return <Route key={index} path={route.path} element={route.element} />;
      });
  };

  return (
    <Routes>
      {/* Public routes */}
      {publicRoutes.map((route, index) => (
        <Route
          key={`public-${index}`}
          path={route.path}
          element={route.element}
        />
      ))}

      {/* Profile route with condition */}
      {profileRoute.showIf && (
        <Route path={profileRoute.path} element={profileRoute.element} />
      )}

      {/* Main layout routes */}
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <MainLayout />
          </ProtectedRoute>
        }
      >
        {renderRoutes(mainLayoutRoutes)}

        {/* Users section with nested routes */}
        {usersRoutes.showIf && (
          <Route path={usersRoutes.path} element={usersRoutes.element}>
            {renderRoutes(usersRoutes.children)}
          </Route>
        )}

        {/* Other route sections */}
        {renderRoutes(utilitiesRoutes)}
        {renderRoutes(reportsRoutes)}
        {renderRoutes(settingsRoutes)}
        {renderRoutes(otherRoutes)}
      </Route>

      {/* 404 Not Found route */}
      <Route
        path="*"
        element={
          <ProtectedRoute>
            <NotFound />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export default AllRoutes;
