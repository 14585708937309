import React, { useState, useEffect } from "react";
import { Input, Avatar, Typography, Skeleton, Badge } from "antd";
import { SearchOutlined, UserOutlined } from "@ant-design/icons";
import {
  ChatUserCartTimeSection,
  ChatUserMessageText,
  ChatUserRightSelction,
  ClientCartDiv,
  ClientListWrapper,
  ClientSectionWrapper,
  CustomCard,
} from "./style";
import dayjs from "dayjs";

const { Text } = Typography;

const truncateMessage = (message, maxLength) => {
  return message?.length > maxLength
    ? `${message?.slice(0, maxLength)}...`
    : message;
};
const sortChildUsers = (childUsers = [], messages = {}) => {
  // Guard clause for empty arrays
  if (!childUsers || !childUsers.length) return [];

  const updatedChild = childUsers.map((user) => {
    if (!user) return user;
    const userMessages =
      !messages[user._id] || !(messages[user._id] instanceof Map)
        ? []
        : [...messages[[user._id]].values()];
    // const userMessages = messages[user._id] || [];
    const latestMessage =
      userMessages.length > 0 ? userMessages[userMessages.length - 1] : null;

    return {
      ...user,
      timestamp: latestMessage?.timestamp || null,
    };
  });

  return updatedChild.sort((a, b) => {
    // Users with no timestamp go to the end
    if (!a.timestamp && !b.timestamp) return 0;
    if (!a.timestamp) return 1;
    if (!b.timestamp) return -1;

    // Compare valid timestamps (newest first)
    const timeA = new Date(a.timestamp).getTime();
    const timeB = new Date(b.timestamp).getTime();
    return timeB - timeA;
  });
};

const ClientListSection = ({
  childUsers,
  childUserLoading,
  activeUser,
  setActiveUser,
  messages,
  login_id,
}) => {
  // state
  const [childData, setChildData] = useState(
    sortChildUsers(childUsers, messages)
  );
  const [search, setSearch] = useState("");

  // life cycle
  useEffect(() => {
    if (search) {
      const filteredUsers = childUsers.filter((user) =>
        user?.accountName.toLowerCase().includes(search.toLowerCase())
      );
      setChildData(filteredUsers);
    } else {
      setChildData(sortChildUsers(childUsers, messages));
    }
  }, [search, childUsers]);

  useEffect(() => {
    setChildData(sortChildUsers(childUsers, messages));
  }, [messages]);

  return (
    <ClientSectionWrapper>
      <div style={{ marginBottom: "2rem" }}>
        <Input
          placeholder="Search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          prefix={<SearchOutlined />}
        />
      </div>

      <ClientListWrapper>
        {childUserLoading ? (
          <div style={{ padding: "1rem" }}>
            <Skeleton active avatar />
            <Skeleton active avatar />
            <Skeleton active avatar />
          </div>
        ) : (
          childData?.map((user) => (
            <ChatUserCard
              key={user._id}
              user={user}
              setActiveUser={setActiveUser}
              activeUser={activeUser}
              messages={messages[user._id]}
              login_id={login_id}
            />
          ))
        )}
      </ClientListWrapper>
    </ClientSectionWrapper>
  );
};

export default React.memo(ClientListSection);

const ChatUserCard = React.memo(
  ({ user, activeUser, setActiveUser, messages, login_id }) => {
    const values =
      !messages || !(messages instanceof Map) ? [] : [...messages.values()];

    // function
    const handleSelectUser = () => {
      setActiveUser(user._id);
    };

    const count = values?.reduce((acc, curr) => {
      if (curr?.status !== "seen") {
        acc++;
      }
      return acc;
    }, 0);

    const isShowCount = values?.[values.length - 1]?.senderId !== login_id;

    return (
      <CustomCard
        isactive={activeUser === user?._id}
        onClick={handleSelectUser}
      >
        <ClientCartDiv>
          <ChatUserRightSelction>
            <Avatar size={40} icon={<UserOutlined />} />
            <ChatUserMessageText>
              <Text strong>{user?.accountName}</Text>
              <Text type="secondary">
                {truncateMessage(values?.[values?.length - 1]?.message, 35)}
              </Text>
            </ChatUserMessageText>
          </ChatUserRightSelction>
          <ChatUserCartTimeSection>
            <Text type="secondary">
              {values?.length > 0
                ? formatMessageTime(values?.[values?.length - 1]?.timestamp)
                : ""}
            </Text>
            {count > 0 && isShowCount ? <Badge count={count}></Badge> : null}
          </ChatUserCartTimeSection>
        </ClientCartDiv>
      </CustomCard>
    );
  }
);

const formatMessageTime = (timestamp) => {
  if (!timestamp) return "";

  const messageDate = dayjs(timestamp);
  const today = dayjs();
  const yesterday = today.subtract(1, "day");

  if (messageDate.isSame(today, "day")) {
    // If message is from today, show time
    return messageDate.format("HH:mm A");
  } else if (messageDate.isSame(yesterday, "day")) {
    // If message is from yesterday, show "Yesterday"
    return "Yesterday";
  }
  // else if (messageDate.isAfter(today.subtract(7, "day"))) {
  //   // If message is from last 7 days, show day name
  //   return messageDate.format("ddd");
  // }
  else {
    // Otherwise show date
    return messageDate.format("DD/MM/YYYY");
  }
};
