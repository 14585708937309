import { Popconfirm } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { CloseImage, StyTable } from "./style";
import {
  MemorizedPositionData,
  PositionAverage,
  PositionQuantity,
  PositionSocketData,
} from "../../components/SocketLiveData";
import closeImage from "../../assets/icons/closeIcon.svg";
import { valanBillDate } from "../../components/CommanFuction/CommanFunction";
import { ScriptName } from "./Components/tradePositionComponent";
import { useSelector } from "react-redux";

const PositionTable = (props) => {
  const {
    position,
    value,
    el,
    currentPriceRef,
    closePosition,
    clearSelect,
    setClearSelect,
    isBroker,
  } = props;

  // state
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  //life cycle
  useEffect(() => {
    if (clearSelect) {
      setSelectedRowKeys([]);
      setClearSelect(false);
    }
  }, [clearSelect]);

  // column
  const columns = useMemo(() => {
    let array = [
      {
        title: "SR No.",
        dataIndex: "index",
        key: "index",
        align: "center",
        width: 50,
        render: (text, record, index) => {
          return index + 1;
        },
      },
      {
        title: "SEGMENT",
        dataIndex: "marketName",
        key: "marketName",
        align: "left",
        width: "7rem",
      },
      {
        title: "CLIENT INFO",
        dataIndex: "client",
        key: "client",
        width: "10rem",
        render: (text, record) => {
          return (
            <span>{`${record?.user?.[0]?.userId} (${record?.user?.[0]?.accountName})`}</span>
          );
        },
      },
      {
        title: "SCRIPT NAME",
        dataIndex: "symbolName",
        key: "symbol",
        align: "left",
        width: "12rem",
        sorter: {
          compare: (a, b) => a?.symbolName?.localeCompare(b?.symbolName),
        },
        render: (text, record) => {
          const date = valanBillDate(record?.expiryDate, false);
          const name = ScriptName(record);
          return (
            <div style={{ textAlign: "left" }}>
              {name} {date}
            </div>
          );
        },
      },
      {
        title: "TYPE",
        dataIndex: "positionType",
        key: "positionType",
        align: "center",
        width: "4rem",
        render: (text, record) => {
          return <div>{record?.positionType}</div>;
        },
      },
      {
        title: "BUY QTY.(LOT)",
        dataIndex: "totalBuyQuantity",
        key: "totalBuyQuantity",
        align: "end",
        width: "10rem",
        render: (text, record) => {
          return (
            <PositionQuantity
              id={record?._id}
              market={record?.marketName}
              type={"totalBuyQuantity"}
              lot={"totalBuyLot"}
            />
          );
        },
      },
      {
        title: "BUY AVG.",
        dataIndex: "totalBuyPrice",
        key: "totalBuyPrice",
        align: "end",
        width: "10rem",
        render: (text, record) => {
          return <PositionAverage id={record?._id} type={"buyAverage"} />;
        },
      },
      {
        title: "SELL QTY.(LOT)",
        dataIndex: "totalSellQuantity",
        key: "totalSellQuantity",
        align: "end",
        width: "10rem",
        render: (text, record) => {
          return (
            <PositionQuantity
              id={record?._id}
              market={record?.marketName}
              type={"totalSellQuantity"}
              lot={"totalSellLot"}
            />
          );
        },
      },
      {
        title: "SELL AVG.",
        dataIndex: "totalSellPrice",
        key: "totalSellPrice",
        align: "end",
        width: "10rem",
        render: (text, record) => {
          return <PositionAverage id={record?._id} type={"sellAverage"} />;
        },
      },
      {
        title: "OUTSTANDING (LOT)",
        dataIndex: "netQ",
        align: "end",
        key: "netQ",
        width: "10rem",
        render: (text, record) => {
          return (
            <PositionQuantity
              id={record?._id}
              market={record?.marketName}
              type={"outstandingQuantity"}
              lot={"outstandingLot"}
            />
          );
        },
      },
      {
        title: "LIVE",
        dataIndex: "cR",
        key: "cR",
        width: "10rem",
        align: "center",
        render: (text, record) => {
          if (record?.status === "Close") {
            return "-";
          } else {
            return (
              <MemorizedPositionData id={record?._id} type={"LiveValue"} />
            );
          }
        },
      },
      {
        title: "P & L",
        dataIndex: "m2M",
        align: "end",
        key: "m2M",
        width: "15rem",
        render: (text, record) => {
          return <PositionSocketData id={record?._id} type={"currentPNL"} />;
        },
      },
      {
        title: "EXIT",
        dataIndex: "close",
        key: "close",
        align: "center",
        width: 50,
        render: (imageUrl, record) => {
          if (record?.status === "Close") {
            return "-";
          } else {
            return (
              <>
                <Popconfirm
                  title={"Close Position"}
                  description={"Are you sure to close position ?"}
                  onConfirm={() => handleClose(record)}
                  placement="topRight"
                >
                  <CloseImage
                    style={{ cursor: "pointer" }}
                    src={closeImage}
                    alt="Product"
                  />
                </Popconfirm>
              </>
            );
          }
        },
      },
    ];
    if (isBroker) {
      array = array?.filter((el) => el?.dataIndex !== "close");
    }
    return array;
  }, [position]);

  //state
  const [positionColumn, setPositionColumn] = useState(columns);

  //use effect
  useEffect(() => {
    if (value?.radioClient !== 1) {
      setPositionColumn(() => {
        return columns.filter((el) => el.dataIndex !== "client");
      });
    } else {
      setPositionColumn(columns);
    }
  }, [value?.radioClient, position]);

  //redux
  const userIP = useSelector((state) => state.authSlice?.user?.userIP) || "";

  // function
  const handleClose = async (record) => {
    let obj = {
      deviceType: "web",
      positionIds: [
        {
          id: record?._id,
          tradePrice: 0,
          High: 0,
          low: 0,
        },
      ],
      tradeIpAddress: userIP,
      type: "Close",
    };

    let closeArray = position[el]?.length === 1 ? [el] : [];

    await closePosition(obj, closeArray);
  };
  const rowSelection = {
    selectedRowKeys,
    columnWidth: 40,
    onChange: (selectedRowKeys, selectedRows, key) => {
      const positionSelected = selectedRows?.map((el) => {
        return { id: el?._id, symbol: el?.symbol, marketName: el?.marketName };
      });
      currentPriceRef.current[el] = positionSelected;
      setSelectedRowKeys(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.status === "Close",
    }),
    clearSelectedRows: () => {
      // rowSelection.selectedRowKeys = [];
    },
  };

  // const rowClassName = (record) => {
  //   switch (record?.type) {
  //     case "buy":
  //       return "buy-row";
  //     case "sell":
  //       return "sell-row";
  //     case "close":
  //       return "close-row";
  //     default:
  //       return "";
  //   }
  // };

  return (
    <StyTable
      className="custom-virtual-table"
      virtual
      columns={positionColumn}
      {...(!isBroker ? { rowSelection: rowSelection } : {})}
      // rowClassName={rowClassName}
      dataSource={position[el]}
      pagination={false}
      sortDirections={["ascend", "descend", "ascend"]}
      scroll={{ x: 1300 }}
      rowKey="_id"
      // locale={{ emptyText: isLoading ? <Skeleton active /> : null }}
    />
  );
};

export default React.memo(PositionTable);
