import { useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import APICall from "../../APICall";
import EndPoints from "../../APICall/EndPoints";
import { Col, Modal, Row, notification } from "antd";
import ThemeCard from "../ThemeCard";
import ThemeInput from "../ThemeInput";
import ThemeInputNumber from "../ThemeInputNumber/ThemeInputNumber";
import ActionButton from "../ActionButton";
import { isValidTrade } from "../../pages/MarketWatch/TradeModal";
import store from "../../store/store";
import { valanBillDate } from "../CommanFuction/CommanFunction";
import { ScriptWiseSettingCheckbox } from "../../pages/Users/NewAccount/style";
import { useSelector } from "react-redux";
import {
  EditTradeRadioButton,
  EditTradeSubmitRow,
} from "../../styles/commonStyle";
import { GlobleMarket } from "../../Helper/constant";
import { useSocket } from "../Socket";

const typeOptions = [
  {
    label: "Buy",
    value: "buy",
  },
  {
    label: "Sell",
    value: "sell",
  },
];

const EditTrade = ({ handleCancel, record, queryKey, pendingSymbol }) => {
  const { marketName } = record;
  const queryCliant = useQueryClient();
  const socketContext = useSocket();
  const { doSend } = socketContext;

  // state
  const [data, setData] = useState({
    lot: record?.lot,
    quantity: record?.quantity,
    tradePrice: record?.tradePrice,
    tradePattern: record?.tradePattern,
    incognito: false,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    doSend({
      event: "subscribeToServerMarket",
      payload: [record?.symbol],
    });

    return () => {
      let symbolEncluded = pendingSymbol?.includes(record?.symbol);

      if (queryKey?.[0] === "tradingTrade" || !symbolEncluded) {
        doSend({
          event: "unsubscribeToServerMarket",
          payload: [record?.symbol],
        });
      }
    };
  }, []);

  //redux
  const isOwner =
    useSelector((state) => state?.authSlice?.userDetail?.accountType) ===
    "owner";

  // api call
  const handleSubmit = async () => {
    const livePrice =
      store.getState()?.webSocketSlice?.webSocket?.data?.[record?.symbol] || 0;
    const stopLoss =
      marketName === "GLOBEX" || marketName === "FOREX"
        ? record?.tradePattern == "buy"
          ? livePrice?.Bid
          : livePrice?.Ask
        : livePrice?.LTP || 0;
    const [allValid, name] = isValidTrade(data, record);
    if (!allValid) {
      return notification.error({
        message: `Please enter positive numbers greater than 0 for ${name}`,
      });
    }

    let marketCheck = marketName === "NSE EQU" || marketName === "NSE FUT";
    if (marketCheck) {
      let modifiedPrice = data?.tradePrice?.toString()?.split(".")?.[1];
      const isPriceAllow =
        modifiedPrice?.[1] == 0 ||
        modifiedPrice?.[1] == 5 ||
        modifiedPrice?.[1] == undefined;
      if (data?.tradePrice > 250 && !isPriceAllow) {
        return notification.error({ message: "Price is not in Format" });
      }
    }

    if (
      data.tradePrice >= livePrice?.Ask &&
      data.tradePrice <= livePrice?.Bid
    ) {
      return notification.error({
        message: "Edit order not allow between buy price and sell price",
      });
    }

    if (parseFloat(stopLoss) == 0) {
      return notification.error({ message: "LTP/Quotation not found" });
    }

    let id = record?._id;
    let obj = {
      lot: Number(data.lot) || 0,
      quantity: data.quantity,
      tradePrice: Number(data.tradePrice),
      stoploss: parseFloat(stopLoss),
    };
    // if (isOwner) {
    //   obj.tradePattern = data.tradePattern;
    //   obj.incognito = data.incognito;
    // }

    setLoading(true);
    try {
      let response = await APICall("patch", `${EndPoints.editTrade}${id}`, obj);
      // console.log("response", response);
      if (response.status === 200) {
        notification.success({ message: response?.data?.message });
        if (queryKey?.[0] === "tradingTrade") {
          queryCliant.refetchQueries({ queryKey: queryKey });
        }
      } else {
        notification.error({ message: response?.data?.message });
      }

      handleCancel();
    } catch (error) {
      notification.error({ message: error?.message });
    } finally {
      setLoading(false);
    }
  };

  // function
  const onChangeValue = ({ target: { name, value } }) => {
    switch (name) {
      case "tradePrice":
        const validMarket = GlobleMarket.includes(marketName);
        if (validMarket) {
          setData({ ...data, tradePrice: value });
          break;
        }
        const validFormat = /^\d*\.?\d{0,2}$/.test(value);

        const isEmptyOrValid = validFormat || value === "";
        if (
          marketName === "MCX" &&
          parseFloat(value) > 1500 &&
          isEmptyOrValid
        ) {
          setData({ ...data, tradePrice: parseFloat(value) });
          break;
        }
        if (isEmptyOrValid) {
          const decimal = value.split(".")[1]?.toString();
          const isNSEMarket = ["NSE EQU", "NSE FUT"].includes(marketName);
          const isValidDecimal =
            !decimal ||
            decimal.length === 1 ||
            (decimal.length === 2 &&
              (decimal[1] === "0" || decimal[1] === "5" || isNSEMarket));

          if (isValidDecimal) {
            setData({ ...data, tradePrice: value });
          }
        }
        break;
      case "quantity":
        let lotValue = (value || 1) / record?.lotSize;
        setData({ ...data, [name]: value, lot: lotValue });
        break;
      case "lot":
        let qtyValue = record?.lotSize * (value || 1);
        setData({ ...data, [name]: value, quantity: qtyValue });
        break;
      default:
        setData({ ...data, [name]: value });
        break;
    }
  };

  // const onChangeValue1 = ({ target: { name, value } }) => {
  //   if (name === "tradePrice") {
  //     const isMCXOver1500 = marketName === "MCX" && parseFloat(value) > 1500;
  //     const regex = /^\d*\.?\d{0,2}$/;
  //     const valid = istrue.includes(marketName);
  //     if ((regex.test(value) || value === "") && !valid) {
  //       const decimal = value.split(".")[1]?.toString();
  //       if (isMCXOver1500) {
  //         setData({ ...data, [name]: parseFloat(value) });
  //       } else {
  //         if (decimal?.length == 2) {
  //           const secondDecimalDigit = decimal[1];
  //           if (
  //             secondDecimalDigit === "0" ||
  //             secondDecimalDigit === "5" ||
  //             marketName === "NSE EQU" ||
  //             marketName === "NSE FUT"
  //           ) {
  //             setData({ ...data, [name]: value });
  //           }
  //         } else if (!decimal || decimal?.length === 1) {
  //           setData({ ...data, [name]: value });
  //         }
  //       }
  //     }
  //     if (valid) {
  //       setData({ ...data, [name]: value });
  //     }
  //   } else if (name === "quantity") {
  //     let lotValue = (value || 1) / record?.lotSize;
  //     setData({ ...data, [name]: value, lot: lotValue });
  //   } else if (name === "lot") {
  //     let qtyValue = record?.lotSize * (value || 1);
  //     setData({ ...data, [name]: value, quantity: qtyValue });
  //   } else {
  //     setData({ ...data, [name]: value });
  //   }
  // };

  return (
    <Modal
      title={
        <Row justify={"left"}>
          <Col span={12}>
            {`${record?.symbolName} ${record?.series || ""} ${
              record?.strikePrice || ""
            } ${record?.option_type || ""}  ${
              record?.expiryDate ? valanBillDate(record?.expiryDate) : ""
            }`}
          </Col>

          <Col span={10}>
            {`${record?.customer[0]?.accountName} (${record?.customer[0]?.userId})`}
          </Col>
        </Row>
      }
      open={true}
      onOk={handleCancel}
      onCancel={handleCancel}
      footer={false}
    >
      <ThemeCard bordered={false} style={{ margin: "0rem 0rem" }}>
        <Row justify={"space-around"} gutter={[10, 0]} align={"middle"}>
          {/* {isOwner && (
            <Col xs={12} sm={10} lg={7} xl={7} style={{ marginTop: "1rem" }}>
              <EditTradeRadioButton
                width={"100%"}
                options={typeOptions}
                onChange={(e) =>
                  setData({ ...data, tradePattern: e.target.value })
                }
                value={data?.tradePattern}
                optionType="button"
                buttonStyle="solid"
              />
            </Col>
          )} */}
          <Col
            span={8}
            // {...(isOwner && {
            //   xs: 12,
            //   sm: 10,
            //   lg: 6,
            //   xl: 6,
            // })}
          >
            <ThemeInput
              marginBottom={"0rem"}
              width={"100%"}
              title="Price"
              className="ThemeInput"
              extraProps={{
                input_type: "number",
              }}
              inputProps={{
                placeholder: "Price",
                name: "tradePrice",
                value: data?.tradePrice,
                onChange: onChangeValue,
                // onBlur: onChangeValue,
                // disabled: marketName !== "NSE FUT" ? true : false,
              }}
            />
          </Col>
          {marketName !== "NSE EQU" && (
            <Col
              span={8}
              // {...(isOwner && {
              //   xs: 12,
              //   sm: 10,
              //   lg: 5,
              //   xl: 5,
              // })}
            >
              <ThemeInputNumber
                marginBottom={"0rem"}
                width={"100%"}
                title="LOT"
                extraProps={{
                  input_type: "number",
                }}
                inputProps={{
                  placeholder: "LOT",
                  name: "lot",
                  value: data?.lot,
                  onChange: onChangeValue,
                  // onBlur: onChangeValue,
                  disabled: marketName === "NSE EQU" ? true : false,
                }}
              />
            </Col>
          )}
          <Col
            span={8}
            // {...(isOwner && {
            //   xs: 12,
            //   sm: 10,
            //   lg: 5,
            //   xl: 5,
            // })}
          >
            <ThemeInputNumber
              marginBottom={"0rem"}
              width={"100%"}
              title="QTY"
              className="ThemeInput"
              extraProps={{
                input_type: "number",
              }}
              inputProps={{
                placeholder: "QTY",
                name: "quantity",
                value: data?.quantity,
                onChange: onChangeValue,
                // onBlur: onChangeValue,
                // disabled: marketName !== "NSE FUT" ? true : false,
              }}
            />
          </Col>
        </Row>

        <EditTradeSubmitRow
          gutter={[10, 20]}
          //  isOwner={isOwner}
        >
          {/* {isOwner && (
            <Col style={{ display: "flex", alignSelf: "center" }}>
              <ScriptWiseSettingCheckbox
                name={`Incognito`}
                checked={data?.incognito}
                onChange={(e) =>
                  setData({ ...data, incognito: e.target.checked })
                }
              >
                Incognito
              </ScriptWiseSettingCheckbox>
            </Col>
          )} */}
          <Col>
            <ActionButton
              title={"Submit"}
              onClick={handleSubmit}
              loading={loading}
              height={"2.7rem"}
            />

            <ActionButton
              title={"Cancel"}
              buttonType="cancel"
              onClick={handleCancel}
              height={"2.7rem"}
              marginLeft={"1rem"}
            />
          </Col>
        </EditTradeSubmitRow>
      </ThemeCard>
    </Modal>
  );
};

export default React.memo(EditTrade);
