import React, { useEffect, useMemo, useRef, useState } from "react";
import { useBhavCopy, useGetCSV, useGetFile } from "../../APICall/apiHooks";
import ThemeCard from "../../components/ThemeCard";
import { Button, Col, Row, Skeleton } from "antd";
import {
  ColumnSettingIcon,
  StyTable,
  TableTitleSearch,
} from "../../components/ThemeCardTable/style";
import styled from "styled-components";
import ThemeInput from "../../components/ThemeInput";
import { SearchOutlined } from "@ant-design/icons";
import useDebounce from "../../hooks/3-useDebounce/useDebounce";
import TableFilterModal from "../../components/TableFilterModal/TableFilterModal";

const ButtonList = [
  {
    label: "Bhav Copy",
    value: "bhavcopy",
  },
  {
    label: "Corporate Action",
    value: "corporateAction",
  },
  {
    label: "Tender Date",
    value: "tenderDate",
  },
];

// Function to parse the response data
const parseResponseData = (responseData, checkList) => {
  if (!responseData) return null;
  // First, get the header line and data lines
  const lines = responseData?.trim()?.split("\n");
  const headerLine = lines?.[0];
  const column = headerLine?.split(",");

  // Create columns for the Table dynamically
  const tableColumns = column?.map((header) => ({
    title: header.charAt(0).toUpperCase() + header.slice(1), // Capitalize first letter
    dataIndex: header,
    key: header,
    hidden: checkList?.length == 0 ? false : !checkList?.includes(header),
    // Add sorter based on data type
    //   sorter: (a, b) => {
    //     // If it's a date field (like "expiry")
    //     if (header.toLowerCase().includes("date") || header === "expiry") {
    //       return new Date(a[header]) - new Date(b[header]);
    //     }
    //     // If it's a numeric field
    //     else if (!isNaN(parseFloat(a[header]))) {
    //       return parseFloat(a[header]) - parseFloat(b[header]);
    //     }
    //     // Default to string comparison
    //     else {
    //       return a[header].localeCompare(b[header]);
    //     }
    //   },
    // Format dates nicely
    render: (text, record) => {
      if (header.toLowerCase().includes("date") || header === "expiry") {
        // Check if it's a valid date
        const date = new Date(text);
        if (!isNaN(date)) {
          return date.toLocaleDateString();
        }
      }
      return text === "" ? "-" : text;
    },
  }));

  // Parse each data row
  const parsedData = [];

  for (let i = 1; i < lines.length; i++) {
    const rowValues = lines[i].trim().split(",");
    // Only process rows that have the correct number of values
    // console.log("rowValue", rowValues, rowValues.length);

    while (rowValues.length < column.length) {
      rowValues.push("");
    }

    if (rowValues.length === column.length) {
      const rowObject = {};
      column.forEach((header, index) => {
        rowObject[header] = rowValues[index];
      });
      parsedData.push({
        ...rowObject,
        key: i - 1, // Use index as key
      });
    }
  }

  return { data: parsedData, column: tableColumns };
};

const BhavCopy = () => {
  // state
  const [tableData, setTableData] = useState({ column: [], data: [] });
  const [activeCSV, setActiveCSV] = useState(null);
  const [search, setSearch] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const [checkList, setCheckList] = useState([]);
  const buttonRef = useRef("");

  // console.log("checkList", checkList);

  const filterColumn = useMemo(
    () =>
      tableData?.column?.map((el) => {
        return {
          title: el?.title,
          value: el?.dataIndex,
          initalShow: true,
          disable: false,
        };
      }),
    [tableData?.column]
  );

  // custom hook for get csv
  const { csvData, csvLoading } = useGetCSV();
  const { fileData, fileLoading, fileMutate } = useGetFile();
  const { bhavCopyLoading, bhavCopyMutate } = useBhavCopy();

  // life cycle
  useEffect(() => {
    if (fileData?.status === 200) {
      const parsedData = parseResponseData(fileData?.data, checkList);
      if (parsedData) {
        setTableData({ column: parsedData.column, data: parsedData.data });
        const tempCol = parsedData?.column?.map((el) => {
          return {
            title: el?.title,
            value: el?.dataIndex,
            initalShow: true,
            disable: false,
          };
        });
        setCheckList(
          tempCol?.filter((el) => el?.initalShow)?.map((item) => item?.value)
        );
      }
    }
  }, [fileData]);

  useEffect(() => {
    if (fileData?.status === 200 && checkList.length > 0) {
      const parsedData = parseResponseData(fileData?.data, checkList);
      if (parsedData) {
        setTableData({ column: parsedData.column, data: parsedData.data });
      }
    }
  }, [checkList]);

  const handleFile = (value) => {
    fileMutate(value);
    setActiveCSV(value);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleButton = (item) => {
    bhavCopyMutate({ names: [item?.value] });
    buttonRef.current = item.value;
  };

  // debouce for search
  useDebounce(
    () => {
      if (search) {
        const searchLower = search.toLowerCase();

        setTableData((pre) => {
          const parsedData = parseResponseData(fileData?.data, checkList);
          const newData = parsedData?.data.filter((item) => {
            const searchFields = [
              "tradingsymbol",
              "instrument_token",
              "exchange_token",
              "symbol",
              "symbolName",
              "symbolid",
              "Close",
              "zsymbol",
              "name",
              "segment",
              "TckrSymb",
              "FinInstrmNm",
              "FinInstrmId",
            ];
            return searchFields.some(
              (field) =>
                item[field] && item[field].toLowerCase().includes(searchLower)
            );
          });
          return { ...pre, data: newData };
        });
      } else if (search === "") {
        const parsedData = parseResponseData(fileData?.data, checkList);
        if (parsedData) {
          setTableData({ column: parsedData.column, data: parsedData.data });
        }
      }
    },
    400,
    [search]
  );

  return (
    <div>
      <ThemeCard
        title={
          <Row gutter={[10, 10]} align={"middle"} justify={"space-between"}>
            <Col>CSV Files</Col>
            <Col>
              <Row gutter={[10, 10]}>
                {ButtonList?.map((el) => {
                  return (
                    <Col>
                      <Button
                        type="primary"
                        loading={
                          bhavCopyLoading && buttonRef.current === el.value
                        }
                        onClick={() => handleButton(el)}
                      >
                        {el.label}
                      </Button>
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>
        }
      >
        {csvLoading ? (
          <Skeleton active />
        ) : (
          <Row gutter={[10, 10]} align={"middle"} justify={"left"}>
            {Array.isArray(csvData) &&
              csvData?.map((el) => {
                return (
                  <Col key={el} xs={12} sm={8} md={6} lg={6} xl={4}>
                    <StyledText
                      onClick={() => handleFile(el)}
                      isActive={activeCSV === el}
                    >
                      {el}
                    </StyledText>
                  </Col>
                );
              })}
          </Row>
        )}
      </ThemeCard>
      {activeCSV && (
        <ThemeCard
          title={
            <ThemeCardTitleRow
              gutter={[10, 10]}
              align={"middle"}
              justify={"space-between"}
            >
              <Col>{`${activeCSV} Table`}</Col>
              <TableTitleSearch
                xxl={4}
                xl={4}
                lg={6}
                md={8}
                sm={12}
                xs={24}
                style={{ display: "flex" }}
              >
                <ColumnSettingIcon onClick={() => setShowFilter(true)} />
                <ThemeInput
                  wrapperwidth={"100%"}
                  inputProps={{
                    placeholder: "Search anything here",
                    prefix: <SearchOutlined />,
                    style: { width: "100%" },
                    value: search,
                    onChange: handleSearch,
                  }}
                  marginBottom={"-5px"}
                />
              </TableTitleSearch>
            </ThemeCardTitleRow>
          }
          style={{ marginTop: "1rem" }}
        >
          <StyTable
            columns={tableData?.column}
            dataSource={tableData?.data}
            loading={fileLoading}
            pagination={{
              showSizeChanger: true,
            }}
            scroll={{ x: "max-content" }}
            // bordered
            size="small"
            rowKey="key"
          />
        </ThemeCard>
      )}
      {showFilter && (
        <TableFilterModal
          column={filterColumn}
          setShowFilter={setShowFilter}
          checkList={checkList}
          filtertype={"csvfilesTable"}
          setCheckList={setCheckList}
        />
      )}
    </div>
  );
};

export default BhavCopy;

// style
const StyledText = styled.p`
  cursor: pointer;
  color: ${(props) => (props?.isActive ? "blue" : "black")};
`;
const ThemeCardTitleRow = styled(Row)`
  @media (max-width: 580px) {
    padding-top: 1rem;
  }
`;
