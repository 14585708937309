import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Header from "./components/Header";
import Navbar from "./components/NavBar";
import RootCommonFunction from "./components/RootCommonFunction";
import AppStyle from "./App.style";
import "./App.css";
import ScrollToTopButton from "./components/ScrollToTop";
import SocketProvider from "./components/Socket";
import ScrollBar from "./components/NavBar/ScrollBar";
import { jwtDecode } from "jwt-decode";
import { useEffect, useLayoutEffect, useMemo } from "react";
import { setAuth } from "./store/slice/authSlice";
import { useAuthChannel } from "./hooks/useAuthChannel";
import AllRoutes from "./components/AllRoutes/AllRoutes";

function App() {
  const dispatch = useDispatch();

  // broadcast logout when change login from another tab in same browser
  const { broadcastLogout } = useAuthChannel();

  //redux
  const accessToken = useSelector((state) => state.authSlice.accessToken);
  const location = useLocation();

  const { exp } = useMemo(() => {
    if (accessToken) {
      return jwtDecode(accessToken);
    } else {
      return 0;
    }
  }, [accessToken]);

  useLayoutEffect(() => {
    let logoutTime = exp * 1000;
    if (!exp || Date.now() >= logoutTime) {
      dispatch(setAuth(null));
      return;
    }
  });

  useEffect(() => {
    function adjustTopProperty(e, isZero) {
      const stickyHeader = document.querySelector(".sticky-main-header");
      if (stickyHeader) {
        const headerHeight = stickyHeader.offsetHeight;

        const targetElements = document.querySelectorAll(
          ".ant-table-sticky-holder"
        );
        targetElements.forEach((targetElement) => {
          targetElement.style.top = `${
            isZero || document?.fullscreenElement ? 0 : headerHeight
          }px`;
        });
      }
    }
    window.addEventListener("load", adjustTopProperty);
    window.addEventListener("resize", adjustTopProperty);
    adjustTopProperty();
    document.addEventListener("fullscreenchange", (e) => {
      document?.fullscreenElement
        ? adjustTopProperty(e, true)
        : adjustTopProperty(e, false);
    });

    return () => {
      window.removeEventListener("load", adjustTopProperty);
      window.removeEventListener("resize", adjustTopProperty);
      document.removeEventListener("fullscreenchange", adjustTopProperty);
    };
  }, [location]);

  return (
    <SocketProvider>
      <AppStyle.Wrapper>
        <AppStyle.CSSReset />
        <RootCommonFunction />
        {accessToken ? (
          <div className="sticky-main-header">
            <Header />
            <Navbar />
            <ScrollBar />
          </div>
        ) : null}
        <AppStyle.Main $fullHeight={!accessToken}>
          <ScrollToTopButton />
          <AllRoutes />
        </AppStyle.Main>
      </AppStyle.Wrapper>
    </SocketProvider>
  );
}

export default App;
