import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {},
  flag: false,
};

const chatSocketSlice = createSlice({
  name: "chatSocketSlice",
  initialState,
  reducers: {
    getChatData: (state, action) => {
      state.data = action.payload;
    },
    setChatSocketStatus: (state, action) => {
      state.flag = action.payload;
    },
  },
});

export const { getChatData } = chatSocketSlice.actions;

export default chatSocketSlice.reducer;
