import React from "react";
import ThemeCard from "../../components/ThemeCard";
import { Col, Pagination, Row, Tooltip, notification } from "antd";
import ThemeDropDown from "../../components/ThemeDropDown";
import { options } from "../../Helper/constant";
import ThemeCardTable from "../../components/ThemeCardTable";
import { useDispatch, useSelector } from "react-redux";
import { useMutation, useQuery } from "@tanstack/react-query";
import APICall from "../../APICall";
import EndPoints from "../../APICall/EndPoints";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import UserFilterComponant from "../../components/UserFilterComponant/UserFilterComponant";
import { setUserClearFilter } from "../../store/slice/globleSlice";
import MarketScriptList from "../../components/MarketScriptList/MarketScriptList";
import { saveAs } from "file-saver";
import {
  formatNumber,
  formatToINR,
} from "../../components/FormateToINR/formatToINR";
import GetDate from "../../components/DatePicker/GetDate";
import {
  ActionColumn,
  ActiveDot,
  buttonTitles,
  CSVColumns,
  tradefilterColumns,
} from "./style";
import dayjs from "dayjs";
import useGetQueryParams from "../../hooks/useGetQueryParams";
import EditTrade from "../../components/EditTrade/EditTrade";
import VerificationModal from "../../components/VerificationModal/VerificationModal";
import {
  readableString,
  valanBillDate,
} from "../../components/CommanFuction/CommanFunction";
import { PaginationRow, StyledButton } from "../../styles/commonStyle";
import { DeleteIcon, EditIcon } from "../Dashboard/style";
import { SubmitButton } from "../AccountPointEntry/style";
import ValanComponant from "../../components/ValanComponant/ValanComponant";

const convertToCSV = (data, TradeColumns) => {
  const header = TradeColumns?.map((col) => col.title).join(",");
  const rows = data?.map((row, index) => {
    return TradeColumns?.map((col) => {
      let value;
      if (col.dataIndex === "srNo") {
        value = index + 1; // Handle SR NO. separately as it is index based
      } else {
        value = row[col.dataIndex];
        if (col.dataIndex === "customer") {
          value = `${row?.customer?.[0]?.userId} (${row?.customer?.[0]?.accountName})`;
        } else if (col.dataIndex === "valan") {
          value = value?.valanName || "";
        } else {
          value = value !== null && value !== undefined ? value : "";
        }
      }
      return value;
    }).join(",");
  });
  return [header, ...rows].join("\n");
};

function TradingTrade() {
  // state
  const [value, setValue] = useState({ title: null });
  const [showModal, setShowModal] = useState({
    flag: false,
    name: null,
    record: null,
  });
  const [totalCounts, setTotalCounts] = useState(0);
  const [selectedRow, setSelectedRow] = useState([]);

  const valueRef = useRef({});
  const dispatch = useDispatch();
  const { params, setSearchParams } = useGetQueryParams();

  //redux
  const LoginDetails = useSelector((state) => state.authSlice?.userDetail);
  const additional = useSelector(
    (state) => state?.authSlice?.additionalSharing
  );
  const filterUser = useSelector((state) => state?.globleSlice?.filterUsers);
  const checkList = useSelector(
    (state) => state?.globleSlice?.tradingTradeColumn
  );
  const loginUserId = LoginDetails?.userId;
  const loginAccountType = LoginDetails?.accountType;
  const isOwner = loginAccountType === "owner";
  const isAllow =
    loginAccountType !== "customer" && loginAccountType !== "broker";

  //life cycle
  useEffect(() => {
    setSearchParams({
      ...params,
      userId: params?.userId ? params?.userId : loginUserId.toString(),
    });

    if (params?.title) {
      setValue({ title: params.title });
    }
  }, []);
  useEffect(() => {
    if (Object.keys(params)?.length == 0) {
      setSearchParams({ userId: loginUserId });
    }
  }, [params]);

  //api call
  const getTradingTrade = async () => {
    let isAllow = filterUser?.includes(Number(params?.userId));
    if (!isAllow) {
      notification.error({ message: "User Id is not valid" });
      return [];
    }

    let payloadObj = {
      userId: Number(params?.userId),
      title: params.title || null,
      marketName: params?.marketName,
      symbolName: params?.symbolName,
      tradeStatus: params?.tradeStatus || null,
      startDate: params?.startDate || null,
      endDate: params?.endDate || null,
    };

    if (params?.valanId) {
      payloadObj.valanId = params.valanId;
    }

    let response = await APICall(
      "post",
      `${EndPoints.getTrade}?page=${params.page || 1}&limit=${
        params?.limit || 10
      }`,
      payloadObj
    );
    // console.log("trades", response?.data?.data);
    setTotalCounts(response?.data?.data?.totalCount);
    return response?.data?.data;
  };

  const {
    isLoading,
    isFetching,
    data: tradeData,
    error: tradeDataError,
    refetch,
  } = useQuery({
    queryKey: ["tradingTrade", params],
    queryFn: getTradingTrade,
    enabled: Object.keys(params)?.length > 0,
  });

  if (tradeDataError) notification.error({ message: tradeDataError?.message });

  const getAllData = async () => {
    let isAllow = filterUser?.includes(Number(params?.userId));
    if (!isAllow) {
      notification.error({ message: "User Id is not valid" });
      return [];
    }

    let payloadObj = {
      userId: Number(params?.userId),
      title: params.title || null,
      marketName: params?.marketName,
      symbolName: params?.symbolName,
      tradeStatus: params?.tradeStatus || null,
      startDate: params?.startDate || null,
      endDate: params?.endDate || null,
    };
    let response = await APICall("post", `${EndPoints.getTrade}`, payloadObj);
    return response;
  };

  const {
    isLoading: isLoadingCSV,
    data: allTradeData,
    mutate,
  } = useMutation({
    mutationFn: getAllData,
    onSuccess: (res) => {
      if (res.status === 200) {
        let allTradeData = res?.data?.data?.trades;
        let csvData = allTradeData?.map((el) => {
          let date = el?.tradeTime;
          if (date) {
            date = dayjs(el?.tradeTime)?.format("YYYY-MM-DD");
          }
          return {
            ...el,
            tradeTime: date,
            time: dayjs(el?.tradeTime)?.format("HH:mm:ss"),
            userId: el?.customer?.[0]?.userId,
            accountName: el?.customer?.[0]?.accountName,
            tradePattern: el?.tradePattern === "buy" ? "B" : "S",
          };
        });
        // const csvColumn = CSVColumns?.filter((item) =>
        //   checkList?.includes(item?.key)
        // );
        const csv = convertToCSV(csvData, CSVColumns);
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        saveAs(blob, "trades.csv");
      } else {
        notification.error({ message: res.data.message });
      }
    },
    onError: (error) => {
      notification.error({ message: error?.message });
    },
  });

  // function
  const findTrade = async () => {
    let obj = {
      ...value,
      userId: valueRef.current?.userId || loginUserId,
      ...valueRef.current?.value,
      startDate: valueRef?.current?.startDate
        ? valueRef?.current?.startDate?.toISOString()
        : null,
      endDate: valueRef?.current?.endDate
        ? valueRef.current?.endDate?.toISOString()
        : null,
      valanId: valueRef.current?.valan || null,
      page: 1,
    };
    if (params?.limit) {
      obj.limit = params?.limit;
    }

    let setParam = Object.keys(obj)?.reduce((acc, curr) => {
      if (obj[curr]) {
        acc[curr] = obj[curr];
      } else {
        return acc;
      }
      return acc;
    }, {});

    setSearchParams({ ...setParam });
  };

  const handleCancel = () => {
    setShowModal({ name: null, flag: false, record: null });
  };

  const handleEdit = (record, key) => {
    setShowModal({ name: "edit", flag: true, record: { ...record, key } });
  };

  const handleNext = (newPage) => {
    setSearchParams({ ...params, page: newPage?.toString() });
  };

  const handleClear = () => {
    valueRef.current = {};
    dispatch(setUserClearFilter(true));
    setValue({ title: null });
    setSearchParams({ userId: loginUserId });
  };

  const onChangeDropDown = (name, selectedValue, selectedOptions) => {
    setValue({ ...value, [name]: selectedValue?.[0] });
  };

  const handleExport = () => {
    mutate();
  };

  const handleMultipleDelete = () => {
    setShowModal({
      name: "delete",
      flag: true,
      record: selectedRow,
      type: "multiple",
    });
  };

  //column
  const columns = [
    {
      title: "DEVICE",
      dataIndex: "deviceType",
      key: "deviceType",
      width: "5.5rem",
      align: "left",
      hidden: !checkList?.includes("deviceType"),
      render: (text, record) => {
        return (
          <Tooltip title={`${record?.marketStatus} trade`}>
            <div style={{ display: "flex", gap: "5px" }}>
              <div>{record?.deviceType}</div>
              <ActiveDot status={record?.marketStatus}></ActiveDot>
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: "TRADE BY",
      dataIndex: "tradeBy",
      width: "8rem",
      key: "tradeBy",
      align: "left",
      hidden: !checkList?.includes("tradeBy"),
      render: (text, record) => {
        const isSame =
          record?.tradeBy?.[0]?.userId === record?.customer?.[0]?.userId;
        return (
          !isLoading && (
            <div style={{ color: !isSame ? "red" : "black" }}>
              {`${record?.tradeBy[0]?.userId} (${record?.tradeBy[0]?.accountName})`}
            </div>
          )
        );
      },
    },
    {
      title: "CLIENT INFO",
      dataIndex: "name",
      key: "name",
      align: "left",
      width: "10rem",
      hidden: !checkList?.includes("name"),
      render: (text, record) => {
        return (
          !isLoading && (
            <div>{`${record?.customer[0]?.userId} (${record?.customer[0]?.accountName})`}</div>
          )
        );
      },
    },
    {
      title: "Trade Id",
      dataIndex: "tradeNumber",
      key: "tradeNumber",
      width: "5rem",
      align: "left",
      hidden: !checkList?.includes("tradeNumber"),
      render: (text, record) => {
        return (
          !isLoading && (
            <div>{record?.tradeNumber ? record?.tradeNumber : "-"}</div>
          )
        );
      },
    },
    {
      title: "MARKET",
      dataIndex: "marketName",
      key: "marketName",
      width: "6rem",
      align: "left",
      hidden: !checkList?.includes("marketName"),
    },
    {
      title: "SCRIPT",
      dataIndex: "symbolName",
      key: "symbolName",
      align: "left",
      width: "12rem",
      hidden: !checkList?.includes("symbolName"),
      render: (text, record) => {
        const date = record?.expiryDate ? record?.expiryDate : "";
        // const date = valanBillDate(record?.expiryDate, false);
        const opt = record?.marketName?.endsWith("OPT");
        const name = opt
          ? `${record?.symbolName} ${record?.series ? record?.series : ""} ${
              record?.strikePrice ? record?.strikePrice : ""
            }`
          : record?.symbolName;
        return (
          !isLoading && (
            <div>
              {name} {date}
            </div>
          )
        );
      },
    },
    {
      title: "ORDER TYPE",
      dataIndex: "title",
      key: "title",
      align: "left",
      width: "6.6rem",
      hidden: !checkList?.includes("title"),
      render: (text, record) => {
        return <span>{readableString(record?.title)}</span>;
      },
    },
    {
      title: "B/S",
      dataIndex: "tradePattern",
      key: "tradePattern",
      width: "4rem",
      align: "left",
      hidden: !checkList?.includes("tradePattern"),
    },
    {
      title: "QTY (LOT)",
      dataIndex: "lot",
      key: "lot",
      align: "left",
      width: "6rem",
      hidden: !checkList?.includes("lot"),
      render: (text, record) => {
        const lot = formatNumber(record?.lot);
        const quantity = formatNumber(record?.quantity);
        const data =
          record?.marketName === "NSE EQU" ? quantity : `${quantity} (${lot})`;
        return !isLoading && <div>{data}</div>;
      },
    },
    {
      title: "ORDER RATE",
      dataIndex: "tradePrice",
      key: "tradePrice",
      align: "left",
      width: "7rem",
      hidden: !checkList?.includes("tradePrice"),
      render: (text, record) => {
        return (
          !isLoading && (
            <div>{formatToINR(record?.tradePrice, record?.marketName)}</div>
          )
        );
      },
    },
    {
      title: "NET RATE",
      dataIndex: "netRate",
      key: "netRate",
      width: "7rem",
      align: "left",
      hidden: !checkList?.includes("netRate"),
      render: (text, record) => {
        return (
          !isLoading && (
            <div>{formatToINR(record?.netRate, record?.marketName)}</div>
          )
        );
      },
    },
    {
      title: "STATUS",
      dataIndex: "tradeStatus",
      key: "tradeStatus",
      width: "7rem",
      align: "left",
      hidden: !checkList?.includes("tradeStatus"),
    },
    {
      title: "ORDER-TIME",
      dataIndex: "orderTime",
      key: "orderTime",
      width: "7rem",
      align: "left",
      hidden: !checkList?.includes("orderTime"),
      render: (text, record) => {
        const finalDate = valanBillDate(record?.tradeTime, true);
        return !isLoading && <div>{finalDate ? finalDate : "-"}</div>;
      },
    },
    {
      title: "TRADE-TIME",
      dataIndex: "executeTime",
      key: "executeTime",
      width: "7rem",
      align: "left",
      hidden: !checkList?.includes("executeTime"),
      render: (text, record) => {
        const finalDate = valanBillDate(record?.executeTime, true);
        return !isLoading && <div>{finalDate ? finalDate : "-"}</div>;
      },
    },
    {
      title: "LTP",
      dataIndex: "stoploss",
      key: "stoploss",
      width: "6rem",
      align: "left",
      hidden: !checkList?.includes("stoploss"),
      render: (text, record) => {
        return !isLoading && <div>{record?.stoploss ?? "-"}</div>;
      },
    },
    {
      title: "LotSize",
      dataIndex: "lotSize",
      key: "lotSize",
      width: "6rem",
      align: "left",
      hidden: !checkList?.includes("lotSize"),
      render: (text, record) => {
        return !isLoading && <div>{record?.lotSize ?? "-"}</div>;
      },
    },
    {
      title: "High",
      dataIndex: "high",
      key: "high",
      width: "6rem",
      align: "left",
      hidden: !checkList?.includes("high"),
      render: (text, record) => {
        return !isLoading && <div>{record?.high ?? "-"}</div>;
      },
    },
    {
      title: "Low",
      dataIndex: "low",
      key: "low",
      width: "6rem",
      align: "left",
      hidden: !checkList?.includes("low"),
      render: (text, record) => {
        return !isLoading && <div>{record?.low ?? "-"}</div>;
      },
    },
    {
      title: "IP",
      dataIndex: "tradeIpAddress",
      key: "tradeIpAddress",
      width: "10rem",
      align: "left",
      hidden: !checkList?.includes("tradeIpAddress"),
      render: (text, record) => {
        let title = record?.getDeviceInfo
          ? locationInfo(record?.getDeviceInfo)
          : "";
        return (
          !isLoading && (
            <Tooltip title={title}>
              <div>{record?.tradeIpAddress}</div>
            </Tooltip>
          )
        );
      },
    },
    {
      title: "MODIFY",
      dataIndex: "modify",
      key: "modify",
      width: "5rem",
      align: "center",
      hidden: !checkList?.includes("modify"),
      render: (text, record) => {
        return (
          !isLoading && (
            <div style={{ color: record?.modified ? "red" : "black" }}>
              {record?.modified ? "modified" : "-"}
            </div>
          )
        );
      },
    },
    isAllow && (additional?.editTrade || additional?.tradeDelete)
      ? {
          title: "ACTION",
          dataIndex: "action",
          key: "action",
          width: "5rem",
          align: "center",
          hidden: !checkList?.includes("action") || selectedRow?.length !== 0,
          render: (text, record, index) => {
            return (
              !isLoading && (
                <ActionColumn>
                  {(additional?.editTrade &&
                    record?.deviceType !== "system" &&
                    record?.tradeStatus !== "pending") ||
                  isOwner ? (
                    <EditIcon
                      onClick={() => handleEdit(record, "tradingTrade")}
                    />
                  ) : (
                    "-"
                  )}
                  {additional?.tradeDelete &&
                    (record?.deviceType !== "system" || isOwner ? (
                      <DeleteIcon
                        onClick={() =>
                          setShowModal({
                            name: "delete",
                            flag: true,
                            record: record,
                          })
                        }
                      />
                    ) : (
                      "-"
                    ))}
                </ActionColumn>
              )
            );
          },
        }
      : {
          width: "0rem",
        },
  ];

  return (
    <>
      <ThemeCard>
        <Row gutter={[10, 15]}>
          <GetDate valueRef={valueRef} />
          <MarketScriptList valueRef={valueRef} />
          {isOwner && <ValanComponant valueRef={valueRef} />}
          <Col xs={24} sm={12} md={8} lg={6} xl={4}>
            <ThemeDropDown
              title={"Order Type"}
              placeholder="Order Type"
              name="title"
              width={"98%"}
              showSearch={true}
              options={options.trading.orderType}
              $onChange={onChangeDropDown}
              $value={value?.title}
            />
          </Col>
          {loginAccountType !== "customer" && (
            <UserFilterComponant valueRef={valueRef} />
          )}
        </Row>

        <Row style={{ marginTop: "2rem" }} gutter={[10, 10]}>
          <Col xs={24} sm={12} md={4} lg={3} xl={2}>
            <StyledButton
              type="primary"
              // loading={isLoading}
              onClick={findTrade}
            >
              Search
            </StyledButton>
          </Col>
          <Col xs={24} sm={12} md={4} lg={3} xl={2}>
            <StyledButton type="primary" onClick={handleClear}>
              Clear Search
            </StyledButton>
          </Col>
          <Col xs={24} sm={12} md={4} lg={3} xl={2}>
            <StyledButton
              type="primary"
              onClick={handleExport}
              loading={isLoadingCSV}
            >
              Export
            </StyledButton>
          </Col>
          {selectedRow?.length !== 0 && (
            <Col xs={24} sm={12} md={4} lg={3} xl={2}>
              <SubmitButton remove={"true"} onClick={handleMultipleDelete}>
                Delete Trade ({`${selectedRow?.length}`})
              </SubmitButton>
            </Col>
          )}
        </Row>
      </ThemeCard>

      <ThemeCardTable
        buttonType={true}
        buttonTitle={buttonTitles}
        data={tradeData?.trades || []}
        column={columns}
        headingStyle={false}
        isFilterTrue={true}
        isLoading={isLoading}
        filtertype="trade"
        isFilter={true}
        checkList={checkList}
        columnFilter={tradefilterColumns}
        setSelectedRow={setSelectedRow}
        selectedRow={selectedRow}
        multipleSelect={isAllow && additional?.tradeDelete ? true : false}
      />
      <PaginationRow>
        <Pagination
          current={Number(params.page) || 1}
          pageSize={params?.limit ? params?.limit : 10}
          showSizeChanger={false}
          total={totalCounts}
          onChange={handleNext}
          queryKey="tradingTrade"
          style={{ textAlign: "center" }}
        />
      </PaginationRow>
      {showModal?.flag && showModal?.name === "edit" && (
        <EditTrade
          handleCancel={handleCancel}
          record={showModal.record}
          queryKey={["tradingTrade", params]}
        />
      )}
      {showModal?.flag && showModal?.name === "delete" && (
        <VerificationModal
          handleCancel={handleCancel}
          record={showModal.record}
          query={["tradingTrade", params]}
          type="delete"
          title={"Delete Trade"}
          setSelectedRow={setSelectedRow}
          multiple={showModal?.type === "multiple"}
          customTitle={
            showModal?.type === "multiple" ? "Delete Selected Trades" : null
          }
          customeDescription={
            showModal?.type === "multiple"
              ? `Are you sure, you want to delete ${selectedRow?.length} selected trades?`
              : null
          }
        />
      )}
    </>
  );
}

export default TradingTrade;

const locationInfo = (data) => {
  const location = data?.location;

  return (
    <div>
      <p>{location?.city ? `City: ${location?.city}(${location?.zip})` : ""}</p>
      <p>{location?.regionName ? `State: ${location?.regionName}` : ""}</p>
      <p>{location?.lat ? `Latitude: ${location?.lat}` : ""}</p>
      <p>{location?.lon ? `Longitude: ${location?.lon}` : ""}</p>
      <p>{data?.deviceName ? `Device Name: ${data?.deviceName}` : ""}</p>
      <p>{data?.deviceType ? `Device Type: ${data?.deviceType}` : ""}</p>
      <p>{data?.browser ? `Browser Name: ${data?.browser}` : ""}</p>
      <p>{data?.osVersion ? `osVersion: ${data?.osVersion}` : ""}</p>
    </div>
  );
};
