import { StyleSheet } from "@react-pdf/renderer";
import { Button, Col, Row, Table } from "antd";
import styled from "styled-components";
import {
  findMarket,
  formatNumber,
  formatToINR,
  formatToINRForSummay,
} from "../FormateToINR/formatToINR";
import { readableString, valanBillDate } from "../CommanFuction/CommanFunction";
import { SettingOutlined } from "@ant-design/icons";
import { limitNumberLength } from "../SocketLiveData";

export const StyledSummarySearchInput = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  .ant-form-item {
    margin-bottom: unset !important;
  }
`;
export const ThemeTableWrapper = styled.div`
  margin-top: 1rem;
  /* margin-bottom: 1rem; */
  border-radius: 10px;
  background: "fff";
  padding-top: 1rem;
`;
export const TableTd = styled.td`
  font-size: 0.9rem;
`;
export const StyledDiv = styled.div`
  /* width: 50%; */
  font-size: 1.2rem;
  font-weight: 600;
  color: #727880;
  margin-right: 1rem;
  @media (max-width: 1079px) {
    font-size: 0.9rem;
  }
  @media (max-width: 450px) {
    margin-right: 0.3rem;
    font-size: 0.8rem;
  }
`;
export const FileTypeButton = styled(Button)`
  border-radius: ${(props) => props.theme.token.borderRadiusLG};
  font-size: 0.9rem;
  width: 100%;
  box-shadow: ${(props) => props.theme.token.boxShadowNone};
  height: 3rem;
  @media (max-width: 1079px) {
    font-size: 0.8rem;
    height: 2.5rem;
  }
  @media (max-width: 767px) {
    font-size: 0.7rem;
    height: 2.5rem;
  }
`;
export const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#fff",
    padding: 20,
  },
  row: {
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    borderBottomStyle: "solid",
    borderLeftWidth: 1,
    borderLeftColor: "#000",
  },
  tableCell: {
    flex: 1,
    paddingVertical: 5,
    paddingHorizontal: 3,
    borderRightWidth: 1,
    borderRightColor: "#000",
    borderRightStyle: "solid",
    fontSize: 12,
    textAlign: "center",
  },
  scriptHeader: {
    backgroundColor: "#0072bc",
    color: "white",
    fontSize: 12,
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
    textAlign: "center",
    flexDirection: "row",
  },
  userInfoCell: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tag: {
    backgroundColor: "#800080",
    color: "#fff",
    paddingHorizontal: 4,
    paddingVertical: 2,
    borderRadius: 4,
    fontSize: 10,
  },
  pdfLink: {
    color: "#0000FF",
    textDecoration: "underline",
  },
  text: {
    display: "flex",
    alignItems: "center",
    marginBottom: 5,
  },
  headerRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    borderLeftWidth: 1,
    borderLeftColor: "#000",
    backgroundColor: "#0072bc",
  },
  subHeaderRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    borderLeftWidth: 1,
    borderLeftColor: "#000",
    backgroundColor: "#0072bc",
  },
  brokerGroup: {
    flex: 2,
    borderRightWidth: 1,
    borderRightColor: "#000",
  },
  brokerHeader: {
    color: "white",
    textAlign: "center",
    paddingVertical: 5,
    borderBottomWidth: 1,
    borderBottomColor: "#000",
  },
  brokerSubHeader: {
    flexDirection: "row",
    borderTopWidth: 1,
    borderTopColor: "#000",
  },
  brokerSubHeaderCell: {
    flex: 1,
    color: "white",
    textAlign: "center",
    paddingVertical: 5,
    borderRightWidth: 1,
    borderRightColor: "#000",
    fontSize: 12,
  },
  text: {
    marginBottom: 10,
    fontSize: 16,
    textAlign: "center",
  },
});
export const StyledRow = styled(Row)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 1rem;
  display: flex;

  @media (max-width: 1200px) {
    justify-content: left;
  }
`;
export const StyTable = styled(Table)`
  transform: none !important;
  & .ant-table-thead tr .ant-table-cell {
    background-color: ${(props) => props.theme.token.colorPrimary};
    color: ${(props) => props.theme.token.colorTextLightSolid};
    font-size: 0.9rem;
    font-weight: 500;
  }
  @media screen and (max-width: 1079px) {
    .ant-table-thead tr .ant-table-cell {
      font-size: 0.75rem;
    }
  }
  .ant-table-thead tr .ant-table-cell::before {
    display: none;
  }

  .ant-table-thead tr .ant-table-cell .ant-table-column-has-sorters {
    border-top: ${(props) =>
      `1px solid ${props.theme.token.colorBorder} !important`};
  }
  .ant-table-body .ant-table-cell {
    border-bottom: 0px;
  }

  .ant-table-wrapper .ant-table-tbody > tr > td {
    padding: 0.5rem !important;
  }
  .ant-table-thead tr th {
    height: 2rem !important;
    padding: 0rem 0.5rem !important;
  }
  .collapseTable .ant-collapse-expand-icon span svg {
    fill: ${(props) => props.theme.token.colorTextLightSolid} !important;
  }
  .collapseTable .ant-collapse-header-text {
    color: ${(props) => props.theme.token.colorTextLightSolid};
  }

  .ant-switch-checked .ant-switch-inner {
    background-color: ${(props) =>
      props.theme.token.colorBgActionButton} !important;
  }

  .ant-table-thead {
    height: 3.18rem;
  }

  .ant-table-thead .ant-table-cell {
    &:hover {
      background: ${(props) =>
        props.theme.token.colorPrimaryBgHover} !important;
    }
  }
  .ant-table-body .ant-table-cell {
    padding: 0.5rem !important;
  }

  .ant-table-tbody .ant-table-row:hover {
    background: #f5f5f5;
  }

  .delete-row {
    /* background-color: #ffcccb;  */
    td:last-child {
      border-right: 5px solid #ffb3b3 !important;
    }
    &:hover > td {
      background-color: #ffb3b3 !important; /* Darker red on hover */
    }
  }

  .update-row {
    td:last-child {
      border-right: 5px solid #ffd700 !important;
    }
    &:hover > td {
      background-color: #fff5b1 !important;
    }
  }

  .create-row {
    /* background-color: #90ee90; Light green for create rows */
    td:last-child {
      border-right: 5px solid #90ee90 !important;
    }
    &:hover > td {
      background-color: #90ee90 !important;
    }
  }

  .incognito-row {
    background-color: #bdb5b5 !important;
    &:hover > td {
      background-color: #bdb5b5 !important;
    }
  }
`;
export const FooterRow = styled.tr`
  background-color: #edecfa;
  /* height: 5px; */
  width: 100%;
  line-height: 0.2rem;
  font-weight: 500;
  /* font-size: small; */
  text-align: center;
  /* border-bottom-left-radius: 10px; */
`;
export const TableTitleCol = styled(Col)`
  display: flex;
  align-items: center;
`;
export const TableTitleSearch = styled(Col)`
  margin-left: ${({ filtertype }) =>
    filtertype === "trade" ||
    filtertype === "summary" ||
    filtertype === "broker" ||
    filtertype === "brokerage" ||
    filtertype === "ledger"
      ? "0"
      : "auto"};
`;
export const ColumnSettingIcon = styled(SettingOutlined)`
  margin-right: 0.5rem;
  cursor: pointer;
  font-size: large;
`;
export const itemOption = [
  { label: 10, value: 10 },
  { label: 20, value: 20 },
  { label: 30, value: 30 },
];
export const Summaycolumns = [
  {
    title: "SR NO.",
    dataIndex: "srNo",
    key: "srNo",
    align: "center",
    width: "4rem",
    render: (text, record, index) => index + 1,
  },
  {
    title: "USER INFO",
    dataIndex: "userId",
    key: "userId",
    align: "center",
    render: (text, record) => {
      if (record?.userId === "Total") {
        return record?.userId;
      } else {
        return `${record?.userId} (${record?.AccountName})`;
      }
    },
  },
  {
    title: "VALAN",
    dataIndex: "valan",
    key: "valan",
    align: "center",
    render: (text, record) => record?.valan?.valanName,
  },
  {
    title: "TOTAL VALAN BILL",
    dataIndex: "totalNetAmount",
    key: "totalNetAmount",
    align: "center",
    render: (text, record) => formatToINRForSummay(record?.totalNetAmount),
  },
  {
    title: "WITHOUT BROKERAGE",
    dataIndex: "withoutBrokerage",
    key: "withoutBrokerage",
    align: "center",
    render: (text, record) => formatToINRForSummay(record?.withoutBrokerage),
  },
  {
    title: "BROKERAGE",
    dataIndex: "brokerage",
    key: "brokerage",
    align: "center",
    render: (text, record) => formatToINRForSummay(record?.brokerage),
  },
  {
    title: "DOWNLINE BILL",
    dataIndex: "downlinem2m",
    key: "downlinem2m",
    align: "center",
    render: (text, record) => formatToINRForSummay(record?.downlinem2m),
  },
  {
    title: "UPLINE BILL",
    dataIndex: "uplinem2m",
    key: "uplinem2m",
    align: "center",
    render: (text, record) => formatToINRForSummay(record?.uplinem2m),
  },
  {
    title: "SELF BILL",
    dataIndex: "selfm2m",
    key: "selfm2m",
    align: "center",
    render: (text, record) => formatToINRForSummay(record?.selfm2m),
  },
];
export const BrokerSharingPdfColumn = [
  {
    title: "SR NO.",
    dataIndex: "srNo",
    key: "srNo",
    align: "center",
    width: "4rem",
    render: (text, record, index) => {
      return index + 1;
    },
  },
  {
    title: "USER INFO",
    dataIndex: "userId",
    key: "userId",
    align: "center",
    width: "16rem",
    render: (text, record) => `${record?.userId} (${record?.accountName})`,
  },
  {
    title: "VALAN",
    dataIndex: "valan",
    key: "valan",
    align: "center",
    width: "10rem",
    render: (text, record) => `${record?.valanId?.valanName}`,
  },
  {
    title: "TOTAL VALAN BILL",
    dataIndex: "totalValanBill",
    key: "totalValanBill",
    align: "center",
    width: "16rem",
    render: (text, record) => formatToINR(record?.totalValanBill),
  },
  {
    title: "BROKERAGE",
    dataIndex: "brokerage",
    key: "brokerage",
    align: "center",
    width: "12rem",
    render: (text, record) => formatToINR(record?.brokerage || 0),
  },
  {
    title: "broker1Name",
    dataIndex: "broker1Name",
    align: "center",
    key: "date",
    width: "6rem",
    render: (text, record) => {
      let broker1 = record?.brokerData?.[0];
      if (broker1) {
        return `${broker1?.accountName}`;
      } else {
        return "-";
      }
    },
  },
  {
    title: "broker1%(Sharing)",
    dataIndex: "broker1Pr",
    align: "center",
    key: "time",
    width: "6rem",
    render: (text, record) => {
      let broker1 = record?.brokerData?.[0];
      if (broker1) {
        return `${broker1?.percentage}`;
      } else {
        return "-";
      }
    },
  },
  {
    title: "broker1BR(Sharing)",
    dataIndex: "broker1Br",
    align: "center",
    key: "id",
    width: "6rem",
    render: (text, record) => {
      let broker1 = record?.brokerData?.[0];
      if (broker1) {
        return `${broker1?.brokerBrokerage}`;
      } else {
        return "-";
      }
    },
  },
  {
    title: "broker2Name",
    dataIndex: "broker2Name",
    align: "center",
    key: "date",
    width: "6rem",
    render: (text, record) => {
      let broker2 = record?.brokerData?.[1];
      if (broker2) {
        return `${broker2?.accountName}`;
      } else {
        return "-";
      }
    },
  },
  {
    title: "broker2%(Sharing)",
    dataIndex: "broker2Pr",
    align: "center",
    key: "time",
    width: "6rem",
    render: (text, record) => {
      let broker2 = record?.brokerData?.[1];
      if (broker2) {
        return `${broker2?.percentage}`;
      } else {
        return "-";
      }
    },
  },
  {
    title: "broker2BR(Sharing)",
    dataIndex: "broker2Br",
    align: "center",
    key: "id",
    width: "6rem",
    render: (text, record) => {
      let broker2 = record?.brokerData?.[1];
      if (broker2) {
        return `${broker2?.brokerBrokerage}`;
      } else {
        return "-";
      }
    },
  },
  {
    title: "broker3Name",
    dataIndex: "broker3Name",
    align: "center",
    key: "date",
    width: "6rem",
    render: (text, record) => {
      let broker3 = record?.brokerData?.[2];
      if (broker3) {
        return `${broker3?.accountName}`;
      } else {
        return "-";
      }
    },
  },
  {
    title: "broker3%(Sharing)",
    dataIndex: "broker3Pr",
    align: "center",
    key: "time",
    width: "6rem",
    render: (text, record) => {
      let broker3 = record?.brokerData?.[2];
      if (broker3) {
        return `${broker3?.percentage}`;
      } else {
        return "-";
      }
    },
  },
  {
    title: "broker3BR(Sharing)",
    dataIndex: "broker3Br",
    align: "center",
    key: "id",
    width: "6rem",
    render: (text, record) => {
      let broker3 = record?.brokerData?.[2];
      if (broker3) {
        return `${broker3?.brokerBrokerage}`;
      } else {
        return "-";
      }
    },
  },
];
export const LedgerColumns = [
  {
    title: "Sr No.",
    dataIndex: "srNo",
    key: "srNo",
    width: "5rem",
    align: "center",
    render: (text, record, index) => index + 1,
  },
  {
    title: "CLIENT INFO",
    dataIndex: "userId",
    key: "userId",
    align: "left",
    render: (text, record) => `${record?.userId} (${record?.accountName})`,
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
    align: "left",
    render: (text, record) => valanBillDate(record?.date, true),
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    align: "left",
  },
  {
    title: "Debit",
    dataIndex: "amount",
    key: "debit",
    align: "center",
    render: (text, record) =>
      record?.entryType === "debit" ? formatToINR(record?.amount) : "-",
  },
  {
    title: "Credit",
    dataIndex: "amount",
    key: "credit",
    align: "center",
    render: (text, record) =>
      record?.entryType === "credit" ? formatToINR(record?.amount) : "-",
  },
  {
    title: "Balance",
    dataIndex: "ledgerBalance",
    key: "ledgerBalance",
    align: "center",
    render: (text, record) => formatToINR(record?.ledgerBalance),
  },
  {
    title: "Remark",
    dataIndex: "remark",
    key: "remark",
    align: "left",
    render: (text, record) => record?.remark,
  },
];
export const BrokerageColumns = [
  {
    title: "TRADE DATE",
    dataIndex: "tradeTime",
    key: "tradeTime",
    align: "left",
    width: "8rem",
    render: (text, record) => valanBillDate(record?.tradeTime, true),
  },
  {
    title: "CLIENT",
    dataIndex: "client",
    key: "client",
    align: "left",
    width: "12rem",
    render: (text, record) => `${record?.client} (${record?.clientName})`,
  },
  {
    title: "SCRIPT",
    dataIndex: "script",
    key: "script",
    align: "left",
    width: "12rem",
    render: (text, record) => {
      const finalDate = valanBillDate(record?.expiryDate, false);
      const opt = record?.marketName?.endsWith("OPT");
      const name = opt
        ? `${record?.symbolName} ${record?.series ? record?.series : ""} ${
            record?.strikePrice ? record?.strikePrice : ""
          }`
        : record?.symbolName;
      return `${name} ${record?.expiryDate ? finalDate : ""}`;
    },
  },
  {
    title: "ORDER TYPE",
    dataIndex: "title",
    key: "title",
    align: "left",
    width: "6rem",
    render: (text, record) => readableString(record?.title),
  },
  {
    title: "B/S",
    dataIndex: "tradeType",
    key: "tradeType",
    align: "left",
    width: "6rem",
  },
  {
    title: "QTY (LOT)",
    dataIndex: "tradeLot",
    key: "tradeLot",
    align: "left",
    width: "9rem",
    render: (text, record) => {
      const lot = formatNumber(record?.tradeLot);
      const quantity = formatNumber(record?.tradeQty);
      const data =
        record?.marketName === "NSE EQU" ? quantity : `${quantity} (${lot})`;
      return data;
    },
  },
  {
    title: "PRICE",
    dataIndex: "tradePrice",
    key: "tradePrice",
    align: "left",
    width: "8rem",
    render: (text, record) => {
      const price = findMarket(record?.marketName)
        ? limitNumberLength(record?.tradePrice)
        : record?.tradePrice?.toFixed(2);
      return price;
    },
  },
  {
    title: "TOTAL BROKERAGE",
    dataIndex: "totalBrokerage",
    key: "totalBrokerage",
    align: "center",
    width: "8rem",
    render: (text, record) => formatToINR(record?.totalBrokerage),
  },
  {
    title: "MY BROKERAGE",
    dataIndex: "myBrokerage",
    key: "myBrokerage",
    align: "center",
    width: "8rem",
    render: (text, record) => formatToINR(record?.myBrokerage),
  },
  {
    title: "UPLINE BROKERAGE",
    dataIndex: "uplineBrokerage",
    key: "uplineBrokerage",
    align: "center",
    width: "8rem",
    render: (text, record) => formatToINR(record?.uplineBrokerage),
  },
  {
    title: "DOWNLINE BROKERAGE",
    dataIndex: "downlineBrokerage",
    key: "downlineBrokerage",
    align: "center",
    width: "8rem",
    render: (text, record) => formatToINR(record?.downlineBrokerage),
  },
];
