import { Button, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import ThemeInput from "../../../components/ThemeInput";
import { SearchOutlined } from "@ant-design/icons";

const UserPosition = ({ record }) => {
  // state
  const [buttonType, setButtonType] = useState("total");
  const [data, setData] = useState(
    record?.positions?.filter((el) => el?.type !== "close")
  );
  const [search, setSearch] = useState("");

  // life cycle
  useEffect(() => {
    let filteredData =
      record?.positions?.filter((el) => el?.type !== "close") || [];

    // First filter by type (buy/sell/total)
    if (buttonType === "sell") {
      filteredData = filteredData.filter((el) => el?.type === "sell");
    } else if (buttonType === "buy") {
      filteredData = filteredData.filter((el) => el?.type === "buy");
    }

    // Then apply search filter if there's a search term
    if (search) {
      const searchValue = search.toLowerCase();
      filteredData = filteredData.filter(
        (el) =>
          el?.user?.[0]?.accountName?.toLowerCase().includes(searchValue) ||
          el?.user?.[0]?.userId.toString()?.includes(searchValue)
      );
    }

    setData(filteredData);
  }, [buttonType, search, record?.positions]);

  //function
  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  return (
    <div>
      <Row gutter={[10, 10]} justify={"left"} style={{ marginBottom: "1rem" }}>
        <Col>
          <ThemeInput
            required
            inputProps={{
              placeholder: "Search anything here",
              prefix: <SearchOutlined />,
              onChange: handleSearch,
              value: search,
            }}
            marginBottom="0px"
          />
        </Col>
        <Col>
          <Button
            type={buttonType === "buy" ? "primary" : ""}
            onClick={() => setButtonType("buy")}
          >
            Buy
          </Button>
        </Col>
        <Col>
          <Button
            type={buttonType === "sell" ? "primary" : ""}
            onClick={() => setButtonType("sell")}
          >
            Sell
          </Button>
        </Col>
        <Col>
          <Button
            type={buttonType === "total" ? "primary" : ""}
            onClick={() => setButtonType("total")}
          >
            Total
          </Button>
        </Col>
      </Row>
      <Row gutter={[10, 12]} style={{ overflowX: "auto", width: "100%" }}>
        {data?.map((el, index) => {
          const isSell = el?.type === "sell" ? true : false;
          return (
            <Col sm={24} md={12} style={{ minWidth: "600px" }}>
              <Row gutter={[10, 10]} justify={"left"}>
                <Col sm={1} md={2}>
                  {index + 1}
                </Col>
                <Col sm={8} md={8}>
                  {el?.user?.[0]?.accountName}({el?.user?.[0]?.userId})
                </Col>
                <Col sm={4} md={6}>
                  {el?.type}
                </Col>
                <Col sm={8} md={6}>
                  {isSell
                    ? ` ${el?.totalQuantity}(${el?.lot?.toFixed(2)})`
                    : ` ${el?.totalQuantity}(${el?.lot?.toFixed(2)})`}
                </Col>
              </Row>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default React.memo(UserPosition);
