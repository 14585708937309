import React from "react";
import { UserOutlined } from "@ant-design/icons";
import {
  ChatUserIcon,
  ChatWrapperDiv,
  MessageBubbleDiv,
  MessageDiv,
  StatusDiv,
} from "./style";
import dayjs from "dayjs";

export const MessageStatus = {
  SENDING: "sending",
  SENT: "sent", // Single tick
  DELIVERED: "delivered", // Double tick
  READ: "seen", // Blue double tick
};

export const SingleTick = ({ color = "currentColor" }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 8L6 11L13 4"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const DoubleTick = ({ color = "currentColor", isRead = false }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 8L5 11L12 4"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 8L8 11L15 4"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const MessageStatusIndicator = ({ status }) => {
  switch (status) {
    case MessageStatus.SENDING:
      return <span style={{ fontSize: "12px", opacity: 0.5 }}>⌛</span>;
    case MessageStatus.SENT:
      return <SingleTick color="#999999" />;
    case MessageStatus.DELIVERED:
      return <DoubleTick color="#999999" />;
    case MessageStatus.READ:
      return <DoubleTick color="#2196F3" isRead={true} />;
    default:
      return null;
  }
};

export const ChatArea = ({ children }) => (
  <ChatWrapperDiv>{children}</ChatWrapperDiv>
);

export const MessageBubble = React.memo(({ data, isMe }) => {
  // console.log("data", data);
  return (
    <MessageBubbleDiv isMe={isMe} id={`message-${data?._id}`}>
      {!isMe && <ChatUserIcon size={32} icon={<UserOutlined />} />}
      <MessageDiv isMe={isMe}>
        <div>{data?.message}</div>
        <StatusDiv>
          <span>
            {dayjs(data?.timestamp).format("hh:mm A") || data?.timestamp}
          </span>
          {isMe && <MessageStatusIndicator status={data?.status} />}
        </StatusDiv>
      </MessageDiv>
      {!isMe && <div style={{ width: "16px" }}></div>}
    </MessageBubbleDiv>
  );
});
