import React, { useState, useEffect } from "react";
import { Col, Modal, Row, Skeleton, Tooltip, Tree } from "antd";
import { CapitalFirstLetter } from "../../../../components/CommanFuction/CommanFunction";
import { SrNoCol, UplineTag } from "../style";
import { useGetChildUsers } from "../../../../APICall/apiHooks";
import { labelObj } from "../../../../Helper/constant";

const label = (el, index) => {
  return (
    <Row gutter={[10, 10]}>
      <SrNoCol>{`${index + 1}) `}</SrNoCol>
      <Col>
        {`${el?.accountName} (${el?.userId})`}
        <Tooltip title={el?.accountType}>
          <UplineTag color="magenta" bordered={false}>
            {labelObj[el?.accountType]}
          </UplineTag>
        </Tooltip>
      </Col>
    </Row>
  );
};

const ChildListModal = ({
  userData,
  setShowModal,
  showModal,
  childLoading,
  id,
}) => {
  // Create unique keys by adding a path prefix
  const [treeData, setTreeData] = useState(
    showModal?.data?.value?.map((el, index) => ({
      title: label(el, index),
      key: `root-${el?.userId}`, // Make first level keys unique with a prefix
      id: el?.userId,
      type: el?.accountType,
      isLeaf: el?.accountType === "customer",
    }))
  );
  const [childId, setChildId] = useState(null);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [loadedKeys, setLoadedKeys] = useState([]);

  // Track the path to create unique keys
  const [nodePaths, setNodePaths] = useState({});

  // custom hook for child user api call
  const { childUsers, isLoading, userDetails } = useGetChildUsers({
    id: childId,
  });

  // life cycle
  useEffect(() => {
    if (childUsers && childId) {
      //   console.log("getchild", childUsers, userDetails);

      if (userDetails?.accountType === "customer") {
        return;
      }

      // Find the path for the parent node
      const parentPath = nodePaths[childId] || `root-${childId}`;

      const updateTreeData = (list, targetId, children) => {
        return list.map((node) => {
          if (node.id === targetId) {
            // Store paths for new children
            const newChildren = children.map((child, childIndex) => {
              // Create a unique path-based key
              const childKey = `${parentPath}-${child?.userId}`;

              // Store this path for future use
              setNodePaths((prev) => ({
                ...prev,
                [child?.userId]: childKey,
              }));

              return {
                title: label(child, childIndex),
                key: childKey, // Use path-based key
                id: child?.userId,
                type: child?.accountType,
                isLeaf: child?.accountType === "customer",
              };
            });

            if (newChildren.length === 0) {
              return {
                ...node,
                isLeaf: true,
              };
            }
            return {
              ...node,
              children: newChildren,
            };
          }

          if (node.children) {
            const updatedChildren = updateTreeData(
              node.children,
              targetId,
              children
            );
            if (updatedChildren !== node.children) {
              return { ...node, children: updatedChildren };
            }
          }

          return node;
        });
      };

      setTreeData((prevData) => {
        const newData = updateTreeData(prevData, childId, childUsers);
        return newData;
      });

      setLoadedKeys((prev) => [...prev.filter((k) => k !== childId), childId]);
    }
  }, [childUsers]);

  useEffect(() => {
    if (isLoading) {
      new Promise((resolve) => {
        if (!isLoading) resolve();
      });
    }
  }, [isLoading]);

  // function
  const onLoadData = (treeNode) => {
    return new Promise((resolve) => {
      const { id, type, key } = treeNode;

      if (type === "customer") {
        resolve();
        return;
      }

      if (loadedKeys.includes(id)) {
        resolve();
        return;
      }

      setChildId(id);

      if (!expandedKeys.includes(key)) {
        setExpandedKeys((prev) => [...prev, key]);
      }

      resolve();
    });
  };

  const onExpand = (expandedKeysValue) => {
    setExpandedKeys(expandedKeysValue);
  };

  return (
    <Modal
      title={`${userData?.user?.accountName} (${id}) > ${CapitalFirstLetter(
        showModal?.data?.key
      )} List`}
      open={showModal.flag}
      onCancel={() => setShowModal({ flag: false })}
      footer={null}
    >
      <Tree
        loadData={onLoadData}
        treeData={treeData}
        // expandedKeys={expandedKeys}
        // onExpand={onExpand}
      />
    </Modal>
  );
};

export default React.memo(ChildListModal);
