import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Col,
  notification,
  Popconfirm,
  Row,
  Skeleton,
  Spin,
  Table,
  theme,
  Tooltip,
} from "antd";
import dayjs from "dayjs";
import closeImage from "../../../assets/icons/closeIcon.svg";
import { setWatchListArray } from "../../../store/slice/marketWatchSlice";
import useDebounce from "../../../hooks/3-useDebounce/useDebounce";
import ModalView from "../TradeModal";
import APICall from "../../../APICall";
import EndPoints from "../../../APICall/EndPoints";
import {
  M2MCalculation,
  MemoizedCardItemPrice,
  MemorizedHighLowPrice,
  MemorizedOpenClose,
  MemorizedPercentageAero,
  MemorizedPriceChange,
} from "../../../components/SocketLiveData";
import store from "../../../store/store";
import { marketTag } from "../../../Helper/constant";
import { useSocket } from "../../../components/Socket";
import {
  ActiveScriptWrapper,
  CloseImage,
  ExpairyDateRow,
  MarketTableTag,
  RemoveAllDiv,
  WatchListName,
} from "../style";
import { StyledThemeSimpleTable as StyledTheme } from "../style";
import UserPosition from "../Components/UserPosition";
var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

const SimpleTable = React.memo((props) => {
  const { checkList } = props;
  let record = useRef();
  let modalType = useRef();
  const activeScriptRef = useRef({});
  const dispatch = useDispatch();
  const token = theme.useToken().token;

  //redux
  // const isLoading = useSelector(
  //   (state) => state.marketWatchSlice?.marketWatchLoading
  // );
  // const data = useSelector(
  //   (state) => state.marketWatchSlice?.data?.[props?.title]
  // );
  const selectedMarket = useSelector(
    (state) => state.marketWatchSlice?.seletctedMaketIndex
  );
  const watchList = useSelector(
    (state) =>
      state?.marketWatchSlice?.watchListArray?.[selectedMarket]?.scriptList
  );
  const accountType = useSelector(
    (state) => state.authSlice?.userDetail?.accountType
  );
  const iscustomer = accountType === "customer";
  const isBroker = accountType === "broker";

  //state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tableData, setTableData] = useState(props?.watchListData);
  const socketContext = useSocket();
  const { doSend } = socketContext;
  const [deleteLoading, setDeteleLoading] = useState({
    load: false,
    index: "",
  });

  //life cycle
  useEffect(() => {
    setTableData(props?.watchListData);
  }, [props?.selectedMarket, props?.watchListData]);

  // api call
  const handleRemoveItem = async (item, index) => {
    setDeteleLoading({ load: true, index: index });
    try {
      let response = await APICall(
        "post",
        `${EndPoints.deleteScript}scriptId=${item._id}`
      );
      if (response.status === 200) {
        notification.success({ message: response.data.message });

        dispatch(
          setWatchListArray({
            type: "deleteScript",
            id: item?._id,
            index: selectedMarket,
          })
        );
        doSend({
          event: "unsubscribeToServerMarket",
          payload: [item?.symbol],
        });
      } else {
        notification.error({ message: "Something want wrong" });
      }
    } catch (error) {
      notification.error({ message: error?.message });
    } finally {
      setDeteleLoading({ load: false, index: "" });
    }
  };

  const handleRemoveAllScript = async () => {
    let watchlistData =
      store.getState()?.marketWatchSlice?.watchListArray?.[selectedMarket];
    let filteredScript = watchlistData?.scriptList?.map((el) => el?.symbol);
    try {
      let response = await APICall(
        "post",
        `${EndPoints.deleteScript}watchListId=${watchlistData?._id}`
      );
      if (response.status === 200) {
        notification.success({ message: response.data.message });
        dispatch(
          setWatchListArray({ type: "deleteAllScript", index: selectedMarket })
        );
        doSend({
          event: "unsubscribeToServerMarket",
          payload: filteredScript,
        });
      } else {
        notification.error({ message: response.data.message });
      }
    } catch (error) {
      notification.error({ message: "Something want wrong" });
    }
  };

  //functions
  const showModal = (item) => {
    record.current = item;
    if (!isBroker) {
      setIsModalOpen(true);
    }
  };

  const onClose = (item) => {
    setIsModalOpen(false);
  };

  let call = true;
  const handleSort = (a, b, c, type) => {
    if (call) {
      call = false;
      const storeData = store.getState()?.webSocketSlice?.webSocket?.data;

      const WatchlistModifiy = watchList?.map((el) => {
        return { ...el, change: storeData?.[el?.symbol]?.[type] || 0 };
      });

      const sortedWatchList = WatchlistModifiy?.sort((a, b) => {
        if (type === "symbolName" || type === "marketName") {
          // Sort by scriptName if the type is 'scriptName'
          return c === "ascend"
            ? a[type].localeCompare(b[type])
            : b[type].localeCompare(a[type]);
        } else {
          // Sort by change for numeric sorting
          return c === "ascend" ? b.change - a.change : a.change - b.change;
        }
      });

      dispatch(
        setWatchListArray({
          type: "sortedWatchList",
          index: selectedMarket,
          sortedList: sortedWatchList,
        })
      );

      setTimeout(() => {
        call = true;
      }, 1000);
    }
  };

  // column
  const columns = useMemo(() => {
    let array = [
      {
        fixed: "left",
        title: <WatchListName>{props?.title}</WatchListName>,
        dataIndex: "InstrumentIdentifier",
        sorter: (a, b, c) => handleSort(a, b, c, "symbolName"),
        align: "left",
        width: "14rem",
        key: "script",
        hidden: !checkList?.includes("script"),
        render: (text, record) => {
          return (
            <ActiveScriptDot
              record={record}
              activeScriptRef={activeScriptRef}
            />
          );
        },
      },
      {
        title: "",
        dataIndex: "Change",
        align: "center",
        width: "6rem",
        sorter: (a, b, c) => handleSort(a, b, c, "marketName"),
        render(text, record) {
          return (
            <div style={{ display: "flex" }}>
              <MemorizedPercentageAero
                item={record}
                flage={
                  record?.marketName === "FOREX" ||
                  record?.marketName === "GLOBEX"
                    ? true
                    : false
                }
              />

              <Tooltip title={record?.marketName} placement={"leftTop"}>
                <MarketTableTag color="magenta" bordered={false}>
                  {marketTag[record?.marketName]}
                </MarketTableTag>
              </Tooltip>
            </div>
          );
        },
      },
      {
        title: "SELL RATE",
        dataIndex: "SellPrice",
        align: "center",
        key: "sellRate",
        hidden: !checkList?.includes("sellRate"),
        render(text, record) {
          const onClickCell = () => {
            modalType.current = "SellPrice";
            showModal(record);
          };

          return (
            <MemoizedCardItemPrice
              item={record}
              priceType={"Ask"}
              onClickCell={onClickCell}
            />
          );
        },
      },
      {
        title: "BUY RATE",
        dataIndex: "BuyPrice",
        text: "BuyPrice",
        align: "center",
        key: "buyRate",
        hidden: !checkList?.includes("buyRate"),
        render(text, record) {
          const onClickCell = () => {
            modalType.current = "BuyPrice";
            showModal(record);
          };
          return (
            <MemoizedCardItemPrice
              item={record}
              priceType={"Bid"}
              onClickCell={onClickCell}
            />
          );
        },
      },
      {
        title: <div style={{ padding: "0px 18px" }}>{"LTP"}</div>,
        dataIndex: "LastTradePrice",
        align: "center",
        key: "ltp",
        hidden: !checkList?.includes("ltp"),
        render(text, record) {
          return <MemoizedCardItemPrice item={record} priceType={"LTP"} />;
        },
      },
      {
        title: "CHANGE(%)",
        dataIndex: "PriceChangePercentage",
        align: "center",
        key: "change%",
        hidden: !checkList?.includes("change%"),
        sorter: (a, b, c) => handleSort(a, b, c, "Price_Change"),
        render(text, record) {
          return (
            <MemorizedPercentageAero
              item={record}
              number={true}
              flage={
                record?.marketName === "FOREX" ||
                record?.marketName === "GLOBEX"
                  ? true
                  : false
              }
            />
          );
        },
      },
      {
        title: "(₹)",
        dataIndex: "PriceChange",
        align: "right",
        key: "change(₹)",
        hidden: !checkList?.includes("change(₹)"),
        sorter: (a, b, c) => handleSort(a, b, c, "Net_Change"),
        render(text, record) {
          return (
            <div>
              <MemorizedPriceChange item={record} />
            </div>
          );
        },
      },
      {
        title: "HIGH",
        dataIndex: "High",
        align: "right",
        key: "high",
        hidden: !checkList?.includes("high"),
        render(text, record, index) {
          return (
            <MemorizedHighLowPrice
              item={record}
              priceType={"High"}
              index={index}
              isDark={checkList?.includes("theme")}
            />
          );
        },
      },
      {
        title: "LOW",
        dataIndex: "Low",
        align: "right",
        key: "low",
        hidden: !checkList?.includes("low"),
        render(text, record, index) {
          return (
            <MemorizedHighLowPrice
              item={record}
              priceType={"Low"}
              index={index}
              isDark={checkList?.includes("theme")}
            />
          );
        },
      },
      {
        title: "OPEN",
        dataIndex: "Open",
        align: "right",
        className: `open-cell`,
        key: "open",
        hidden: !checkList?.includes("open"),
        sorter: (a, b, c) => handleSort(a, b, c, "Open"),
        render(text, record) {
          return <MemorizedOpenClose item={record} priceType={"Open"} />;
        },
      },
      {
        title: "CLOSE",
        dataIndex: "Close",
        align: "right",
        key: "close",
        hidden: !checkList?.includes("close"),
        render(text, record) {
          return <MemorizedOpenClose item={record} priceType={"Close"} />;
        },
      },

      {
        title: "POSITION",
        dataIndex: "position",
        align: "right",
        key: "position",
        hidden: !checkList?.includes("position"),
        render: (text, record) => {
          let position = record?.positions;

          let totalBuy = 0;
          let totalSell = 0;
          let totalBuyLot = 0;
          let totalSellLot = 0;
          position?.forEach((item) => {
            if (item?.type === "buy") {
              totalBuy += item?.totalQuantity;
              if (item?.status === "Open") {
                totalBuyLot += item?.lot;
              }
            } else if (item?.type === "sell") {
              totalSell += -item?.totalQuantity;
              if (item?.status === "Open") {
                totalSellLot += item?.lot;
              }
            }
          });
          return (
            <div>
              <div>
                B +{formatQuantity(totalBuy)} {`(${parseInt(totalBuyLot)})`}
              </div>
              <div>
                S -{formatQuantity(totalSell)} {`(${parseInt(totalSellLot)})`}
              </div>
            </div>
          );
        },
      },
      Table.EXPAND_COLUMN,
      !isBroker &&
        !iscustomer && {
          title: "M2M BUY",
          dataIndex: "buy",
          key: "buy",
          align: "right",
          key: "m2mBuy",
          hidden: !checkList?.includes("m2mBuy"),
          render: (text, record) => {
            const position = record?.positions;
            return (
              <div>
                {position && position?.length > 0 ? (
                  <M2MCalculation item={record} flag="buy" />
                ) : (
                  0
                )}
              </div>
            );
          },
        },
      !isBroker &&
        !iscustomer && {
          title: "M2M SELL",
          dataIndex: "sell",
          key: "sell",
          align: "right",
          key: "m2mSell",
          hidden: !checkList?.includes("m2mSell"),
          render: (text, record) => {
            const position = record?.positions;
            return (
              <div>
                {position && position?.length > 0 ? (
                  <M2MCalculation item={record} flag="sell" />
                ) : (
                  0
                )}
              </div>
            );
          },
        },
      {
        title: (
          <Popconfirm
            title="Delete the all script"
            placement="rightTop"
            description="Are you sure want to delete all script?"
            onConfirm={handleRemoveAllScript}
          >
            <RemoveAllDiv>REMOVE ALL</RemoveAllDiv>
          </Popconfirm>
        ),
        dataIndex: "remove",
        align: "center",
        key: "remove",
        width: "5rem",
        hidden: !checkList?.includes("remove"),
        render: (imageUrl, item, index) => {
          return (
            <div
              onClick={() => handleRemoveItem(item, index)}
              style={{ cursor: "pointer" }}
            >
              {deleteLoading.load === true && deleteLoading.index === index ? (
                <Spin size="small" />
              ) : (
                <CloseImage src={closeImage} alt="Product" />
              )}
            </div>
          );
        },
      },
    ];
    return array.filter((o1) => o1);
  }, [
    tableData,
    token,
    deleteLoading,
    checkList,
    props.selectedMarket,
    props?.positionData,
  ]);

  // loading column
  const loadingColumn = useMemo(() => {
    let array = [
      {
        title: (
          <Col
            style={{
              cursor: "pointer",
              color: token.green5,
              fontWeight: 700,
              fontSize: "1rem",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            {props?.title}
          </Col>
        ),
        dataIndex: "InstrumentIdentifier",
        align: "left",
        width: "14rem",
      },
      {
        title: "",
        dataIndex: "Change",
        align: "center",
        width: "3rem",
      },
      {
        title: "SELL RATE",
        dataIndex: "SellPrice",
        align: "center",
      },
      {
        title: "BUY RATE",
        dataIndex: "BuyPrice",
        text: "BuyPrice",
        align: "center",
      },

      {
        title: (
          <div style={{ padding: "0px 18px" }}>
            {props.title === "FOREX" || props.title === "GLOBEX"
              ? "SPREAD"
              : "LTP"}
          </div>
        ),
        dataIndex: "LastTradePrice",
        align: "center",
      },
      {
        title: "CHANGE(%)",
        dataIndex: "PriceChangePercentage",
        align: "center",
      },
      {
        title:
          props?.title === "FOREX" || props?.title === "GLOBEX" ? "($)" : "(₹)",
        dataIndex: "PriceChange",
        align: "right",
      },
      {
        title: "HIGH",
        dataIndex: "High",
        align: "right",
      },
      {
        title: "LOW",
        dataIndex: "Low",
        align: "right",
      },
      // props.title !== "FOREX" &&
      {
        title: "OPEN",
        dataIndex: "Open",
        align: "right",
        className: `open-cell`,
      },
      {
        title: "CLOSE",
        dataIndex: "Close",
        align: "right",
      },
      {
        title: "POSITION",
        dataIndex: "position",
        align: "right",
      },
      !isBroker &&
        !iscustomer && {
          title: "M2M BUY",
          dataIndex: "buy",
          key: "buy",
          align: "right",
        },
      !isBroker &&
        !iscustomer && {
          title: "M2M SELL",
          dataIndex: "sell",
          key: "sell",
          align: "right",
        },
      {
        title: (
          <Popconfirm
            title="Delete the all script"
            placement="leftTop"
            description="Are you sure want to delete all script?"
          >
            <div
              style={{
                cursor: "pointer",
                color: token.colorBgBase,
                fontWeight: 600,
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                WebkitTextStroke: "0.5px red",
              }}
            >
              REMOVE ALL
            </div>
          </Popconfirm>
        ),
        dataIndex: "remove",
        align: "center",
      },
    ];
    return array.filter((o1) => o1);
  }, []);

  useDebounce(
    () => {
      if (props?.searchValue?.length > 0 && watchList) {
        const filteredData = watchList?.filter((existingScript) => {
          return existingScript?.symbolName
            ?.toLowerCase()
            ?.includes(props?.searchValue?.toLowerCase());
        });

        setTableData(filteredData);
      } else {
        setTableData(watchList);
      }
    },
    500,
    [props?.searchValue, watchList]
  );

  const skeletonData = new Array(4).fill({}).map((_, index) => ({
    // key: index,
    InstrumentIdentifier: (
      <Skeleton.Input
        key={index}
        style={{ width: `${(index + 1) * 25}rem`, height: "1.6rem" }}
        active={props.isLoading}
      />
    ),
  }));

  return (
    <div>
      <StyledTheme.Root>
        <StyledTheme.Table
          columns={props?.isLoading ? loadingColumn : columns}
          dataSource={props?.isLoading ? skeletonData : tableData}
          pagination={false}
          sortDirections={["ascend", "descend", "ascend"]}
          rowKey="_id"
          sticky
          scroll={{ x: 1300, scrollToFirstRowOnChange: false }}
          isDark={checkList?.includes("theme")}
          expandable={{
            expandedRowRender: (record) => {
              return <UserPosition record={record} />;
            },
            rowExpandable: (record) => record.positions?.length > 0,
          }}
        />
        {isModalOpen ? (
          <ModalView
            modalType={modalType.current}
            record={record.current}
            onClose={onClose}
            activeScript={activeScriptRef.current}
          />
        ) : null}
      </StyledTheme.Root>
    </div>
  );
});

const ActiveScriptDot = ({ record, activeScriptRef }) => {
  const { marketName } = record;
  const token = theme.useToken().token;
  // state
  const [isScriptActive, setIsScriptActive] = useState(true);

  const time = MemorizedTime(record);
  const timePart = time?.split("T");
  const givenDate = new Date(time);
  const differnce = Date.now() - givenDate.getTime();
  const inputDateString = record?.expiryDate;
  let _lastUpdatedText = `Last Updated ${timePart} `;
  let formattedDate = "";

  let option = marketName === "NSE OPT" || marketName === "MCX OPT";
  let showExpiry =
    marketName === "FOREX" ||
    marketName === "GLOBEX" ||
    marketName === "NSE EQU";

  // life cycle
  useEffect(() => {
    if (differnce > 1000 * 60 * 2) {
      setIsScriptActive(false);
      activeScriptRef.current[record?.symbol] = false;
    } else {
      setIsScriptActive(true);
      activeScriptRef.current[record?.symbol] = true;
    }
  }, [record, differnce]);

  if (inputDateString) {
    const [day, month, year] = inputDateString?.split("-").map(Number);
    const dateObject = new Date(year, month - 1, day);
    formattedDate = dateObject.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
    formattedDate = formattedDate.replace(/\s/g, "").toUpperCase();
  }

  return (
    <div style={{ display: "flex", gap: "5px" }}>
      <div
        style={{
          borderLeft: `4px solid ${isScriptActive ? token.green : token.red}`,
          height: "35px",
        }}
      ></div>
      <Tooltip placement="top" title={_lastUpdatedText} arrow>
        <ActiveScriptWrapper>
          <Row justify={"left"}>
            <Col style={{ fontSize: "1rem" }}>
              {`${record?.symbolName || ""} ${
                (option && record?.strikePrice) || ""
              }  ${(option && record?.series) || ""} 
              `}
            </Col>
            {/* <ActiveDot
              active={isScriptActive ? token.green : token.red}
            ></ActiveDot> */}
          </Row>
          <ExpairyDateRow>
            {`
              ${!showExpiry && record?.expiryDate ? formattedDate : ""}`}
          </ExpairyDateRow>
        </ActiveScriptWrapper>
      </Tooltip>
    </div>
  );
};

const formatQuantity = (total) => {
  if (total >= 10000000) {
    return `${(total / 10000000).toFixed(2)} Cr`;
  } else if (total >= 100000) {
    return `${(total / 100000).toFixed(2)} Lacs`;
  } else if (total >= 1000) {
    return `${(total / 1000).toFixed(2)}k`;
  } else {
    return total.toFixed(2);
  }
};

export const MemorizedTime = (record) => {
  const time = useSelector(
    (state) => state.webSocketSlice?.webSocket?.data?.[record?.symbol]?.LUT
  );

  return `${time}`;
};

export default SimpleTable;
