import React, {
  Suspense,
  lazy,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tabs } from "antd";
import ThemeCard from "../../../components/ThemeCard";
import { setNewAccountInfo } from "../../../store/slice/userAccountSlice";
import {
  setBrokerList,
  setBrokerInitialIds,
  setBrokerInitislData,
} from "../../../store/slice/brokaerForUsersFormSlice";
import { handleMarketValidation } from "../../../Helper/functions";
import {
  StyledTheme,
  StyledExclamationOutlined,
  FlexContainer,
  TabContainer,
  TabLabel,
} from "./style";
import {
  useBlockScriptList,
  useGetFilteredUsers,
} from "../../../APICall/apiHooks";
import MarketAccessDetailView from "./MarketAccess/MarketAccessDetailView";
import {
  addAllMarketBlockedScript,
  setContentBlockScript,
} from "../../../store/slice/scriptSettingsRequestSlice";
import { useParams } from "react-router-dom";
const LazyMarketAccessDetailView = lazy(() =>
  import("./MarketAccess/MarketAccessDetailView")
);

const MarketAccessView = React.memo(({ is_edits, parentForm }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const paramId = useParams()?.id;

  //state
  const [showMarket, setShowMarket] = useState([]);
  const [tab, setTab] = useState("");

  const touchedRef = useRef({});

  //redux
  const accountType = useSelector(
    (state) => state.userAccountSlice.accountType
  );

  const loginUserId = useSelector(
    (state) => state.authSlice?.userDetail?.userId
  );
  const parentId = useSelector(
    (state) => state?.userAccountSlice?.parentValidation?.basicInfo?.userId
  );
  // custom hook for get filtered users
  const { filteredData: brokerAll } = useGetFilteredUsers({
    type: "broker",
    editable: accountType === "customer",
  });
  const { allBlockScriptData, allBlockScriptLoading } = useBlockScriptList(
    is_edits ? paramId : loginUserId
  );

  //redux
  const market_access = useSelector(
    (state) => state.userAccountSlice.market_access
  );
  const parentDetails = useSelector(
    (state) => state.authSlice.MarketAccessDetails
  );
  const parentMarket = parentDetails?.parentMarketList;
  const childMarketAccess = useSelector(
    (state) => state?.userAccountSlice?.childMarketAccess
  );
  const marketValidationErrors = useSelector(
    (state) => state.globleSlice.marketValidationErrors
  );
  const marketList = useSelector((state) => state.globleSlice.marketListData);
  const contentValue = useSelector(
    (state) => state?.globleSlice?.constantValue
  );
  const previousMarket = contentValue?.allMaketAccess
    ?.filter((el) => !el?.status)
    ?.map((el) => el?.scriptName);
  const constentMarket = contentValue?.marketAccess;

  const List = useMemo(
    () => marketList?.map((el) => el?.scriptName),
    [marketList]
  );

  const formMarket = useMemo(() => {
    return showMarket?.map((el, index) => {
      let checked = market_access?.includes(el.value);
      let isValid = marketValidationErrors[el?.value];
      let preMarket =
        previousMarket?.includes(el.value) && is_edits && !checked;
      let isUnchecked =
        (constentMarket?.includes(el.value) && !checked && is_edits) ||
        preMarket;

      return {
        label: (
          <FlexContainer>
            <TabContainer
              onClick={() => handleTabChange(el?.value)}
              isActive={tab === el?.value}
              isChecked={checked}
              isValid={isValid}
              isUnchecked={isUnchecked}
            >
              <StyledTheme.Checkbox
                checked={checked}
                onChange={(e) => handleTabCheck(e, el?.value)}
              />
              <TabLabel
                isActive={tab === el?.value}
                isChecked={checked}
                isUnchecked={isUnchecked}
              >
                {el?.label}
              </TabLabel>
            </TabContainer>
            <div>{isValid === true && <StyledExclamationOutlined />}</div>
          </FlexContainer>
        ),
        key: `${el?.scriptName}`,
        children: (
          // <Suspense fallback={<Skeleton />}>
          <MarketAccessDetailView
            tab={el?.scriptName}
            show={show}
            setShow={setShow}
            is_edits={is_edits}
            parentForm={parentForm}
            activeKey={tab === el?.value}
          />
        ),
        // </Suspense>
        forceRender: true,
      };
    });
  }, [
    showMarket,
    market_access,
    marketValidationErrors,
    tab,
    show,
    is_edits,
    parentForm,
  ]);

  // life cycle
  useEffect(() => {
    if (is_edits) {
      setTab(market_access[0]);
    } else {
      setTab(List[0]);
      let dummy = [];
      let includes = market_access?.includes(List[0]);
      if (!includes) {
        dummy = [...market_access, List[0]];
        dispatch(setNewAccountInfo({ market_access: dummy }));
      }
    }
  }, []);
  useEffect(() => {
    setTab(List[0]);
    setShow(false);
    // if (accountType === "customer") {
    //   getBroker();
    // }
  }, [accountType]);
  useEffect(() => {
    if (is_edits && accountType !== "masterAdmin") {
      const market = parentMarket?.map((el) => {
        return { label: el, value: el, scriptName: el };
      });
      setShowMarket(market);
    } else {
      setShowMarket(
        Array.from(
          new Map(marketList.map((item) => [item.scriptName, item])).values()
        )
      );
    }
  }, [is_edits, parentMarket]);
  useEffect(() => {
    if (brokerAll) {
      dispatch(setBrokerInitislData(brokerAll));
      if (is_edits && accountType === "customer") {
        dispatch(setBrokerInitialIds(brokerAll));
        let idFromForm = parentForm
          ?.getFieldValue("additionalSharing")
          ?.map((item) => item.brokerId);
        let idFromAlllScrip = brokerAll.map((item) => item.value);
        let exIds = idFromAlllScrip.filter(
          (item) => !idFromForm.includes(item)
        );
        dispatch(
          setBrokerList(brokerAll.filter((item) => exIds.includes(item.value)))
        );
      } else {
        dispatch(setBrokerList(brokerAll));
        dispatch(setBrokerInitialIds(brokerAll));
      }
    }
  }, [brokerAll]);

  useEffect(() => {
    if (allBlockScriptData) {
      const data = allBlockScriptData?.reduce((acc, curr) => {
        if (curr?.blockedBy !== "Blocked BY NSE") {
          if (!acc[curr?.marketName]) acc[curr?.marketName] = [];
          acc[curr?.marketName].push(curr?.Script);
        }
        return acc;
      }, {});
      // console.log("parent", parentId);
      const matchId = is_edits ? parentId : loginUserId;

      dispatch(addAllMarketBlockedScript({ data }));
      const content = allBlockScriptData?.reduce((acc, curr) => {
        if (
          curr?.parentId !== matchId &&
          curr?.blockedBy !== "Blocked BY NSE"
        ) {
          if (!acc[curr?.marketName]) acc[curr?.marketName] = [];
          acc[curr?.marketName].push(curr?.Script);
        }
        return acc;
      }, {});

      dispatch(setContentBlockScript({ data: content }));
    }
  }, [allBlockScriptData, accountType]);

  //functions
  const handleTabChange = (activeKey) => {
    if (tab === activeKey) {
      setTab("");
    } else {
      setTab(activeKey);
      let dummy = [];
      let includes = market_access?.includes(activeKey);
      if (!includes) {
        dummy = [...market_access, activeKey];
        dispatch(setNewAccountInfo({ market_access: dummy }));
      }
    }
  };

  const handleTabCheck = (e, activeKey) => {
    e.stopPropagation();
    const checked = e.target.checked;
    if (!checked) {
      handleMarketValidation(parentForm, dispatch);
    }

    // if (!checked && is_edits && accountType !== "customer") {
    //   const include = !childMarketAccess?.includes(activeKey);
    //   if (include) {
    //     return;
    //   }
    // }
    if (!checked && is_edits && accountType !== "customer") {
      const include = constentMarket?.includes(activeKey);
      if (include) {
        return;
      }
    }

    if (!checked && is_edits) {
      const touchedFields = parentForm.isFieldsTouched();
      const touchedValues = {};
      if (touchedFields) {
        // Get all field values
        const allValues = parentForm.getFieldsValue();

        // Filter to get only touched fields
        Object.keys(allValues).forEach((field) => {
          if (parentForm.isFieldTouched(field)) {
            touchedValues[field] = allValues[field];
            const [name, market] = field?.split("$");
            if (market === activeKey) {
              if (!touchedRef.current[market]) touchedRef.current[market] = {};
              touchedRef.current[market][field] = allValues[field];
            }
          }
        });
      }
    }
    if (checked && is_edits) {
      const inOldMarket = constentMarket?.includes(activeKey);
      // if (!inOldMarket) {
      //   const allValues = parentForm.getFieldsValue();
      //   Object.keys(allValues).forEach((field) => {
      //     if (parentForm.isFieldTouched(field)) {
      //       const [name, market] = field?.split("$");
      //       if (market === activeKey) {
      //         if (!touchedRef.current[market]) touchedRef.current[market] = {};
      //         touchedRef.current[market][field] = allValues[field];
      //       }
      //     }
      //   });
      //   console.log("toucedRef", touchedRef.current);
      // } else {
      const touchedMarket = touchedRef.current[activeKey];
      if (touchedMarket) {
        const touchedValues = Object.keys(touchedMarket).map((item) => {
          return {
            name: item,
            value: touchedMarket[item],
            touched: true,
          };
        });
        setTimeout(() => {
          parentForm.setFields(touchedValues);
        }, 600);
      }
      // }
    }

    let dummy = [];
    if (market_access?.includes(activeKey)) {
      dummy = market_access.filter((o1) => o1 !== activeKey);
    } else {
      dummy = [...market_access, activeKey];
    }
    if (e.target.checked) {
      setTab(activeKey);
    }
    dispatch(setNewAccountInfo({ market_access: dummy }));
  };

  return (
    <ThemeCard
      title="Market Access"
      bordered={false}
      style={{ marginBlock: "2rem", flexDirection: "column", width: "100%" }}
    >
      <StyledTheme.Wrapper>
        {formMarket.length > 0 && (
          <Tabs
            items={formMarket}
            activeKey={tab}
            // tabPosition="mode"
            draggable={false}
            className="users-tabs-view"
            onChange={handleTabChange}
            style={{ marginBottom: "2rem" }}
          />
        )}
      </StyledTheme.Wrapper>
    </ThemeCard>
  );
});

export default MarketAccessView;
